export default function Tooltip({ text, visible, excel }) {
    return (
        text &&
        visible && (
            <div
                role="tooltip"
                className={`opacity-80 ${""} absolute z-10 py-2 px-3 text-sm font-medium text-kupawhite bg-kupablack rounded-lg shadow-sm transition-opacity duration-300 tooltip`}
            >
                {text}
                <div className="tooltip-arrow" data-popper-arrow></div>
            </div>
        )
    );
}
