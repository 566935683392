import classNames from "classnames";

function LoadingSpinner({ size, wholeScreen, className }) {
	return (
		<div
			className={classNames("flex", wholeScreen && "h-screen", className)}
		>
			<div className="m-auto text-center justify-center">
				<div style={{ width: size, height: size }} className="loader" />
			</div>
		</div>
	);
}

LoadingSpinner.defaultProps = {
	wholeScreen: true,
};

export default LoadingSpinner;
