import { useEffect, useState } from "react";

export default function Darkmode({ className }) {
	const [darkmode, setDarkmode] = useState(localStorage.getItem("theme"));

	useEffect(() => {
		const theme = localStorage.getItem("theme");

		if (theme === "dark") {
			document.documentElement.classList.add("dark");
			setDarkmode(true);
		} else {
			document.documentElement.classList.remove("dark");
			setDarkmode(false);
		}
	}, []);

	const toggleDarkmode = () => {
		setDarkmode(!darkmode);

		if (darkmode) {
			localStorage.setItem("theme", "light");
			document.documentElement.classList.remove("dark");
		} else {
			localStorage.setItem("theme", "dark");
			document.documentElement.classList.add("dark");
		}
	};

	if (true) return null;

	/*return (
		<CustomButton
			className={classNames(
				"right-20 top-0 h-10 w-10 mt-2",
				darkmode
					? "bg-violet-600 text-kupawhite"
					: "bg-sky-400 text-amber-300",
				className
			)}
			icon={darkmode ? faMoon : faSun}
			onClick={() => toggleDarkmode()}
		/>
	);*/
}
