import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Popup from "reactjs-popup";
import CustomButton from "../../../../common/layouts/Global/CustomButton";
import PopupHeadTitle from "../../../../common/layouts/Global/PopupHeadTitle";
import { RoundButtonXMarkAbsolute } from "../../../../common/layouts/Global/RoundButton";
import { ToastNotSavedCustom } from "../../../../common/util/ToastUtil";
import NotificationTypes from "../NotificationChecked";
function ModifyNotificationPopup({
	open,
	closeMethod,
	modifyMethod,
	types = {},
	currentNotification,
}) {
	const { t } = useTranslation();
	const [active, setActive] = useState(false);
	const [subActive, setSubActve] = useState(false);

	const [notificationTypes, setNotificationTypes] = useState([]);
	useEffect(() => {
		setActive(currentNotification?.active);
		setSubActve(currentNotification?.active_subgroup);
		let json = {};
		currentNotification?.types?.forEach((item) => {
			json[item] = item;
		});
		setNotificationTypes(currentNotification?.types);
	}, [
		open,
		currentNotification?.active,
		currentNotification?.active_subgroup,
		currentNotification?.types,
	]);
	const handleChange = (type) => {
		if (notificationTypes?.includes(type)) {
			setNotificationTypes((prev) =>
				prev.filter((element) => element !== type)
			);
			return;
		}
		setNotificationTypes((prev) => [...prev, type]);
	};
	const handleSubmit = () => {
		if (notificationTypes.length === 0) {
			ToastNotSavedCustom(t("notif.choose.type"));
			return;
		}
		modifyMethod(
			currentNotification?.user?.id,
			active,
			subActive,
			notificationTypes
		);
		closeMethod();
	};
	return (
		<Popup
			open={open}
			onClose={closeMethod}
			contentStyle={{
				borderRadius: "0.75rem",
				width: "90%",
				maxWidth: "400px",
				padding: "10px",
			}}
			className="relative"
		>
			<RoundButtonXMarkAbsolute functionCall={closeMethod} />

			<PopupHeadTitle
				text={t("notifi.edit") + " " + currentNotification?.user?.email}
			/>

			<div className="text-center p-1 mt-2">
				<div className="m-auto flex flex-col items-start gap-0 text-left">
					<div className="ml-1">
						<input
							className="mr-1"
							defaultChecked={active}
							onClick={() => setActive((prev) => !prev)}
							type="checkbox"
						/>
						{t("enabled")}
					</div>
					<div className="ml-1">
						<input
							className="mr-1"
							defaultChecked={subActive}
							onClick={() => setSubActve((prev) => !prev)}
							type="checkbox"
						/>
						{t("enabled.sub.group")}
					</div>

					<NotificationTypes
						notificationTypes={types}
						currentNotificationTypes={notificationTypes}
						handleChange={handleChange}
					/>
				</div>
				<div className="flex justify-end mt-2">
					<CustomButton
						className="w-40"
						text={t("modify")}
						onClick={handleSubmit}
					/>
				</div>
			</div>
		</Popup>
	);
}

export default ModifyNotificationPopup;
