import { useTranslation } from "react-i18next";

function CustomSelectOption({ value, onChange, values }) {
	const { t } = useTranslation();

	return (
		<select
			value={value || ""}
			onChange={onChange}
			className="border border-slate-300 rounded-full px-2 py-1"
		>
			{values.map((i, index) => (
				<option key={index} value={i}>
					{t(i, {
						1: undefined,
						2: undefined,
						3: undefined,
					})}
				</option>
			))}
		</select>
	);
}

export default CustomSelectOption;
