import {
	requestAPI,
	requestAPIToDownload,
} from "../../../common/requests/RequestAPI";
import URL from "../../../common/requests/UrlsEnum";

let abortController;

export async function FetchDrinkHistory(
	groupId,
	machineId,
	filters = undefined,
	page = 1,
	pageCount = 40
) {
	try {
		if (abortController) abortController.abort();

		filters = filters ?? {};
		let add = {};
		if (machineId) {
			add = { machine_id: { in: [machineId] } };
		}

		abortController = new AbortController();

		const data = await requestAPI(
			`${URL.ORDER}?page=${page}&countPerPage=${pageCount}&groupId=${groupId}`,
			"POST",
			{ ...add, ...filters },
			abortController.signal
		);

		return data.success ? data.data : [];
	} catch (error) {
		return [];
	}
}

export async function DownloadDrinkHistory(
	groupId,
	machineId,
	filters = undefined
) {
	filters = filters ?? {};
	let add = {};
	if (machineId) {
		add = { machine_id: { in: [machineId] } };
	}

	const data = await requestAPIToDownload(
		`${URL.ORDER_EXPORT}?groupId=${groupId}`,
		"POST",
		{ ...add, ...filters }
	);
	return data;
}

export async function FetchVisibleMachines(groupId) {
	const data = await requestAPI(
		URL.VISIBLE_MACHINES.replace("{groupId}", groupId),
		"GET"
	);

	return data.success ? data.data : [];
}

export async function FetchVisibleDiscounts(groupId) {
	const data = await requestAPI(
		URL.VISIBLE_DISCOUNTS.replace("{groupId}", groupId),
		"GET"
	);

	return data.success ? data.data : [];
}
export async function FetchVisibleLogs(groupId) {
	const data = await requestAPI(
		URL.VISIBLE_LOGS.replace("{groupId}", groupId),
		"GET"
	);

	return data.success ? data.data : [];
}
