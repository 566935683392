import { Navigate, Route, Routes } from "react-router-dom";
import LoginContainer from "../containers/LoginContainer";
import BackgroundWave from "../layouts/Global/BackgroundWave";
import SignUp from "./SignUp";
function Login() {
	return (
		<>
			<Routes>
				<Route path="/" element={<Navigate to="/login" />} />
				<Route path="/login" element={<LoginContainer />} />
				<Route path="/signup" element={<SignUp />} />
				<Route path="*" element={<Navigate to="/login" />} />
			</Routes>

			<BackgroundWave height={"50%"} />
		</>
	);
}

export default Login;
