import { requestAPI } from "../../../common/requests/RequestAPI";
import URL from "../../../common/requests/UrlsEnum";

export async function FetchRoles(groupId) {
	const members = await requestAPI(`${URL.GROUP}/${groupId}/role`, "GET");
	return members;
}

export async function DeleteRole(roleId) {
	const data = await requestAPI(`${URL.GROUP}/role/${roleId}`, "DELETE", {});
	return data;
}

export async function AddRole(groupId, name, permissions) {
	const data = await requestAPI(`${URL.GROUP}/${groupId}/role`, "POST", {
		name: name,
		permissions: permissions,
	});
	return data;
}

export async function UpdateRole(roleId, roleName, rolePermissions, roleRank) {
	const data = await requestAPI(`${URL.GROUP}/role/${roleId}`, "PUT", {
		name: roleName,
		permissions: rolePermissions,
		rank: roleRank,
	});
	return data;
}
export async function UpdateRolesRank(groupId, roles) {
	const data = await requestAPI(
		URL.ROLES_RANK_EDIT.replace("{id}", groupId),
		"PUT",
		{
			roles,
		}
	);
	return data;
}
