import { useEffect } from "react";
import LoadingSpinner from "../../../common/layouts/Global/LoadingSpinner";
import { useQuery } from "@tanstack/react-query";
import { FetchTree } from "../../../common/requests/tree/Tree";
import { useNavigate } from "react-router-dom";

function Dashboard() {
	document.title = "KUPA Station";

	const { data } = useQuery([`tree`], {
		queryFn: () => FetchTree(),
	});

	const navigate = useNavigate();

	useEffect(() => {
		if (data && data.length > 0) {
			navigate(`/portal/${data[0].id}`);
		}
	}, [data, navigate]);

	if (!data) return <LoadingSpinner />;

	if (data.length === 0) return <></>;
}

export default Dashboard;
