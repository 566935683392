import { Pie } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import WhiteBlockDiv from "../../../common/layouts/Global/WhiteBlockDiv";

function AppVsMobile({ stats = {} }) {
	const { t } = useTranslation();

	const data = {
		labels: ["Application", "Station"],
		datasets: [
			{
				data: [stats.mobileUsers, stats.stationUsers],
				backgroundColor: [
					"rgb(112 191 24 / 15%)",
					"rgb(6 174 211 / 15%)",
				],
				borderColor: "black",
				borderWidth: 1,
			},
		],
	};
	if (!data) return null;

	return (
		stats && (
			<WhiteBlockDiv text={t("origin.use")}>
				<Pie
					width={350}
					height={350}
					options={{ maintainAspectRatio: false }}
					data={data}
				/>
			</WhiteBlockDiv>
		)
	);
}

export default AppVsMobile;
