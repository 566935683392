import classNames from "classnames";

const ToggleButton = ({ name, checked, id, handleChange }) => {
	return (
		<label
			className={classNames("inline-flex relative items-center", {
				"opacity-50": !handleChange,
				"cursor-pointer": handleChange,
			})}
		>
			<input
				type="checkbox"
				className="sr-only peer"
				checked={checked}
				onChange={handleChange}
				disabled={!handleChange}
				id={id}
			/>

			<div className="bg-third/30 dark:bg-thirddark w-11 h-6 bg-gray-200 rounded-full peer peer-checked:bg-kupablue peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all" />

			<span className="ml-3 text-sm font-medium text-gray-900 text-kupablack dark:text-kupawhite">
				{name}
			</span>
		</label>
	);
};

export default ToggleButton;
