const Permissions = Object.freeze({
	GROUP_NAME_EDIT: "GROUP_NAME_EDIT",
	GROUP_MEMBER_VIEW: "GROUP_MEMBER_VIEW",
	GROUP_MEMBER_EDIT: "GROUP_MEMBER_EDIT",
	GROUP_ROLE_VIEW: "GROUP_ROLE_VIEW",
	GROUP_ROLE_EDIT: "GROUP_ROLE_EDIT",
	GROUP_SUBGROUP_EDIT: "GROUP_SUBGROUP_EDIT",
	GROUP_MACHINES_VIEW: "GROUP_MACHINES_VIEW",
	GROUP_DISCOUNT_VIEW: "GROUP_DISCOUNT_VIEW",
	GROUP_DISCOUNT_EDIT: "GROUP_DISCOUNT_EDIT",
	GROUP_VIDEO_VIEW: "GROUP_VIDEO_VIEW",
	GROUP_VIDEO_EDIT: "GROUP_VIDEO_EDIT",
	GROUP_VIDEO_APPROVE: "GROUP_VIDEO_APPROVE",
	GROUP_SCHEDULE_VIEW: "GROUP_SCHEDULE_VIEW",
	GROUP_SCHEDULE_EDIT: "GROUP_SCHEDULE_EDIT",
	GROUP_STATS_ORDER_VIEW: "GROUP_STATS_ORDER_VIEW",
	GROUP_STATS_ORDER_EXPORT: "GROUP_STATS_ORDER_EXPORT",
	GROUP_STATS_ORDER_REVENUE_VIEW: "GROUP_STATS_ORDER_REVENUE_VIEW",
	GROUP_STATS_TOPMACHINE_VIEW: "GROUP_STATS_TOPMACHINE_VIEW",
	GROUP_NOTIFICATION_RECEIVE: "GROUP_NOTIFICATION_RECEIVE",
	GROUP_NOTIFICATION_VIEW: "GROUP_NOTIFICATION_VIEW",
	GROUP_NOTIFICATION_EDIT: "GROUP_NOTIFICATION_EDIT",
	MACHINE_SETTING_PAYMENT_TOGGLE: "MACHINE_SETTING_PAYMENT_TOGGLE",
	MACHINE_SETTING_CONTAINER_EDIT: "MACHINE_SETTING_CONTAINER_EDIT",
	MACHINE_SETTING_VALVE_EDIT: "MACHINE_SETTING_VALVE_EDIT",
	MACHINE_SETTING_OPTION_EDIT: "MACHINE_SETTING_OPTION_EDIT",
	MACHINE_SETTING_OPTION_TOGGLE: "MACHINE_SETTING_OPTION_TOGGLE",
	MACHINE_SETTING_RECIPE_EDIT: "MACHINE_SETTING_RECIPE_EDIT",
	MACHINE_SETTING_RECIPE_TOGGLE: "MACHINE_SETTING_RECIPE_TOGGLE",
	MACHINE_SETTING_PRODUCT_EDIT: "MACHINE_SETTING_PRODUCT_EDIT",
	MACHINE_SETTING_CLEAN_VIEW: "MACHINE_SETTING_CLEAN_VIEW",
	MACHINE_STATS_PRODUCTLEVEL_VIEW: "MACHINE_STATS_PRODUCTLEVEL_VIEW",
	MACHINE_STATS_TOPOPTION_VIEW: "MACHINE_STATS_TOPOPTION_VIEW",
	MACHINE_STATS_TOPRECIPE_VIEW: "MACHINE_STATS_TOPRECIPE_VIEW",
	MACHINE_STATS_ORDER_VIEW: "MACHINE_STATS_ORDER_VIEW",
	MACHINE_STATS_ORDER_EXPORT: "MACHINE_STATS_ORDER_EXPORT",
	MACHINE_NAME_EDIT: "MACHINE_NAME_EDIT",
	LOGS_VIEW: "LOGS_VIEW",
	ERRORS_VIEW: "ERRORS_VIEW",
	STORE_CATALOG_VIEW: "STORE_CATALOG_VIEW",
	STORE_ORDER_CREATE: "STORE_ORDER_CREATE",
	STORE_ORDER_VIEW: "STORE_ORDER_VIEW",
	STORE_ORDER_EDIT: "STORE_ORDER_EDIT",
	STORE_ORDER_CANCEL: "STORE_ORDER_CANCEL",
});

export default Permissions;
