import { useQuery } from "@tanstack/react-query";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import Popup from "reactjs-popup";
import Permissions from "../../../../common/config/permissions";
import { useAuth } from "../../../../common/context/AuthContext";
import { DimensionsContext } from "../../../../common/context/DimensionsContext";
import Restricted from "../../../../common/context/hooks/Restricted";
import CustomButton from "../../../../common/layouts/Global/CustomButton";
import PopupHeadTitle from "../../../../common/layouts/Global/PopupHeadTitle";
import { RoundButtonXMarkAbsolute } from "../../../../common/layouts/Global/RoundButton";
import { FetchPermissions } from "../../../../common/requests/Permission";
import RolesTable from "../../tables/RolesTable";

function RolePermissionsPopup({
	open,
	closeMethod,
	modifyMethod,
	currentRole,
	groupId,
}) {
	const { t } = useTranslation();
	const { data } = useQuery([`available-permissions-${groupId}`], {
		queryFn: () => FetchPermissions(groupId),
	});
	const permissions = data ? data?.data?.availablePermissions : [];
	const myRank = data ? data?.data?.rank : null;
	const [permissionsAdded, setPermissionsAdded] = useState([]);
	const { hasAccessGroup } = useAuth();
	const canEdit = hasAccessGroup(groupId) || currentRole.rank >= myRank + 1;
	const isPermissionChecked = (permission) => {
		return (
			isPermissionSetToStartAndUnchanged(permission) ||
			isPermissionAddedToTrue(permission)
		);
	};

	const isPermissionSetToStartAndUnchanged = (permission) => {
		return (
			isPermissionSetFromStart(permission) &&
			!isPermissionAdded(permission)
		);
	};

	function isPermissionSetFromStart(permission) {
		return currentRole.role_permission.includes(permission.key);
	}

	const isPermissionAdded = (permission) => {
		return permissionsAdded.some(
			(addedPermission) => addedPermission.id === permission.id
		);
	};

	const isPermissionAddedToTrue = (permission) => {
		return permissionsAdded.some(
			(addedPermission) =>
				addedPermission.id === permission.id &&
				addedPermission.status === true
		);
	};

	const handleCheckboxChange = async (event) => {
		const permissionId = Number(event.target.value);
		var status = false;
		if (event.target.checked) {
			status = true;
		}
		const newPermissions = permissionsAdded.filter(
			(permission) => permission.id !== permissionId
		);
		setPermissionsAdded([
			...newPermissions,
			{
				id: permissionId,
				key: permissions.find(
					(permission) => permission.id === permissionId
				).key,
				status: status,
			},
		]);
	};

	const close = () => {
		setPermissionsAdded([]);
		closeMethod();
	};

	const setNewPermissions = async () => {
		const newPermissions = [];
		permissionsAdded.forEach((permission) => {
			if (
				(permission.status === true &&
					!currentRole.role_permission.includes(permission.key)) ||
				(permission.status === false &&
					currentRole.role_permission.includes(permission.key))
			) {
				newPermissions.push({
					permission_id: permission.id,
					active: permission.status,
				});
			}
		});
		setPermissionsAdded([]);
		await modifyMethod(newPermissions);
	};
	const { hasPermission } = useAuth();
	const { dimensions } = useContext(DimensionsContext);
	return (
		<Popup
			open={open}
			onClose={close}
			contentStyle={{
				borderRadius: "0.75rem",
				padding: "10px",
				height: "auto",
				width: dimensions < 1050 ? "93%" : "1000px",
				position: "relative",
			}}
		>
			<RoundButtonXMarkAbsolute functionCall={closeMethod} />

			<PopupHeadTitle
				text={t("roles.permissions") + " « " + currentRole.name + " »"}
			/>

			<div className="text-center mt-2 p-1 overflow-auto  h-[550px]">
				<div
					className="overflow-auto"
					style={{
						alignItems: "center",
					}}
				>
					<RolesTable
						canEdit={
							hasPermission(
								Permissions.GROUP_ROLE_EDIT,
								groupId
							) && canEdit
						}
						permissions={permissions}
						handleOnClick={handleCheckboxChange}
						isPermissionChecked={isPermissionChecked}
					/>
				</div>
				<div className="flex justify-end mt-2">
					<Restricted
						to={Permissions.GROUP_ROLE_EDIT}
						groupId={groupId}
					>
						<CustomButton
							onClick={async () => {
								await setNewPermissions();
							}}
							text={t("modify")}
							className="w-40"
						/>
					</Restricted>
				</div>
			</div>
		</Popup>
	);
}
export default RolePermissionsPopup;
