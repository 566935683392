import {
	faCloudUpload,
	faSpinner,
	faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import {
	ToastNotSavedCustom,
	ToastSaved,
} from "../../../common/util/ToastUtil";
import {
	CreateUploadURL,
	UploadFile,
} from "../../requests/video/CreateUploadURL";
function VideoInput({ refetchGalery }) {
	const { t } = useTranslation();
	const partSize = 3 * 10 * 327680;

	const splitFileIntoParts = (file, partSize) => {
		if (!file) return [];
		const fileSizeMB = file.size / (1024 * 1024); // convert size to MB

		if (fileSizeMB > 100) {
			ToastNotSavedCustom("File size exceeds limit of 100MB");
			return [];
		}
		if (file.type !== "video/mp4") {
			ToastNotSavedCustom(
				"Invalid file type. Only MP4 files are allowed"
			);
			return [];
		}

		const parts = [];
		let i = 0;
		while (i < file.size) {
			parts.push(file.slice(i, i + partSize, "video/mp4"));
			i += partSize;
		}
		return parts;
	};

	const { groupId } = useParams();
	const [uploadProgress, setUploadProgress] = useState(0);
	const [videoFile, setVideoFile] = useState(null);

	const handleDragOver = (e) => {
		e.preventDefault();
	};

	const handleFileDrop = (e) => {
		e.preventDefault();
		const file = e.dataTransfer.files[0];

		if (file && file.type === "video/mp4") {
			setVideoFile(file);
			handleAccept();
		} else ToastNotSavedCustom("Une erreur est survenue");
	};
	const inputRef = useRef(null);

	const handleFileChange = (event) => {
		const file = event.target.files[0];
		if (file && file.type === "video/mp4") {
			setVideoFile(file);
			handleAccept(file);
		} else ToastNotSavedCustom("Une erreur est survenue");
	};

	const handleClick = () => {
		inputRef.current.click();
	};
	const resetVideo = () => {
		setVideoFile(null);
	};
	const handleFileProcess = (file) => {
		if (file) {
			const parts = splitFileIntoParts(file, partSize);
			return parts;
		} else ToastNotSavedCustom("Une erreur est survenue");
	};
	const handleAccept = async (file) => {
		try {
			let url = await CreateUploadURL(groupId, file.name);
			if (url.uploadUrl) {
				const parts = handleFileProcess(file);
				let totalBytesUploaded = 0;
				for (let i = 0; i < parts.length; i++) {
					let startRange = i * partSize;
					let endRange = startRange + parts[i].size - 1;
					let contentRange = `${startRange}-${endRange}`;

					let response = await UploadFile(
						groupId,
						parts[i],
						file.name,
						url.uploadUrl,
						file.size,
						contentRange
					);
					if (response?.createdDateTime) {
						ToastSaved();
						setUploadProgress(0);
						//setFileUploaded(true);
						setVideoFile(null);
						refetchGalery();
					}
					totalBytesUploaded += parts[i].size;
					const progressPercent =
						(totalBytesUploaded / file.size) * 100;
					setUploadProgress(Math.floor(progressPercent));
				}
			} else {
				//setFileUploaded(false);
				ToastNotSavedCustom(t("save.error"), url.message);
				setVideoFile(null);
				setUploadProgress(0);
			}
		} catch (error) {
			ToastNotSavedCustom(t("save.error"));
			setUploadProgress(0);
			setVideoFile(null);
		}
	};

	return (
		<div
			style={{
				height: "100px",
				width: "150px",
				display: "flex",
				flexDirection: "row-reverse",
				margin: "10px",
				marginBottom: 0,
				marginLeft: "20px",
				position: "relative",
				cursor: videoFile ? "default" : "pointer",
			}}
			onDragOver={handleDragOver}
			onDrop={handleFileDrop}
			onClick={videoFile ? null : handleClick}
		>
			<input
				type="file"
				ref={inputRef}
				style={{ display: "none" }}
				accept="video/mp4"
				onChange={handleFileChange}
			/>
			<div
				style={{
					boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
					margin: "2px",
					border: "4px dashed #00acd3",
					borderRadius: "10px",
					backgroundSize: "cover",
					backgroundPosition: "center",
				}}
			>
				<div
					className="text-xs "
					style={{
						height: "100px",
						width: "150px",
						flex: 1,
						marginLeft: "auto",
						marginRight: "auto",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					{videoFile ? (
						<React.Fragment>
							<FontAwesomeIcon
								icon={faSpinner}
								className="animate-spin"
							/>
							<div>
								{videoFile.name}{" "}
								<FontAwesomeIcon
									icon={faTrash}
									onClick={resetVideo}
									className="cursor-pointer"
								/>
							</div>
							<div
								style={{
									width: "100%",
									backgroundColor: "#ccc",
								}}
							>
								<div
									style={{
										width: `${uploadProgress}%`,
										backgroundColor: "blue",
										height: "10px",
									}}
								/>
							</div>
						</React.Fragment>
					) : (
						<React.Fragment>
							<FontAwesomeIcon
								icon={faCloudUpload}
								size={"lg"}
								className=""
							/>
							<div>{t("drag.drop")}</div>
							<div>{t("or")}</div>
							<div className="text-black">{t("browse.file")}</div>
						</React.Fragment>
					)}
				</div>
				{/* <div className="text-xs w-fit max-w-2 max-w-[150px] overflow-hidden">
					({t("upload.max")})
				</div> */}
			</div>
		</div>
	);
}

export default VideoInput;
