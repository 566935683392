import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import Dropdown from "../../../../common/layouts/Global/Dropdown";
import LoadingSpinner from "../../../../common/layouts/Global/LoadingSpinner";
import SearchBar from "../../../../common/layouts/Global/SearchBar";
import { filteredMachines } from "../../../../common/util/Filter";
import Permissions from "../../../../common/config/permissions";
import { useAuth } from "../../../../common/context/AuthContext";
import { usePermission } from "../../../../common/context/hooks/usePermission";
import Machine from "../../../../portail/layouts/Machine/Machine";
import { FetchAllMachines } from "../../../../portail/requests/machines/FetchAllMachines";
import { GetMachineStatus } from "../../../util/MachineMethods";

function MachinesDashboard() {
	const { t } = useTranslation();
	document.title = `KUPA Station | ${t("stations")}`;

	const { groupId } = useParams();
	const hasPermission = usePermission(
		Permissions.GROUP_MACHINES_VIEW,
		groupId
	);
	const { hasGlobalPermission, staff } = useAuth();

	const { data, isLoading, refetch } = useQuery(
		[`MachinesGroup-${groupId}`],
		{
			queryFn: () => hasPermission && FetchAllMachines(groupId),
		}
	);
	const [userLocation, setUserLocation] = useState(undefined);
	const [center, setCenter] = useState({
		lat: 46.6904093,
		lng: -71.0595185,
	});
	const [search, setSearch] = useState("");
	const [filterSearch, setFilter] = useState(
		localStorage.getItem("sort.machines") ?? "problems"
	);
	function distance(lat1, lon1, lat2, lon2) {
		const R = 6371; // km
		const r = Math.PI / 180;
		const φ1 = lat1 * r; // φ, λ in radians
		const φ2 = lat2 * r;
		const Δφ = (lat2 - lat1) * r;
		const Δλ = (lon2 - lon1) * r;

		const a =
			Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
			Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
		const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

		const d = R * c; // in km
		return d;
	}

	useEffect(() => {
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition((position) => {
				setUserLocation({
					latitude: position.coords.latitude,
					longitude: position.coords.longitude,
				});

				setCenter({
					lat: position.coords.latitude,
					lng: position.coords.longitude,
				});
			});
		}
	}, []);
	const getOrderedMachines = () => {
		let filter = filteredMachines(data, search, hasGlobalPermission);

		filter.map((machine) => {
			machine.status = GetMachineStatus(machine);
			return machine;
		});
		if (filterSearch === "distance") {
			if (userLocation) {
				return filter.sort((a, b) => {
					return (
						distance(
							userLocation?.latitude,
							userLocation?.longitude,
							a.latitude ?? center.lat,
							a.longitude ?? center.lng
						) -
						distance(
							userLocation?.latitude,
							userLocation?.longitude,
							b.latitude ?? center.lat,
							b.longitude ?? center.lng
						)
					);
				});
			} else {
				return filter.sort((a, b) => b.status.score - a.status.score);
			}
		} else if (filterSearch === "private") {
			return filter.sort((a, b) =>
				a.private_name > b.private_name ? 1 : -1
			);
		} else if (filterSearch === "factory") {
			return filter.sort((a, b) =>
				a.factory_name > b.factory_name ? 1 : -1
			);
		} else {
			return filter.sort((a, b) => b.status.score - a.status.score);
		}
	};

	if (!hasPermission) return null;
	let filters = [
		"distance",
		"private",
		staff() ? "factory" : undefined,
		"problems",
	].filter((filter) => filter !== undefined);

	return (
		<>
			<div className="sm:flex justify-between mb-5">
				<SearchBar
					className="w-4/5 md:w-1/5"
					onChange={(e) => {
						setSearch(e.target.value);
					}}
				/>

				<div className="pl-2 sm:p-0 flex gap-2">
					<h1 className="text-sm my-auto font-semibold lowercase first-letter:uppercase ">
						{t("sorted.by")} :
					</h1>
					<Dropdown
						className={"w-36 "}
						classNameInput={"bg-[#fff]"}
						customTitle={t(filterSearch)}
						list={filters.map((role) => ({
							title: role,
							onClick: () => {
								setFilter(role);
								localStorage.setItem("sort.machines", role);
							},
						}))}
					/>
					{/* <select
						value={filterSearch || ""}
						onChange={(e) => {
							setFilter(e.target.value);
							localStorage.setItem(
								"sort.machines",
								e.target.value
							);
						}}
						className="outline-0 ring-1 ring-slate-200 rounded-full px-2 py-0"
					>
						{filters.map((i, index) => (
							<option key={index} value={i}>
								{t(i, {
									1: undefined,
									2: undefined,
									3: undefined,
								})}
							</option>
						))}
					</select> */}
				</div>
			</div>
			{filterSearch === "distance" && !userLocation && (
				<div className="mt-4 mb-4 text-sm text-center">
					<p className="text-kupared">{t("sort.distance.problem")}</p>
				</div>
			)}
			{isLoading ? (
				<LoadingSpinner />
			) : (
				<div className="gap-2 grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 m-2 sm:m-0 mt-5">
					{getOrderedMachines().map((machine) => (
						<Machine
							key={machine.id}
							machine={machine}
							refetch={refetch}
							groupId={machine?.group_id}
						/>
					))}
				</div>
			)}
		</>
	);
}

export default MachinesDashboard;
