import { useQuery } from "@tanstack/react-query";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import LoadingSpinner from "../../common/layouts/Global/LoadingSpinner";
import WhiteBlockDiv from "../../common/layouts/Global/WhiteBlockDiv";
import { FetchGroupDashboard } from "../../portail/requests/Group";
import flavoroption from "../assets/img/flavorsOptions.png";
import ItemsPurchasePopup from "../layouts/PopUp/itemsPurchasePopup";
import { FetchCatalog } from "../requests/Catalog";
import Restricted from "../../common/context/hooks/Restricted";
import Permissions from "../../common/config/permissions";
import { useAuth } from "../../common/context/AuthContext";
import { TreeContext } from "../../common/context/TreeContext";
const Catalog = () => {
	const { t } = useTranslation();
	document.title = `KUPA Station | ${t("catalog")}`;

	const { groupId } = useParams();
	const navigate = useNavigate();
	const { setUpdateTree } = useContext(TreeContext);
	const [open, setOpen] = useState(false);
	const [items, setItems] = useState([]);
	const { data: group } = useQuery([`group-${groupId}`], {
		queryFn: () => FetchGroupDashboard(groupId),
		enabled: !!groupId,
	});
	const isCustom = group?.data?.type === "CUSTOM";
	const { data: catalog, isLoading } = useQuery([`catalog-${groupId}`], {
		queryFn: () => FetchCatalog(groupId),
		enabled: !isCustom,
	});
	const closePopUp = () => {
		setOpen(false);
		setItems([]);
	};
	const openPopUp = (data) => {
		setOpen(true);
		setItems(data);
	};
	const { hasPermission } = useAuth();

	useEffect(() => {
		if (
			!hasPermission(Permissions.STORE_CATALOG_VIEW, groupId) ||
			!hasPermission(Permissions.STORE_ORDER_CREATE, groupId)
		)
			navigate(`/store/${groupId}/orders`);
		if (isCustom || (!isLoading && !catalog?.success)) {
			navigate(`/store/${groupId}/orders`);
		}
		setUpdateTree((prev) => prev + 1);
	}, []);
	const images = {
		flavoroption: flavoroption,
	};
	if (isLoading) return <LoadingSpinner />;
	return (
		<Restricted to={Permissions.STORE_CATALOG_VIEW} groupId={groupId}>
			<Restricted to={Permissions.STORE_ORDER_CREATE} groupId={groupId}>
				<WhiteBlockDiv>
					{Object.keys(catalog?.data).map((key) => (
						<div
							className="m-4 pt-[15px] cursor-pointer text-center"
							key={key}
							onClick={() => openPopUp(catalog?.data[key])}
						>
							<img
								alt="Kupa station"
								className="object-cover w-52 h-44 shadow-md mb-2 mix-blend-multiply"
								src={images[key]}
							/>
							{t(key)}
						</div>
					))}
					<ItemsPurchasePopup
						open={open}
						closeMethod={closePopUp}
						data={items}
						groupId={groupId}
					/>
				</WhiteBlockDiv>
			</Restricted>
		</Restricted>
	);
};

export default Catalog;
