import React from "react";
import { useTranslation } from "react-i18next";
import { GetRecipeImg } from "../../../common/util/RecipeUtil";
import WhiteBlockDiv from "../../../common/layouts/Global/WhiteBlockDiv";
import Table from "../../../common/layouts/tables/Table";
import ProgressBar from "./Stats/ProgressBar";
import moment from "moment";

const ProductsLevel = ({ product_level }) => {
	const { t } = useTranslation();
	if (!product_level) return null;
	for (let product in product_level) {
		if (product_level[product].length === 0) {
			delete product_level[product];
		}
	}

	const columns = [t("product"), t("level"), "%", t("last.change")];
	function findPercentagesWithNames() {
		return product_level.map((product) => {
			const lastChangeDate = product?.lastChangeDate;
			const formattedDate = lastChangeDate
				? moment(lastChangeDate).format("YYYY-MM-DD")
				: "-";

			return [
				<div className="flex w-fit flex-row items-center">
					<div
						className="w-[30px] h-[30px] saturate-50 bg-contain bg-no-repeat bg-center mix-blend-multiply"
						style={{
							backgroundImage: `url(${GetRecipeImg(
								product.key
							)})`,
						}}
					></div>
					<p style={{ margin: "3px" }}>{t(product.key)}</p>
				</div>,
				<ProgressBar pourcent={product.percentage} color={"orange"} />,
				Math.min(Math.max(product?.percentage?.toFixed(1), 0), 100),
				formattedDate,
			];
		});
	}

	const rows = findPercentagesWithNames(product_level);

	if (product_level.length === 0) return null;

	return (
		<WhiteBlockDiv text={t("products.level")} className={"p-1"}>
			<Table
				className={
					"justify-center w-full max-h-300 h-fit md:max-h-400 overflow-auto"
				}
				columns={columns}
				rows={rows}
			/>
		</WhiteBlockDiv>
	);
};

export default ProductsLevel;
