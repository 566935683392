import { useTranslation } from "react-i18next";
import WhiteBlockDiv from "../../../common/layouts/Global/WhiteBlockDiv";
import Table from "../../../common/layouts/tables/Table";
import { GetRecipeImg } from "../../../common/util/RecipeUtil";

function OptionsGraph({ options, text }) {
	const { t } = useTranslation();

	const isValidOptions = options && typeof options === "object";
	const sumTotal = options.reduce((total, el) => total + el.count, 0);
	const columns = [t("option"), "%", "nombre"];

	const rows = options?.map((options) => {
		return [
			<div
				style={{
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
				}}
			>
				<div
					className="saturate-50"
					style={{
						height: "30px",
						width: "30px",
						backgroundImage: `url(${GetRecipeImg(options.key)})`,
						backgroundSize: "contain",
						backgroundRepeat: "no-repeat",
						backgroundPosition: "center center",
						mixBlendMode: "multiply",
					}}
				></div>
				<p style={{ margin: "10px" }}>{t(options.key)}</p>
			</div>,
			((options.count / sumTotal) * 100).toFixed(1),
			options.count.toLocaleString(),
		];
	});

	return (
		isValidOptions && (
			<WhiteBlockDiv text={text}>
				<Table
					className={
						"justify-center w-full max-h-300 h-fit md:max-h-400 overflow-auto"
					}
					columns={columns}
					rows={rows}
				/>
			</WhiteBlockDiv>
		)
	);
}

export default OptionsGraph;
