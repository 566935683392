import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Permissions from "../../../../../common/config/permissions";
import { useAuth } from "../../../../../common/context/AuthContext";
import Restricted from "../../../../../common/context/hooks/Restricted";
import CustomButton from "../../../../../common/layouts/Global/CustomButton";
import LoadingSpinner from "../../../../../common/layouts/Global/LoadingSpinner";
import {
	RoundButtonPen,
	RoundButtonPlus,
	RoundButtonXMark,
} from "../../../../../common/layouts/Global/RoundButton";
import WhiteBlockDiv from "../../../../../common/layouts/Global/WhiteBlockDiv";
import TableDraggable from "../../../../../common/layouts/tables/TableDraggable";
import { FetchPermissions } from "../../../../../common/requests/Permission";
import {
	ToastAfterLoading,
	ToastLoading,
} from "../../../../../common/util/ToastUtil";
import {
	AddRole,
	DeleteRole,
	FetchRoles,
	UpdateRole,
	UpdateRolesRank,
} from "../../../../requests/dashboard/Roles";
import CreateRolePopup from "../../Popup/CreateRolePopup";
import DeletePopup from "../../Popup/DeletePopup";
import ModifyRolePopup from "../../Popup/ModifyRolePopup";
import RolePermissionsPopup from "../../Popup/RolePermissionsPopup";

function RolesSection({ groupId }) {
	const { t } = useTranslation();
	const { inGroup, staff } = useAuth();
	const { data, refetch } = useQuery([`group-${groupId}-roles`], {
		queryFn: () => FetchRoles(groupId),
	});
	const { data: avPermission } = useQuery(
		[`available-permissions-${groupId}`],
		{
			queryFn: () => FetchPermissions(groupId),
		}
	);
	const [deletePopupOpen, setDeletePopupOpen] = useState(false);
	const [createPopupOpen, setCreatePopupOpen] = useState(false);
	const [modifyPopupOpen, setModifyPopupOpen] = useState(false);
	const [rolePermissionsPopupOpen, setRolePermissionsPopupOpen] =
		useState(false);
	const [roleModify, setRoleModify] = useState(null);
	const [rolesOrderModified, setRolesOrderModified] = useState(false);

	const [updatedRolesList, setUpdatedRolesList] = useState(null);
	const [canDrag, setCanDrag] = useState(true);
	const [fetch, setFetch] = useState(0);
	useEffect(() => {
		const fetchRoles = async () => {
			refetch();
			const rolesList = data ? data?.data : [];
			if (data) {
				setUpdatedRolesList(rolesList);
			}
		};
		fetchRoles();
	}, [fetch, groupId, data]);

	const openDeletePopup = (role) => {
		setRoleModify(role);
		setDeletePopupOpen(true);
	};

	const closeDeletePopup = () => {
		setDeletePopupOpen(false);
	};

	const openCreatePopup = () => {
		setCreatePopupOpen(true);
	};

	const closeCreatePopup = () => {
		setCreatePopupOpen(false);
	};

	const openModifyPopup = (role) => {
		setRoleModify(role);
		setModifyPopupOpen(true);
	};

	const closeModifyPopup = () => {
		setModifyPopupOpen(false);
	};

	const openRolePermissionsPopup = (role) => {
		setRoleModify(role);
		setRolePermissionsPopupOpen(true);
	};

	const closeRolePermissionsPopup = () => {
		setRolePermissionsPopupOpen(false);
	};

	const modifyRole = async (newRoleName) => {
		closeModifyPopup();
		ToastLoading("updateRole");
		const data = await UpdateRole(
			roleModify.id,
			newRoleName,
			[],
			roleModify.rank
		);
		ToastAfterLoading("updateRole", data?.success, data?.data?.message);
		setFetch((prev) => prev + 1);
	};

	const modifyRolePermissions = async (newPermissions) => {
		closeRolePermissionsPopup();
		ToastLoading("updatePermissionRole");
		const data = await UpdateRole(
			roleModify.id,
			roleModify.name,
			newPermissions,
			roleModify.rank
		);
		ToastAfterLoading(
			"updatePermissionRole",
			data?.success,
			data?.data?.message
		);
		setFetch((prev) => prev + 1);
	};

	const createRole = async (roleName, permissions) => {
		closeCreatePopup();
		ToastLoading("createRole");
		const data = await AddRole(groupId, roleName, permissions);
		ToastAfterLoading("createRole", data?.success, data?.data?.message);
		setFetch((prev) => prev + 1);
	};

	const deleteRole = async () => {
		closeDeletePopup();
		ToastLoading("deleteRole");
		const data = await DeleteRole(roleModify.id);
		ToastAfterLoading("deleteRole", data?.success, data?.data?.message);

		setFetch((prev) => prev + 1);
	};

	const modifyRolesOrder = async () => {
		await Promise.all(
			updatedRolesList.map(async (role, index) =>
				UpdateRole(role.id, role.name, [], index + 1)
			)
		);
		setFetch((prev) => prev + 1);
		setRolesOrderModified(false);
	};

	const cancelModifyRolesOrder = () => {
		setFetch((prev) => prev + 1);
		setRolesOrderModified(false);
	};

	const columns = [t("roles.rank"), t("role"), t("actions"), t("actions")];
	const rows = updatedRolesList?.map((role) => {
		const rankDiff = role.rank >= avPermission?.data?.rank + 1;
		return [
			role.id,
			role.rank,
			role.name,
			true ? (
				<CustomButton
					className={"min-w-fit whitespace-nowrap"}
					text={t("roles.see.permissions")}
					onClick={() => openRolePermissionsPopup(role)}
				/>
			) : null,
			staff() || !inGroup(groupId) || rankDiff ? (
				<Restricted
					to={[Permissions.GROUP_ROLE_EDIT]}
					groupId={groupId}
				>
					<RoundButtonPen
						functionCall={() => openModifyPopup(role)}
					/>
					<RoundButtonXMark
						functionCall={() => openDeletePopup(role)}
					/>
				</Restricted>
			) : null,
		];
	});
	const handleDragFunction = async (roles) => {
		setCanDrag(false);
		await UpdateRolesRank(
			groupId,
			roles.map((role) => {
				return { role_id: role[0], rank: role[1] };
			})
		).then(() => refetch());
		setCanDrag(true);
	};
	if (!updatedRolesList) return <LoadingSpinner />;
	return (
		<WhiteBlockDiv
			className="mb-2 sm:mb-0"
			text={
				<>
					{t("roles")}{" "}
					<Restricted
						to={[Permissions.GROUP_ROLE_EDIT]}
						groupId={groupId}
					>
						<RoundButtonPlus functionCall={openCreatePopup} />
					</Restricted>
				</>
			}
		>
			<TableDraggable
				columns={columns}
				rows={rows}
				handleDragFunction={handleDragFunction}
				canDrag={canDrag}
				setCanDrag={setCanDrag}
				groupId={groupId}
			/>
			{rolesOrderModified && (
				<div>
					<button onClick={() => cancelModifyRolesOrder()}>
						{t("cancel")}
					</button>
					<button onClick={() => modifyRolesOrder()}>
						{t("apply")}
					</button>
				</div>
			)}
			<CreateRolePopup
				open={createPopupOpen}
				closeMethod={closeCreatePopup}
				createMethod={createRole}
				groupId={groupId}
			></CreateRolePopup>
			{roleModify && (
				<RolePermissionsPopup
					open={rolePermissionsPopupOpen}
					closeMethod={closeRolePermissionsPopup}
					modifyMethod={modifyRolePermissions}
					currentRole={roleModify}
					groupId={groupId}
				/>
			)}
			{roleModify && (
				<ModifyRolePopup
					open={modifyPopupOpen}
					closeMethod={closeModifyPopup}
					modifyMethod={modifyRole}
					currentRole={roleModify}
				></ModifyRolePopup>
			)}
			<DeletePopup
				open={deletePopupOpen}
				closeMethod={closeDeletePopup}
				deleteMethod={deleteRole}
				typeOfDelete={"role"}
			></DeletePopup>
		</WhiteBlockDiv>
	);
}
export default RolesSection;
