import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import CustomDateInput from "../../Global/CustomDateInput";
import CustomSelectOption from "../../Global/CustomSelectOption";
import FloatingLabelInput from "../../Global/InputFloatingLabel";

function FilterTypeDate({ currentEditingFilterData, handleChange }) {
	const { t } = useTranslation();

	const operators = [
		{
			name: "filter.equal",
			operator: "=",
		},
		{
			name: "filter.last",
			operator: "in_the_last",
		},
		{
			name: "filter.after",
			operator: ">",
		},
		{
			name: "filter.before",
			operator: "<",
		},
		{
			name: "filter.between",
			operator: "between",
		},
	];

	useEffect(() => {
		if (!currentEditingFilterData.operator)
			handleChange({
				...currentEditingFilterData,
				operator: operators[0].operator,
			});

		if (
			currentEditingFilterData?.operator === "in_the_last" &&
			!currentEditingFilterData.secondValue
		)
			handleChange({
				...currentEditingFilterData,
				secondValue: "days",
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentEditingFilterData.operator]);

	return (
		<div>
			<CustomSelectOption
				value={
					operators.find(
						(o) => o.operator === currentEditingFilterData.operator
					)?.name
				}
				onChange={(e) =>
					handleChange({
						operator: operators.find(
							(o) => o.name === e.target.value
						).operator,
					})
				}
				values={operators.map((o) => o.name)}
			/>

			{currentEditingFilterData.operator === "between" ? (
				<div>
					<CustomDateInput
						classNameInput="px-2 py-1"
						classNameGlobal="mt-2"
						value={currentEditingFilterData.firstValue}
						onChange={(e) =>
							handleChange({
								...currentEditingFilterData,
								firstValue: e.target.value,
							})
						}
					/>
					<p className="inline mx-1">{t("and")}</p>
					<CustomDateInput
						classNameInput="px-2 py-1"
						value={currentEditingFilterData.secondValue || ""}
						onChange={(e) =>
							handleChange({
								...currentEditingFilterData,
								secondValue: e.target.value,
							})
						}
					/>
				</div>
			) : currentEditingFilterData.operator === "in_the_last" ? (
				<div className="mt-2 flex gap-2">
					<FloatingLabelInput
						className="px-2 py-1 w-auto"
						value={currentEditingFilterData.firstValue || ""}
						placeholder={0}
						onChange={(e) =>
							handleChange({
								...currentEditingFilterData,
								firstValue: e.target.value,
							})
						}
					/>
					<CustomSelectOption
						value={currentEditingFilterData.secondValue || "days"}
						onChange={(e) =>
							handleChange({
								...currentEditingFilterData,
								secondValue: e.target.value,
							})
						}
						values={["days", "months"]}
					/>
				</div>
			) : (
				<CustomDateInput
					classNameInput="px-2 py-1"
					classNameGlobal="mt-2"
					value={currentEditingFilterData.firstValue || ""}
					onChange={(e) =>
						handleChange({
							...currentEditingFilterData,
							firstValue: e.target.value,
						})
					}
				/>
			)}
		</div>
	);
}

export default FilterTypeDate;
