import { useQuery } from "@tanstack/react-query";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { DimensionsContext } from "../../../../common/context/DimensionsContext";
import { TreeContext } from "../../../../common/context/TreeContext";
import DataPeriod from "../../../../common/layouts/Global/DataPeriod";
import LoadingSpinner from "../../../../common/layouts/Global/LoadingSpinner";
import { FetchMachineStats } from "../../../../portail/requests/machines/FetchMachine";
import MachineStats from "../../machines/MachineStats";

function MachineDashboard() {
	const { t } = useTranslation();
	document.title = `KUPA Station | ${t("dashboard")}`;
	const { setUpdateTree } = useContext(TreeContext);
	const { machineId, groupId } = useParams();
	const { endDateAPI, startDateAPI } = useContext(DimensionsContext);

	const { data, refetch } = useQuery([`MachinesStatsw-${machineId}`], {
		queryFn: () =>
			FetchMachineStats(machineId, { startDateAPI, endDateAPI }),
	});
	const [reload, setReload] = useState(false);
	useEffect(() => {
		setUpdateTree((prev) => prev + 1);
	}, []);
	useEffect(() => {
		const refetchFunction = async () => {
			setReload(true);
			await refetch();
			setReload(false);
		};
		refetchFunction();
	}, [startDateAPI, endDateAPI]);
	return (
		<div>
			<DataPeriod />
			<div className="flex "></div>
			{data ? (
				data?.success ? (
					<MachineStats
						groupId={groupId}
						machineId={machineId}
						data={data.data}
						reload={reload}
					/>
				) : null
			) : (
				<LoadingSpinner />
			)}
		</div>
	);
}

export default MachineDashboard;
