const SCORES = {
	// Arduino MEGA disconnected
	"1-1": {
		score: 50,
		classNames: [],
	},
	"arduino-mega": {
		score: 50,
		classNames: [],
	},

	// Arduino UNO disconnected while card required
	"1-2": {
		score: 50,
		classNames: [],
	},
	"arduino-uno": {
		score: 50,
		classNames: [],
	},

	// USB Touchscreen disconnected
	"2-1": {
		score: 50,
		classNames: [],
	},
	touchscreen: {
		score: 50,
		classNames: [],
	},

	// Payment terminal disconnected while payments enabled
	"3-1": {
		score: 50,
		classNames: [],
	},
	paymentMachine: {
		score: 50,
		classNames: [],
	},

	// QR reader disconnected while bluetooth disabled
	"4-1": {
		score: 50,
		classNames: [],
	},
	"qr-scanner": {
		score: 50,
		classNames: [],
	},

	"water-temperature": {
		score: 500,
		classNames: ["bg-yellow-100"],
	},

	"40-1": {
		// Cooling unit temperature below 0ºC
		score: 500,
		classNames: ["bg-yellow-100"],
	},
	"40-2": {
		// Cooling unit temperature above 10ºC
		score: 500,
		classNames: ["bg-yellow-100"],
	},

	"42-1": {
		// Nothing output while FLAT drink
		score: 500,
		classNames: ["bg-yellow-100"],
	},
	"42-2": {
		// Nothing output while SPARKLING drink
		score: 500,
		classNames: ["bg-yellow-100"],
	},
	"42-3": {
		// Only CO2 output while SPARKLING drink
		score: 500,
		classNames: ["bg-yellow-100"],
	},

	"41-1": {
		// Tray bottom sensor water leak
		score: 1000,
		classNames: ["bg-blue-100"],
	},
	"41-2": {
		// Flow meter detection while no drink
		score: 1000,
		classNames: ["bg-blue-100"],
	},

	/** Old sensors */
	"water-flow": {
		score: 1000,
		classNames: ["bg-yellow-100"],
	},
	"water-sensor": {
		score: 1000,
		classNames: ["bg-blue-100"],
	},
	"water-leak": {
		score: 1000,
		classNames: ["bg-blue-100"],
	},
};

function GetMachineStatus(machine) {
	const status = {
		score: 0,
		className: "bg-ghostwhite",
	};

	for (const item of machine.hardware_status) {
		if (item.status !== true) {
			const score = SCORES[item.key];

			if (!score) continue;

			status.score += score.score;
			if (score.classNames.length > 0) {
				status.className = score.classNames.join(" ");
			}
		}
	}

	if (!machine.isOnline) {
		status.score += 10;
	}

	if (!machine.isVideoOnline) {
		status.score += 10;
	}

	return status;
}

export { GetMachineStatus };
