import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Popup from "reactjs-popup";
import CustomButton from "../../../../common/layouts/Global/CustomButton";
import FloatingLabelInput from "../../../../common/layouts/Global/InputFloatingLabel";
import PopupHeadTitle from "../../../../common/layouts/Global/PopupHeadTitle";
import { RoundButtonXMarkAbsolute } from "../../../../common/layouts/Global/RoundButton";
import { ToastNotSavedCustom } from "../../../../common/util/ToastUtil";
import NotificationTypes from "../NotificationChecked";

function CreateNotifMemberPopup({ open, types, closeMethod, createMethod }) {
	const { t } = useTranslation();
	const memberEmailInput = useRef(null);

	const [active, setActive] = useState(false);
	const [activeSubgroup, setActiveSubgroup] = useState(false);
	const [notificationTypes, setNotificationTypes] = useState([]);

	const isValidEmail = (memberEmail) => {
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return emailRegex.test(memberEmail);
	};
	const handleChange = (type) => {
		if (notificationTypes?.includes(type)) {
			setNotificationTypes((prev) =>
				prev.filter((element) => element !== type)
			);
			return;
		}
		setNotificationTypes((prev) => [...prev, type]);
	};
	return (
		<Popup
			open={open}
			onClose={closeMethod}
			contentStyle={{
				borderRadius: "0.75rem",
				width: "90%",
				maxWidth: "400px",
				padding: "10px",
			}}
			className="relative"
		>
			<RoundButtonXMarkAbsolute functionCall={closeMethod} />

			<PopupHeadTitle text={t("create.member.notification.title")} />

			<div className="text-center mt-2 p-1">
				<div
					style={{
						//display: "flex",
						//flexDirection: "column",
						alignItems: "center",
					}}
				>
					<FloatingLabelInput
						type="email"
						autoComplete="false"
						reference={memberEmailInput}
						name={t("create.member.member.email")}
					/>
					<div className="text-left">
						<div className="ml-1">
							<input
								className="mr-1"
								type="checkbox"
								checked={active}
								onChange={(e) => setActive(e.target.checked)}
							/>
							{t("enabled")}
						</div>
						<div className="ml-1">
							<input
								className="mr-1"
								type="checkbox"
								checked={activeSubgroup}
								onChange={(e) =>
									setActiveSubgroup(e.target.checked)
								}
							/>
							{t("members.active.subgroup")}
						</div>
						<NotificationTypes
							notificationTypes={types}
							currentNotificationTypes={notificationTypes}
							handleChange={handleChange}
						/>
					</div>
				</div>
			</div>
			<div className="flex justify-end">
				<CustomButton
					className="md:w-40 w-full"
					text={t("add")}
					onClick={() => {
						const memberEmail = memberEmailInput.current.value;

						if (!isValidEmail(memberEmail)) {
							ToastNotSavedCustom(t("reset.inputError"));
						} else {
							createMethod(
								memberEmail,
								active,
								activeSubgroup,
								notificationTypes
							);
						}
					}}
				/>
			</div>
		</Popup>
	);
}
export default CreateNotifMemberPopup;
