import React, { useState } from "react";
import Warning from "../../../common/assets/warning.png";
import gifImage from "../../assets/draganddrop.gif";
import VideoInput from "./VideoInput";
function Video({ loading, setoriginDiv, refetchGalery }) {
	const [overItemid, setOverItemId] = useState(false);
	// styles
	const stylesVideo = {
		height: "100px",
		width: "150px",
	};

	// functions
	const handleDragStart = (event, itemId) => {
		setoriginDiv(true);
		event.dataTransfer.setData("text/plain", itemId);
	};

	const handleMouseOver = (item) => setOverItemId(item.id);
	const handleMouseOut = () => setOverItemId([]);

	return (
		<>
			<VideoInput refetchGalery={refetchGalery} />
			{loading &&
				loading?.map((item, index) => (
					<div className="text-center w-auto" key={item.id}>
						<div
							key={item.id}
							onMouseOver={() => handleMouseOver(item)}
							onMouseOut={handleMouseOut}
							style={{
								height: "100px",
								width: "150px",
								display: "flex",
								flexDirection: "row-reverse",
								margin: "10px",
								marginBottom: 0,
								marginLeft: "20px",
								position: "relative",
							}}
						>
							{!item.approved && (
								<div className="text-xs bg-orange-400  uppercase absolute -left-5  rounded-lg   text-white px-1 py-[2px] shadow-md">
									{" "}
									En approbation
								</div>
							)}
							<div
								className="thumbnail-container "
								style={{
									backgroundColor: "transparent",
									boxShadow:
										"rgba(0, 0, 0, 0.24) 0px 3px 8px",
									margin: "2px",
									width: "250px",
									border:
										overItemid === item.id
											? "8px solid #00acd3"
											: "8px solid transparent",
									borderRadius: "10px",
									backgroundImage: `url(${
										(item &&
											item.thumbnail &&
											item.thumbnail[0]?.large?.url) ||
										Warning
									})`,
									backgroundSize: "cover",
									backgroundPosition: "center",
								}}
							>
								<div
									className="video-container"
									style={{
										...stylesVideo,
										backgroundImage:
											overItemid === item.id &&
											item.approved
												? `url(${gifImage})`
												: null,
										backgroundSize: "cover",
										backgroundPosition: "center",
									}}
									draggable
									onDragStart={(event) =>
										handleDragStart(event, item.id)
									}
								></div>
							</div>
						</div>
						<div
							className="text-black text-center truncate w-[150px] text-sm"
							style={{ display: "inline-block" }}
						>
							<span
								className="w-[150px] overflow-hidden text-ellipsis"
								style={{ display: "inline-block" }}
							>
								{item.name}
							</span>
						</div>
					</div>
				))}
		</>
	);
}

export default Video;
