import { requestAPI } from "../../../common/requests/RequestAPI";
import URL from "../../../common/requests/UrlsEnum";

export async function FetchAllMachines(Id) {
	let data = await requestAPI(
		URL.MACHINES_GROUP_SUBGROUPS.replace("{id}", Id)
	);

	return data.data;
}

export async function FetchMachineGroup(Id) {
	let data = await requestAPI(URL.MACHINES_GROUP.replace("{id}", Id));

	return data.data;
}
