import { useQuery } from "@tanstack/react-query";
import { useLocation } from "react-router-dom";
import GroupTag from "../../../portail/layouts/Tree/GroupTag";
import StoreGroupTag from "../../../store/layouts/Tree/StoreGroupTag";
import { FetchTree } from "../../requests/tree/Tree";
import LoadingSpinner from "../Global/LoadingSpinner";

function Tree({ sizeTree, handleClickInsideModal, openModal }) {
	const { pathname } = useLocation();
	const store = pathname.includes("store") ?? false;
	const { data, isLoading } = useQuery([`tree`], {
		queryFn: () => FetchTree(),
		//enabled: !store,
	});

	if (isLoading) return <LoadingSpinner />;
	return store ? (
		<div>
			<StoreGroupTag
				data={data}
				handleClickInsideModal={handleClickInsideModal}
			/>
		</div>
	) : (
		<GroupTag
			data={data}
			handleClickInsideModal={handleClickInsideModal}
			openModal={openModal}
		/>
	);
}

export default Tree;
