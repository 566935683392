import { requestAPI } from "../../../common/requests/RequestAPI";
import URL from "../../../common/requests/UrlsEnum";

export async function GetGroupVideoSchedule(groupId) {
	let data = await requestAPI(
		URL.GROUP_VIDEO_SCHEDULE.replace("{id}", groupId)
	);
	return data;
}

export async function CreateGroupVideoSchedule(
	groupId,
	videoModelId,
	startDate,
	endDate
) {
	let data = await requestAPI(
		URL.GROUP_VIDEO_SCHEDULE.replace("{id}", groupId),
		"POST",
		{
			videoModelId,
			startDate: new Date(startDate).toUTCString(),
			endDate: endDate ? new Date(endDate).toUTCString() : null,
		}
	);

	return data;
}
export async function ModifyGroupVideoSchedule(
	groupId,
	groupVideoScheduleId,
	videoModelId,
	start,
	end
) {
	let data = await requestAPI(
		URL.GROUP_VIDEO_SCHEDULE_ID.replace("{id}", groupId).replace(
			"{videoSchedule}",
			groupVideoScheduleId
		),
		"PUT",
		{
			videoModelId,
			start: new Date(start).toUTCString(),
			end: end ? new Date(end).toUTCString() : null,
		}
	);
	return data;
}

export async function DeleteGroupVideoSchedule(groupId, groupVideoScheduleId) {
	let data = await requestAPI(
		URL.GROUP_VIDEO_SCHEDULE_ID.replace("{id}", groupId).replace(
			"{videoSchedule}",
			groupVideoScheduleId
		),
		"DELETE",
		{}
	);
	return data;
}
