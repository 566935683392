import { useTranslation } from "react-i18next";
import CustomCheckbox from "../../../common/layouts/Global/CustomCheckbox";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faCaretRight } from "@fortawesome/free-solid-svg-icons";

export function MachineFilter({
	machineIds = [],
	selectedIds = [],
	setSelectedIds,
}) {
	const { t } = useTranslation();

	const toggleMachine = (id) => {
		if (id === "all") {
			setSelectedIds(["all"]);
			return;
		}

		if (selectedIds.includes("all")) {
			setSelectedIds([id]);
			return;
		}

		if (selectedIds.includes(id)) {
			const newIds = selectedIds.filter((item) => item !== id);
			setSelectedIds(newIds.length ? newIds : ["all"]);
			return;
		}

		const updatedIds = [...selectedIds, id];
		if (updatedIds.length === machineIds.length) {
			setSelectedIds(["all"]);
		} else {
			setSelectedIds(updatedIds);
		}
	};

	const renderMachines = () => {
		return machineIds.map((id, index) => (
			<CustomCheckbox
				key={index}
				checked={selectedIds.includes(id?.id)}
				onChange={() => toggleMachine(id?.id)}
				value={t(id?.name)}
			/>
		));
	};
	const [isOpen, setIsOpen] = useState(false);

	let displayText;
	if (selectedIds.includes("all")) {
		displayText = t("all.stations");
	} else if (selectedIds.length > 0) {
		displayText = selectedIds.length;
	} else {
		displayText = t("all.stations");
	}

	return (
		<div className=" relative">
			<div className="text-xs sm:text-sm rounded-full pl-0 p-1 bg-white">
				<p
					className="inline cursor-pointer pl-0.5 p-2"
					onClick={() => setIsOpen((prev) => !prev)}
				>
					<FontAwesomeIcon
						className={`${
							isOpen ? "rotate-90" : ""
						} transition-transform ease-in duration-200 mr-2`}
						icon={faAngleRight}
					/>
					{t("stations")} ({displayText})
				</p>
			</div>

			{isOpen && (
				<div className="items-center fixed h-52 overflow-auto z-20 min-w-[160px]">
					<FontAwesomeIcon
						icon={faCaretRight}
						className="text-white text-2xl absolute -top-1 left-4 -rotate-90"
					/>
					<div className="bg-white rounded-[5px] shadow-lg mt-2 overflow-y-auto p-2">
						<div>
							<div>
								<CustomCheckbox
									checked={selectedIds.includes("all")}
									onChange={() => toggleMachine("all")}
									value={t("all.stations")}
								/>
							</div>
							<div className="w-full border-b-[1px] my-1.5 border-gray-300" />
							{renderMachines()}
						</div>
					</div>
				</div>
			)}
		</div>
	);
}
export function MachineList({ machineNames = [] }) {
	const { t } = useTranslation();
	const renderMachines = () => {
		return machineNames.map((name, index) => (
			<div className="mx-2">{t(name)}</div>
		));
	};
	const [isOpen, setIsOpen] = useState(false);

	let displayText;
	if (machineNames.includes("all")) {
		displayText = t("all.stations");
	} else if (machineNames.length > 0) {
		displayText = machineNames.length;
	} else {
		displayText = t("all.stations");
	}

	return (
		<div className=" relative">
			<div className="text-xs sm:text-sm rounded-full p-0.5 bg-white">
				<p
					className="inline cursor-pointer pl-0.5 p-2"
					onClick={() => setIsOpen((prev) => !prev)}
				>
					<FontAwesomeIcon
						className={`${
							isOpen ? "rotate-90" : ""
						} transition-transform ease-in duration-200 mr-2`}
						icon={faAngleRight}
					/>
					{t("stations")} ({displayText})
				</p>
			</div>

			{isOpen && (
				<div className="items-center fixed h-52 overflow-auto z-20 min-w-[160px]">
					<FontAwesomeIcon
						icon={faCaretRight}
						className="text-white text-2xl absolute -top-1 left-4 -rotate-90"
					/>
					<div className="bg-white rounded-[5px] shadow-lg mt-2 overflow-y-auto p-2">
						{renderMachines()}
					</div>
				</div>
			)}
		</div>
	);
}
