import { useQuery } from "@tanstack/react-query";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { DimensionsContext } from "../../../common/context/DimensionsContext";
import DataPeriod from "../../../common/layouts/Global/DataPeriod";
import LoadingSpinner from "../../../common/layouts/Global/LoadingSpinner";
import { FetchGroupDashboardStats } from "../../requests/Group";
import Stats from "../Stats";
import { useTranslation } from "react-i18next";

function GroupDashboard() {
	const { t } = useTranslation();
	document.title = `KUPA Station | ${t("dashboard")}`;

	const { groupId } = useParams();
	const { endDateAPI, startDateAPI } = useContext(DimensionsContext);

	const { data: stats, refetch } = useQuery(
		[`stats-${groupId}-${startDateAPI}-${endDateAPI}`],
		{
			queryFn: () =>
				FetchGroupDashboardStats(groupId, { startDateAPI, endDateAPI }),
		}
	);
	const [reload, setReload] = useState(false);
	useEffect(() => {
		const refetchFunction = async () => {
			setReload(true);
			await refetch();
			setReload(false);
		};
		refetchFunction();
	}, [startDateAPI, endDateAPI]);
	return (
		<div>
			<DataPeriod />
			{stats ? (
				<Stats
					boissonsParMois={stats?.monthlyOrders}
					appVsMobile={stats?.appVsStation}
					boissons={stats?.labeledSaveursVendues}
					topMachines={stats?.topMachines}
					groupId={groupId}
					isLoading={reload}
				/>
			) : (
				<LoadingSpinner />
			)}
		</div>
	);
}

export default GroupDashboard;
