import { useNavigate } from "react-router-dom";
import CustomButton from "../../../common/layouts/Global/CustomButton";

import { useTranslation } from "react-i18next";

function QuitSave({
	close,
	saveSettings,
	showSave,
	showNotSave,
	showQuit,
	name,
	secondText,
}) {
	const { t } = useTranslation();
	const navigate = useNavigate();

	return (
		<div className="modal">
			<h1 className="text-center  mt-3 text-lg">
				{t("save.question")} {name} ?
			</h1>
			<h2 className="text-center mt-2  text-base">{secondText}</h2>
			<div className="flex flex-wrap gap-2 justify-center mt-6 mb-3">
				{showSave && (
					<CustomButton
						text={t("save")}
						className="bg-kupagreen hover:bg-kupagreenhover"
						onClick={() => {
							saveSettings();
							close();
						}}
					/>
				)}

				{showNotSave && (
					<CustomButton
						text={t("not.save")}
						className="bg-kupared hover:bg-kuparedhover"
						onClick={() => navigate(-1)}
					/>
				)}

				{showQuit && (
					<CustomButton
						text={t("cancel")}
						className="bg-kupablue hover:bg-kupabluedark"
						onClick={() => close()}
					/>
				)}
			</div>
		</div>
	);
}

QuitSave.defaultProps = {
	showSave: true,
	showNotSave: true,
	showQuit: true,
};

export default QuitSave;
