/* eslint-disable import/no-anonymous-default-export */
const API_URL = "https://api.admin.kupastation.com";
//const API_URL = "https://apimain.kupastation.ca";
//const API_URL = "http://localhost:3000";
const VERSION = "v1";

export default {
	USER: `${API_URL}/${VERSION}/user`,
	USER_VERIFY_EMAIL: `${API_URL}/${VERSION}/user/verify-email`,
	RESET_PIN: `${API_URL}/${VERSION}/user/reset-pin`,

	DASHBOARD: `${API_URL}/${VERSION}/dashboard`,

	MACHINE_SETTINGS: `${API_URL}/${VERSION}/machine/{id}/settings`,
	MACHINE: `${API_URL}/${VERSION}/machine/{id}`,
	MACHINE_NAME: `${API_URL}/${VERSION}/machine/{id}/name`,

	MACHINES_GROUP_SUBGROUPS: `${API_URL}/${VERSION}/group/{id}/machines`,
	MACHINES_GROUP: `${API_URL}/${VERSION}/group/{id}/machinesofgroup`,

	MACHINE_STATS: `${API_URL}/${VERSION}/stats/machine/{id}`,

	FLAVORS_AND_OPTIONS: `${API_URL}/${VERSION}/searchutilities`,

	ORDER: `${API_URL}/${VERSION}/order/search`,

	ORDER_EXPORT: `${API_URL}/${VERSION}/order/export`,

	USERS: `${API_URL}/${VERSION}/user/all`,

	ADD_CREDIT: `${API_URL}/${VERSION}/user/{id}/add_credit`,

	MACHINE_QUICK_INFOS: `${API_URL}/${VERSION}/stats/machine/{id}/quick`,

	GROUP_STATS_HEADER: `${API_URL}/${VERSION}/stats/group/{id}/header`,
	GROUP_STATS: `${API_URL}/${VERSION}/stats/group/{id}`,

	LOG: `${API_URL}/${VERSION}/log/search`,
	ERROR: `${API_URL}/${VERSION}/error/search`,
	SEARCH_UTILITIES: `${API_URL}/${VERSION}/searchutilities`,

	GROUP: `${API_URL}/${VERSION}/group`,
	GET_IMAGE: `${API_URL}/public/{filename}`,

	PERMISSION: `${API_URL}/${VERSION}/permission/{groupId}`,

	VISIBLE_MACHINES: `${API_URL}/${VERSION}/searchutilities/machines/{groupId}`,
	VISIBLE_DISCOUNTS: `${API_URL}/${VERSION}/searchutilities/discounts/{groupId}`,
	VISIBLE_LOGS: `${API_URL}/${VERSION}/searchutilities/logs/{groupId}`,
	ROLES_RANK_EDIT: `${API_URL}/${VERSION}/group/{id}/roles`,
	DISCOUNT: `${API_URL}/${VERSION}/discount`,

	NOTIF: `${API_URL}/${VERSION}/group/{id}/member_notif`,
	VIDEO: `${API_URL}/${VERSION}/video/{id}`,
	GALLERY: `${API_URL}/${VERSION}/video/group/{id}`,
	SEQUENCES: `${API_URL}/${VERSION}/video/group/{id}/sequences`,
	SEQUENCE: `${API_URL}/${VERSION}/video/group/{id}/sequence/{sequenceId}`,

	DELETE_VIDEO: `${API_URL}/${VERSION}/video/group/{id}`,

	ONEDRIVE_UPLOAD_URL: `${API_URL}/${VERSION}/video/group/{id}/createuploadsession`,
	ONEDRIVE_UPLOAD: `${API_URL}/${VERSION}/video/group/{id}/upload`,

	GROUP_VIDEO_SCHEDULE: `${API_URL}/${VERSION}/videoschedulegroup/group/{id}`,
	GROUP_VIDEO_SCHEDULE_ID: `${API_URL}/${VERSION}/videoschedulegroup/{videoSchedule}/group/{id}`,

	MACHINE_VIDEO_SCHEDULE: `${API_URL}/${VERSION}/videoschedulemachine/group/{id}`,
	MACHINE_VIDEO_SCHEDULE_ID: `${API_URL}/${VERSION}/videoschedulemachine/{videoSchedule}/group/{id}`,

	CATALOG: `${API_URL}/${VERSION}/store/{groupId}/catalog`,
	STORE_ORDER: `${API_URL}/${VERSION}/store/order`,
	STORE_ORDER_SEARCH: `${API_URL}/${VERSION}/store/ordersearch`,
	STORE_ORDER_ID: `${API_URL}/${VERSION}/store/order/{id}`,
	STORE_GROUP_ORDER: `${API_URL}/${VERSION}/store/{groupId}/order`,

	// Translation
	TRANSLATION: `${API_URL}/${VERSION}/translation/{{lng}}/PORTAL`,
};
