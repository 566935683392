import { Line } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import WhiteBlockDiv from "../../../common/layouts/Global/WhiteBlockDiv";
import moment from "moment";

function Boissons({ stats = 0 }) {
	const { t } = useTranslation();

	const options = {
		responsive: true,
		plugins: {
			legend: {
				position: "bottom",
			},
		},
	};

	const dataLabels = Array.from(stats)
		.map((i) => t(moment.months(i.month - 1).toLowerCase()))
		.reverse();

	const data = {
		labels: dataLabels,
		datasets: [
			{
				label: t("all"),
				data: Array.from(stats)

					.map(
						(t) =>
							t.count_paid_price_zero +
							t.count_paid_price_non_zero
					)
					.reverse(),
				borderColor: "rgb(28, 163, 236)",
				backgroundColor: "rgb(28, 163, 236)",
			},
			{
				label: t("free"),
				data: Array.from(stats)

					.map((t) => t.count_paid_price_zero)
					.reverse(),
				borderColor: "rgb(173, 255, 47)",
				backgroundColor: "rgb(173, 255, 47)",
			},
			{
				label: t("paid"),
				data: Array.from(stats)

					.map((t) => t.count_paid_price_non_zero)
					.reverse(),
				borderColor: "rgb(250,128,114)",
				backgroundColor: "rgb(250,128,114)",
			},
		],
	};
	return (
		<WhiteBlockDiv text={t("by.month")} className={"mb-2 lg:mb-0"}>
			<Line options={options} data={data} />
		</WhiteBlockDiv>
	);
}

export default Boissons;
