import React from "react";
import { useSomePermission } from "./usePermission";

const Restricted = ({ to, groupId, children }) => {
	const allowed = useSomePermission(to, groupId);

	if (!to) return null;
	if (allowed) return <>{children}</>;

	return null;
};

export default Restricted;
