import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
	apiKey: "AIzaSyDtOj3LMni7pDNmzI6_b_Ju2alh8H9_FZg",
	authDomain: "kupa-station-5f78d.firebaseapp.com",
	databaseURL: "https://kupa-station-5f78d.firebaseio.com",
	projectId: "kupa-station-5f78d",
	storageBucket: "kupa-station-5f78d.appspot.com",
	messagingSenderId: "332121866084",
	appId: "1:332121866084:web:fd2222aafaf77439d60471",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export default auth;
