import Permissions from "../../common/config/permissions";

export function filteredAlphabetic(list) {
	return list.sort((a, b) => a.name.localeCompare(b.name));
}

export function filteredIncreasing(list, key) {
	return list.sort((a, b) => {
		return a[key] - b[key];
	});
}

export function filteredMachines(machines, searchValue, hasGlobalPermission) {
	const query = searchValue.toLowerCase();

	if (!Array.isArray(machines)) return [];

	return (
		machines?.filter(
			(machine) =>
				machine.public_name.toLowerCase().includes(query) ||
				machine.serial_number.toLowerCase().includes(query) ||
				machine.private_name.toLowerCase().includes(query) ||
				(hasGlobalPermission(Permissions.GROUP_MEMBER_VIEW) &&
					machine.factory_name.toLowerCase().includes(query))
		) ?? []
	);
}

// keys = [ "name", "serial" ]
export function filteredSearch(list, key, searchValue) {
	const query = searchValue.toLowerCase();
	return list.filter((elem) => elem[key].toLowerCase().includes(query));
}
