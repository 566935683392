import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import logo from "../../../common/assets/logo/kupa.png";
import useImagePath from "./getImagePath";

function Logo({
	isMobile,
	login = false,
	actionLogo,
	customWidthLogin = "350px",
	customWidth = window.innerWidth * 0.17,
}) {
	//EasterEgg
	const easterEggCounterRequired = 10;
	const [easterEggCounter, setEasterEggCounter] = useState(0);

	const logoStyles = {
		padding: isMobile ? "5px" : "10px",
		margingBottom: "15px",
		maxWidth: isMobile ? undefined : login ? customWidthLogin : customWidth,
		//height: isMobile && "60px",
		//EasterEgg
		animation:
			easterEggCounter > easterEggCounterRequired
				? "shakeEasterEgg 2s"
				: "none",
		objectFit: "contain",
		height: isMobile ? "60px" : "100px",
	};

	//EasterEgg
	const incrementsEasterEggCounter = () => {
		setEasterEggCounter(easterEggCounter + 1);

		if (easterEggCounter === easterEggCounterRequired)
			console.log(
				"POUET ! (Gracieuseté de Philippe St-Laurent-Recoura et Abdelilah Araar)"
			);
	};
	const logos = {
		"/": {
			logo: logo,
			customStyles: {
				padding: isMobile ? "15px" : "30px",
				paddingTop: isMobile ? "10px" : "25px",
				paddingBottom: isMobile ? "5px" : "25px",
			},
		},
		store: { logo: useImagePath("KupaStore", isMobile) },
		portal: { logo: useImagePath("KupaPortal", isMobile) },
	};
	const { pathname } = useLocation();
	const navigate = useNavigate();

	const [logoState, setLogoState] = useState("/");
	useEffect(() => {
		if (pathname.includes("portal")) {
			setLogoState("portal");
		} else if (pathname.includes("store")) {
			setLogoState("store");
		} else {
			setLogoState("/");
		}
	}, [pathname]);
	return (
		<img
			onClick={() => {
				if (isMobile) {
					actionLogo();
					return;
				}
				navigate(`/${logoState}`);
				actionLogo();
				incrementsEasterEggCounter();
			}}
			src={logos[logoState]?.logo}
			style={{
				...logoStyles,
				...logos[logoState]?.customStyles,
			}}
			alt="KupaStation Logo"
		/>
	);
}

export default Logo;
