import { QueryClient } from "@tanstack/query-core";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "./common/context/AuthContext";
import { DimensionsProvider } from "./common/context/DimensionsContext";
import { TreeProvider } from "./common/context/TreeContext";

import "./common/assets/language/i18nextInit";
import AppContainer from "./common/containers/AppContainer";
import "./index.css";

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<QueryClientProvider client={queryClient}>
		<AuthProvider>
			<BrowserRouter>
				<DimensionsProvider>
					<TreeProvider>
						<AppContainer />
					</TreeProvider>
				</DimensionsProvider>
			</BrowserRouter>
		</AuthProvider>
		<ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
	</QueryClientProvider>
);
