import {
	EmailAuthProvider,
	createUserWithEmailAndPassword,
	reauthenticateWithCredential,
	reload,
	sendEmailVerification,
	sendPasswordResetEmail,
	signInWithCustomToken,
	signInWithEmailAndPassword,
	signOut,
	updatePassword,
	updateProfile,
} from "firebase/auth";
import { createContext, useContext, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import auth from "../config/firebase";
import LoadingSpinner from "../layouts/Global/LoadingSpinner";
import { FetchTree } from "../requests/tree/Tree";
import {
	FetchSelfInfos,
	ForceCheckEmailVerification,
	GeneratePin,
	UpdateUsername,
} from "../requests/user/Me";
import { ToastAfterLoading, ToastLoading } from "../util/ToastUtil";
import i18next from "i18next";

const AuthContext = createContext();

export function useAuth() {
	return useContext(AuthContext);
}

export function AuthProvider({ children }) {
	const { t } = useTranslation();

	const [loading, setLoading] = useState(true);
	const [firebaseUser, setFirebaseUser] = useState();
	const [kupaUser, setKupaUser] = useState();
	const [tree, setTree] = useState();
	function loginWithCustomToken(token) {
		return signInWithCustomToken(auth, token);
	}

	function login(email, password) {
		if (!email || !password) throw new Error();
		return signInWithEmailAndPassword(auth, email, password);
	}

	async function register(email, password, name) {
		if (!email || !password || !name) throw new Error();

		const user = await createUserWithEmailAndPassword(
			auth,
			email,
			password
		);

		await updateProfile(user, {
			displayName: name,
		});
		auth.languageCode = i18next.language || "en";
		await sendEmailVerification(user);

		return user;
	}

	function sendEmailPasswordReset(email) {
		if (!email) throw new Error();
		auth.languageCode = i18next.language || "en";
		return sendPasswordResetEmail(auth, email);
	}

	function logout() {
		return signOut(auth);
	}

	const refreshSelfInfos = async () => {
		const me = await FetchSelfInfos();
		setKupaUser(me?.data);

		return me;
	};

	const hasGlobalPermission = (permission) => {
		try {
			const permissions = kupaUser?.permissions?.global ?? [];
			return permissions?.includes(permission);
		} catch (error) {
			return false;
		}
	};
	const myPermission = async (groupId) => {
		return kupaUser?.permissions?.[groupId].length
			? kupaUser?.permissions?.[groupId]
			: kupaUser?.permissions?.global ?? [];
	};

	const hasPermission = (permission, groupId) => {
		try {
			const permissions = kupaUser?.permissions[String(groupId)] ?? [];
			const globalPermissions = kupaUser?.permissions?.global ?? [];
			return (
				permissions?.includes(permission) ||
				globalPermissions?.includes(permission)
			);
		} catch (error) {
			return false;
		}
	};

	const updateUsername = async (username) => {
		await updateProfile(auth.currentUser, {
			displayName: username,
		});

		await refreshSelfInfos();

		reload(auth.currentUser);
	};
	const updatePasswordFunction = async (oldPassword, newPassword) => {
		try {
			const user = auth.currentUser;
			const credentials = EmailAuthProvider.credential(
				user.email,
				oldPassword
			);
			const data = await reauthenticateWithCredential(
				auth.currentUser,
				credentials
			);
			await updatePassword(auth.currentUser, newPassword);
			return true;
		} catch (error) {
			return false;
		}
	};
	const inGroup = (groupId) => {
		try {
			const permissions = kupaUser?.groupMember ?? [];
			return permissions?.includes(parseInt(groupId));
		} catch (error) {
			return false;
		}
	};
	function findGroupById(tree, targetId) {
		if (!tree) return null;
		for (const node of tree) {
			if (node.id === targetId) {
				return node;
			}

			if (node.group && node.group.length > 0) {
				const foundInChild = findGroupById(node.group, targetId);
				if (foundInChild) {
					return foundInChild;
				}
			}
		}
		return null;
	}
	const hasAccessGroup = (groupId) => {
		if (staff() || inGroup(groupId)) {
			return true;
		}

		const group = findGroupById(tree, parseInt(groupId));

		if (group && group.type === "CUSTOM" && inGroup(group.parent)) {
			return true;
		}
		return false;
	};
	const myGroups = (groupId) => {
		try {
			const groups = kupaUser?.groupMember ?? [];
			return groups;
		} catch (error) {
			return [];
		}
	};
	const staff = () => {
		try {
			const staff = kupaUser?.permissions?.global?.length >= 1 ?? false;
			return staff;
		} catch (error) {
			return false;
		}
	};
	const generatePin = async () => {
		try {
			ToastLoading("generatingPin");
			const pin = await GeneratePin();
			setKupaUser({ ...kupaUser, pin: pin.data.pin });
			ToastAfterLoading("generatingPin", pin.success, pin?.data?.message);
		} catch (error) {
			return false;
		}
	};

	useEffect(() => {
		const sendEmailValidation = async (user) => {
			toast.dismiss();
			auth.languageCode = i18next.language;
			await sendEmailVerification(user);
			toast.success(
				t("auth.email.verification_sent", {
					0: user.email,
				})
			);
		};

		const unsubscribe = auth.onAuthStateChanged(async (user) => {
			setFirebaseUser(user);
			setLoading(false);
			const tree = await FetchTree();
			setTree(tree);
			if (user) {
				const me = await refreshSelfInfos();

				if (
					user.displayName &&
					me?.data?.user?.name &&
					user.displayName !== me.data.user.name
				)
					await UpdateUsername(user.displayName);

				if (!user.emailVerified) {
					toast.warning(t("auth.email.unverified.resend"), {
						onClick: () => sendEmailValidation(user),
						icon: "📧",
						autoClose: false,
					});

					toast.info(
						t("auth.email.unverified", {
							0: user.email,
						}),
						{
							onClick: () => sendEmailValidation(user),
							autoClose: false,
						}
					);
				}

				if (user.emailVerified && !me?.data?.user?.email_verified_at) {
					await ForceCheckEmailVerification();
				}
			} else {
				setKupaUser(undefined);
			}
		});

		return unsubscribe;
	}, []);

	const value = {
		firebaseUser,
		kupaUser,
		refreshSelfInfos,
		login,
		loginWithCustomToken,
		register,
		sendEmailPasswordReset,
		logout,
		updateUsername,
		hasGlobalPermission,
		hasPermission,
		myPermission,
		inGroup,
		myGroups,
		staff,
		generatePin,
		updatePasswordFunction,
		hasAccessGroup,
	};

	return loading ? (
		<LoadingSpinner size={200} />
	) : (
		<AuthContext.Provider value={value}>{children}</AuthContext.Provider>
	);
}
