import { useQuery } from "@tanstack/react-query";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Popup from "reactjs-popup";
import Permissions from "../../../../common/config/permissions";
import { useAuth } from "../../../../common/context/AuthContext";
import { DimensionsContext } from "../../../../common/context/DimensionsContext";
import CustomButton from "../../../../common/layouts/Global/CustomButton";
import FloatingLabelInput from "../../../../common/layouts/Global/InputFloatingLabel";
import PopupHeadTitle from "../../../../common/layouts/Global/PopupHeadTitle";
import { RoundButtonXMarkAbsolute } from "../../../../common/layouts/Global/RoundButton";
import { FetchPermissions } from "../../../../common/requests/Permission";
import { ToastNotSavedCustom } from "../../../../common/util/ToastUtil";
import RolesTable from "../../tables/RolesTable";

function CreateRolePopup({ open, closeMethod, createMethod, groupId }) {
	const { t } = useTranslation();
	const { data } = useQuery([`available-permissions-${groupId}`], {
		queryFn: () => FetchPermissions(groupId),
	});
	const [roleNameInput, setRoleNameInput] = useState("");
	const permissions = data ? data?.data?.availablePermissions : [];
	const [permissionsAdded, setPermissionsAdded] = useState([]);

	const handleCheckboxChange = async (event) => {
		const permissionId = Number(event.target.value);

		if (event.target.checked) {
			setPermissionsAdded([
				...permissionsAdded,
				{
					id: permissionId,
					key: permissions.find(
						(permission) => permission.id === permissionId
					).key,
				},
			]);
		} else {
			setPermissionsAdded(
				permissionsAdded.filter((id) => id.id !== permissionId)
			);
		}
	};
	const isPermissionChecked = (permission) => {
		return permissionsAdded.some(
			(addedPermission) => addedPermission.id === permission.id
		);
	};
	const close = () => {
		setPermissionsAdded([]);
		closeMethod();
	};
	useEffect(() => {
		setRoleNameInput("");
	}, [open]);
	const { hasPermission } = useAuth();
	const { dimensions } = useContext(DimensionsContext);
	return (
		<Popup
			open={open}
			onClose={close}
			contentStyle={{
				borderRadius: "0.75rem",
				padding: "10px",
				height: "auto",
				width: dimensions < 1050 ? "93%" : "1000px",
				position: "relative",
			}}
			className="relative"
		>
			<RoundButtonXMarkAbsolute functionCall={closeMethod} />

			<PopupHeadTitle text={t("create.role.title")} />

			<div className="text-center mt-2 p-1 overflow-auto  h-[550px]">
				<div
					className="overflow-auto"
					style={{
						alignItems: "center",
					}}
				>
					<FloatingLabelInput
						//reference={roleNameInput}
						name={t("create.role.roleName")}
						//onChange={setRoleNameInput}
						onChange={(e) => setRoleNameInput(e.target.value)}
					/>
					<RolesTable
						canEdit={hasPermission(
							Permissions.GROUP_ROLE_EDIT,
							groupId
						)}
						opacity={roleNameInput === ""}
						permissions={permissions}
						handleOnClick={handleCheckboxChange}
						isPermissionChecked={isPermissionChecked}
					/>
				</div>
				<div className="flex justify-end mt-2">
					<CustomButton
						disabled={roleNameInput === ""}
						className="w-40"
						text={t("create")}
						onClick={() => {
							const roleName = roleNameInput;
							if (roleName === "") {
								ToastNotSavedCustom(t("role.input.error"));
							} else {
								createMethod(
									roleName,
									permissionsAdded.map(
										(permission) => permission.id
									)
								);
							}
						}}
					/>
				</div>
			</div>
		</Popup>
	);
}
export default CreateRolePopup;
