import { requestAPI } from "./RequestAPI";
import URL from "./UrlsEnum";

export async function FetchPermissions(groupId) {
	const data = await requestAPI(
		`${URL.PERMISSION.replace("{groupId}", groupId)}`,
		"GET"
	);
	return data;
}
