import classNames from "classnames";
import { useContext, useEffect, useRef, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "reactjs-popup/dist/index.css";
import DashboardContainer from "../../portail/containers/dashboard/DashboardContainer.jsx";
import Errors from "../../portail/views/Errors/Errors";
import GroupDashboard from "../../portail/views/group/GroupDashboard.jsx";
import GroupManagement from "../../portail/views/group/GroupManagement";
import MachineDashboard from "../../portail/views/group/machine/MachineDashboard.jsx";
import MachineSetting from "../../portail/views/group/machine/MachineSetting.jsx";
import MachinesDashboard from "../../portail/views/group/machine/MachinesDashboard";
import Logs from "../../portail/views/logs/Logs.jsx";
import Catalog from "../../store/views/Catalog.jsx";
import Orders from "../../store/views/Orders.jsx";
import Store from "../../store/views/Store.jsx";
import { DimensionsContext } from "../context/DimensionsContext";
import BackgroundWave from "../layouts/Global/BackgroundWave";
import PageHeader from "../layouts/Global/PageHeader";
import PageName from "../layouts/Global/PageName";
import Sidebar from "../layouts/Global/Sidebar";
import Tree from "../layouts/Tree/Tree";
import NotFound from "./NotFound";
import Profile from "./Profile.jsx";

function App() {
	const { setDimensions, setDimensionsHeight, dimensions, dimensionsHeight } =
		useContext(DimensionsContext);
	const [isMobile, setIsMobile] = useState(
		document.documentElement.clientWidth < 1000
	);

	//const [name, setName] = useState("");
	const [navheight, setNavheight] = useState(0);
	const [openModal, setOpenModal] = useState(false);
	const modalRef = useRef(null);
	const barsRef = useRef(null);
	useEffect(() => {
		function handleClickOutside(event) {
			if (barsRef.current && barsRef.current.contains(event.target)) {
				return;
			}
			if (modalRef.current && !modalRef.current.contains(event.target)) {
				setOpenModal(false);
			}
		}

		document.addEventListener("mousedown", handleClickOutside);

		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);
	const handleClickInsideModal = () => {
		if (isMobile) {
			setOpenModal(false);
		}
	};
	useEffect(() => {
		const handleResize = () => {
			setDimensions(document.documentElement.clientWidth);

			setDimensionsHeight(document.documentElement.clientHeight);
			setIsMobile(document.documentElement.clientWidth < 1000);
		};

		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	return (
		<div>
			<BackgroundWave flip={true} isMobile={isMobile} />
			<PageName
				navheight={navheight}
				isMobile={isMobile}
				left={!isMobile ? "21%" : 0.2 * dimensions}
			/>
			{!isMobile && (
				<div
					className={classNames(
						"z-[3] top-0 right-0 items-center mobile:fixed"
					)}
				>
					<PageHeader isMobile={false} />
				</div>
			)}

			<div
				style={{
					height: !isMobile && dimensionsHeight,
				}}
				className={classNames(
					"fixed z-[4] transition-all duration-300 w-full  mobile:w-[21%]"
				)}
			>
				<div
					className={classNames(
						"  rounded-none z-[100] mobile:shadow-sidebar mobile:h-full bg-sideBar"
					)}
				>
					<Sidebar
						setNavheight={setNavheight}
						isMobile={isMobile}
						size={[dimensions, dimensionsHeight]}
						setOpenModal={setOpenModal}
						openModal={openModal}
						handleClickInsideModal={handleClickInsideModal}
						ref={barsRef}
					/>
				</div>
				<div
					ref={modalRef}
					className={classNames(
						" p-[20px] bg-white z-[-1] mt-[-16px] ml-0 w-fit  rounded-b-3xl mx-8 text-lg shadow-[sidebarModel]",
						{
							flex: openModal,
							hidden: !openModal,
						}
					)}
				>
					<Tree
						key={1}
						handleClickInsideModal={handleClickInsideModal}
						openModal={openModal}
					/>
				</div>
			</div>

			<div
				style={{
					minHeight: dimensionsHeight,
				}}
				className={classNames(
					"absolute z-[1] transition-all duration-300 w-full mobile:w-[79%] mobile:right-0 "
				)}
			>
				<div
					className={classNames(
						"p-[10px] mt-[160px] mobile:mt-[80px]"
					)}
				>
					<Routes>
						<Route path="/" element={<Navigate to="/portal" />} />

						<Route
							path="/portal"
							element={<DashboardContainer />}
						/>

						<Route path="portal/:groupId">
							<Route index element={<GroupDashboard />} />

							<Route
								path="management"
								element={<GroupManagement />}
							/>

							<Route
								path="machines"
								element={<MachinesDashboard />}
							/>
							<Route path="machine/:machineId">
								<Route index element={<MachineDashboard />} />
								<Route
									path="setting"
									element={<MachineSetting />}
								/>
							</Route>
						</Route>
						<Route path="store" element={<Store />} />
						<Route path="store/:groupId">
							<Route index element={<Navigate to="catalog" />} />
							<Route path="catalog" element={<Catalog />} />
							<Route path="orders" element={<Orders />} />
						</Route>
						<Route path="/logs" element={<Logs />} />
						<Route path="/errors" element={<Errors />} />

						<Route path="/profil" element={<Profile />} />
						{/* 
						<Route path="/clients" element={<Clients />} />
						<Route path="/testing" element={<Gallery />} /> */}

						<Route path="/404" element={<NotFound />} />

						<Route path="*" element={<NotFound />} />
					</Routes>
				</div>
			</div>
		</div>
	);
}

export default App;
