import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import Popup from "reactjs-popup";
import useImagePath from "../logos/getImagePath";

function NavigationPopup({ open, closeMethod }) {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const choices = [
		{
			name: "store",
			logo: useImagePath("KupaStore"),
		},
		{
			name: "portal",
			logo: useImagePath("KupaPortal"),
		},
	];

	const { pathname } = useLocation();

	const handleChoiceClick = (name) => {
		if (pathname.includes(name)) {
			closeMethod();
		} else {
			navigate(`/${name}`);
			closeMethod();
		}
	};
	return (
		<Popup
			open={open}
			onClose={closeMethod}
			contentStyle={{
				borderRadius: "0.75rem",
				//overflow: "auto",
				padding: "10px",
				maxHeight: "95%",
				width: "90%",
				maxWidth: "400px",
			}}
			className="relative w-2/3"
		>
			<div className="flex flex-col align-middle justify-evenly">
				{choices.map((choice, index) => (
					<React.Fragment key={index}>
						<div className="flex flex-col items-center">
							{pathname.includes(choice.name) && (
								<div className="text-center text-black/80">
									{t("you.here")}
								</div>
							)}
							<img
								alt="logo"
								className="p-4 lg:p-8 hover:bg-kupabluelight/10 hover:cursor-pointer object-contain"
								src={choice.logo}
								onClick={() => handleChoiceClick(choice.name)}
							/>
							{index < choices.length - 1 && (
								<div className="w-full m-3 border-2 border-black/40"></div>
							)}
						</div>
					</React.Fragment>
				))}
			</div>
		</Popup>
	);
}

export default NavigationPopup;
