import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import LoadingSpinner from "../../../common/layouts/Global/LoadingSpinner";
import MachineSettings from "../../views/machines/MachineSettings";

import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../common/context/AuthContext";
import { FetchRecipesAndOptions } from "../../../common/requests/machine/RecipesSupplements";
import {
	ToastNotSaved,
	ToastNotSavedCustom,
	ToastSaved,
	ToastStartSaving,
} from "../../../common/util/ToastUtil";
import {
	FetchMachine,
	FetchMachineSettings,
	SaveMachine,
} from "../../requests/machines/Machine";
import { CheckContainers, CheckValves } from "../../util/CheckSettings";
import { TreeContext } from "../../../common/context/TreeContext";

function MachineSettingsContainer({ location }) {
	const { t } = useTranslation();
	document.title = `KUPA Station | ${t("settings")}`;
	const { setUpdateTree } = useContext(TreeContext);
	const [isLoading, setIsLoading] = useState(true);
	const { machineId } = useParams();

	const [settings, setSettings] = useState([]);
	const [oldsettings, setOldSettings] = useState([]);
	const { data: machine, isLoading: FetchMachineLoading } = useQuery(
		[`Machine-${machineId}`],
		{
			queryFn: () => FetchMachine(machineId),
		}
	);
	useEffect(() => {
		setUpdateTree((prev) => prev + 1);
	}, []);
	const { data: mapping, isLoading: mappingLoading } = useQuery([`mapping`], {
		queryFn: () => FetchRecipesAndOptions(),
	});
	const loadData = async (machineId) => {
		const settings = await FetchMachineSettings(machineId);
		setSettings(settings);
		setOldSettings(settings);
		setIsLoading(false);
	};

	const saveSettings = async () => {
		for (let price of settings.containers) {
			price.price = Number(price.price);
			price.sizeML = Number(price.sizeML);
		}
		let check = CheckContainers(settings.containers);
		if (!check.success) {
			ToastNotSavedCustom(check.info);
			setIsLoading(false);
			return;
		}

		check = CheckValves(settings.valves);
		if (!check.success) {
			ToastNotSavedCustom(check.info);
			setIsLoading(false);
			return;
		}
		ToastStartSaving();

		let success = await SaveMachine({ ...settings, machineId });

		if (success) {
			ToastSaved();
			setIsLoading(true);
			loadData(machineId);
		} else ToastNotSaved();
	};

	useEffect(() => {
		window.scrollTo(0, 0);
		loadData(machineId);
	}, [machineId]);
	useEffect(() => {
		const handleFocus = () => {
			window.scrollTo(0, 0);
			loadData(machineId);
		};

		window.addEventListener("focus", handleFocus);

		return () => {
			window.removeEventListener("focus", handleFocus);
		};
	}, [machineId]);
	const { hasAccessGroup } = useAuth();
	if (!hasAccessGroup(machine?.group_id)) return null;
	if (oldsettings.length === 0) return null;

	return (
		<>
			{isLoading || mappingLoading || FetchMachineLoading ? (
				<LoadingSpinner />
			) : (
				<>
					<MachineSettings
						key={machineId}
						machine={machine}
						settings={settings}
						setSettings={setSettings}
						saveSettings={saveSettings}
						mapping={mapping}
					/>
				</>
			)}
		</>
	);
}

export default MachineSettingsContainer;
