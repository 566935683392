import React, { useEffect, useState } from "react";

const Count = (props) => {
	const { number, duration, shown } = props.data;

	const [count, setCount] = useState("0");

	useEffect(() => {
		const start = 0;
		const end = parseInt(number);

		if (start === end) return;

		const totalTime = duration * 1000;
		const incrementTime = totalTime / end;
		let currentNumber = start;

		const startTime = Date.now();

		const timer = setInterval(() => {
			const elapsedTime = Date.now() - startTime;

			currentNumber = Math.min(
				end,
				Math.round((elapsedTime / totalTime) * end)
			);

			setCount(
				String(currentNumber) +
					number
						?.toString()
						.substring(currentNumber?.toString().length)
			);

			if (currentNumber === end) {
				clearInterval(timer);
			}
		}, incrementTime);

		return () => clearInterval(timer);
	}, [number, duration]);

	return (
		<h2
			className={`${
				shown ? "group-hover:scale-150" : ""
			} mb-3  text-4xl text-kupabluedark font-bold text-center transition-transform duration-300`}
		>
			{count}
		</h2>
	);
};

export default Count;
