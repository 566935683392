import { useQuery } from "@tanstack/react-query";
import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Permissions from "../../../../../common/config/permissions";
import Restricted from "../../../../../common/context/hooks/Restricted";
import LoadingSpinner from "../../../../../common/layouts/Global/LoadingSpinner";
import {
	RoundButtonPen,
	RoundButtonPlus,
	RoundButtonXMark,
} from "../../../../../common/layouts/Global/RoundButton";
import WhiteBlockDiv from "../../../../../common/layouts/Global/WhiteBlockDiv";
import Table from "../../../../../common/layouts/tables/Table";
import {
	ToastAfterLoading,
	ToastLoading,
} from "../../../../../common/util/ToastUtil";
import {
	CreateMachineVideoSchedule,
	DeleteMachineVideoSchedule,
	GetMachineVideoSchedule,
	ModifyMachineVideoSchedule,
} from "../../../../requests/video/machineVideoSchedule";
import CreateMachineVideoSchedulePopup from "../../Popup/CreateMachineVideoSchedulePopup";
import DeletePopup from "../../Popup/DeletePopup";
import ModifyMachineVideoSchedulePopup from "../../Popup/ModifyMachineVideoSchedule";

function MachineVideoSchedule({ groupId }) {
	const { t } = useTranslation();
	const { data, isLoading, refetch } = useQuery(
		[`group-${groupId}-videoScheduleMachine`],
		{
			queryFn: () => GetMachineVideoSchedule(groupId),
		}
	);
	const [createVideoSchedulePopupOpen, setCreateVideoSchedulePopupOpen] =
		useState(false);
	const [deleteVideoSchedulePopupOpen, setDeleteVideoSchedulePopupOpen] =
		useState(false);
	const [modifyVideoSchedulePopupOpen, setModifyVideoSchedulePopupOpen] =
		useState(false);
	const [videoScheduleModify, setVideoScheduleModify] = useState(null);

	const closeCreatePopup = () => {
		setCreateVideoSchedulePopupOpen(false);
	};

	const openCreatePopup = (videoSchedule) => {
		setVideoScheduleModify(videoSchedule);
		setCreateVideoSchedulePopupOpen(true);
	};

	const closeDeletePopup = () => {
		setDeleteVideoSchedulePopupOpen(false);
	};

	const openDeletePopup = (videoSchedule) => {
		setVideoScheduleModify(videoSchedule);
		setDeleteVideoSchedulePopupOpen(true);
	};

	const closeModifyPopup = () => {
		setModifyVideoSchedulePopupOpen(false);
	};

	const openModifyPopup = (videoSchedule) => {
		setVideoScheduleModify(videoSchedule);
		setModifyVideoSchedulePopupOpen(true);
	};

	const createMachineVideoSchedule = async (
		machineId,
		videoModelId,
		start,
		end
	) => {
		closeCreatePopup();
		ToastLoading("createMachineSchedule");
		const data = await CreateMachineVideoSchedule(
			Number(groupId),
			machineId,
			videoModelId,
			start,
			end
		);
		ToastAfterLoading(
			"createMachineSchedule",
			data?.success,
			data?.data?.message
		);
		refetch();
	};

	const deleteMachineVideoSchedule = async () => {
		closeDeletePopup();
		ToastLoading("deleteMachineSchedule");

		const data = await DeleteMachineVideoSchedule(
			groupId,
			videoScheduleModify.id
		);
		ToastAfterLoading(
			"deleteMachineSchedule",
			data?.success,
			data?.data?.message
		);
		refetch();
	};

	const ModifyVideoSchedule = async (
		videoModelId,
		videoScheduleStart,
		videoScheduleEnd
	) => {
		closeModifyPopup();
		ToastLoading("updateMachineSchedule");
		const data = await ModifyMachineVideoSchedule(
			groupId,
			videoScheduleModify?.machine?.machine_name?.machine_id,
			videoScheduleModify.id,
			videoModelId,
			videoScheduleStart,
			videoScheduleEnd
		);
		ToastAfterLoading(
			"updateMachineSchedule",
			data?.success,
			data?.data?.message
		);
		refetch();
	};

	if (isLoading) return <LoadingSpinner />;
	if (!data?.success) return null;
	const columns = [
		t("Id"),
		t("sequence.machine"),
		t("sequence.name"),
		t("date.start"),
		t("date.end"),
		t("actions"),
	];
	const rows = data?.data?.map((videoSchedule) => {
		return [
			videoSchedule?.id,
			videoSchedule?.machine?.machine_name?.private_name,
			videoSchedule?.video_model?.name,

			//get the date and only the hour and minutes
			videoSchedule.start
				? moment(videoSchedule.start).format("YYYY-MM-DD HH:mm")
				: t("discounts.no.date"),

			videoSchedule.end
				? moment(videoSchedule.end).format("YYYY-MM-DD HH:mm")
				: t("discounts.no.date"),
			<Restricted to={Permissions.GROUP_SCHEDULE_EDIT} groupId={groupId}>
				<div className="flex">
					<RoundButtonPen
						functionCall={() => openModifyPopup(videoSchedule)}
					/>

					<RoundButtonXMark
						functionCall={() => openDeletePopup(videoSchedule)}
					/>
				</div>
			</Restricted>,
		];
	});

	return (
		<WhiteBlockDiv
			className="mb-2 sm:mb-0"
			text={
				<>
					{t("machine.video.schedule.title")}{" "}
					<RoundButtonPlus functionCall={openCreatePopup} />
				</>
			}
		>
			<Table columns={columns} rows={rows} />
			<CreateMachineVideoSchedulePopup
				open={createVideoSchedulePopupOpen}
				closeMethod={closeCreatePopup}
				createMethod={createMachineVideoSchedule}
				groupId={groupId}
			/>
			<DeletePopup
				open={deleteVideoSchedulePopupOpen}
				closeMethod={closeDeletePopup}
				deleteMethod={deleteMachineVideoSchedule}
				typeOfDelete={"schedule"}
			></DeletePopup>
			<ModifyMachineVideoSchedulePopup
				open={modifyVideoSchedulePopupOpen}
				closeMethod={closeModifyPopup}
				modifyMethod={ModifyVideoSchedule}
				groupId={groupId}
				currentVideoSchedule={
					videoScheduleModify ? videoScheduleModify : ""
				}
			/>
		</WhiteBlockDiv>
	);
}

export default MachineVideoSchedule;
