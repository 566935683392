import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import React, { useState } from "react";
import FloatingLabelInput from "./InputFloatingLabel";

const FloatingLabelPassowrd = ({
	classNameAll,
	className,
	reference,
	type = "text",
	name,
	placeholder,
	nameInput,
	value,
	defaultValue,
	min,
	max,
	step,
	autoComplete,
	onChange,
	idInput,
	disabled = false,
	inputMode,
	darkmode,
	classNameGlobal,
	classNameInput,
	CompleteAPI = false,
	data,
	visible,
	handleAutoCompleteClick,
	AutoCompleteRef,
	onClick,
	maxLength,
	autoFocus = false,
	onBlur,
}) => {
	const [showPassword, setShowPassword] = useState(false);

	const toggleShowPassword = () => {
		setShowPassword(!showPassword);
	};

	return (
		<div className={classNames(" flex relative", classNameAll)}>
			<FloatingLabelInput
				className={className}
				reference={reference}
				name={name}
				placeholder={placeholder}
				nameInput={nameInput}
				value={value}
				defaultValue={defaultValue}
				autoComplete={autoComplete}
				onChange={onChange}
				idInput={idInput}
				disabled={disabled}
				inputMode={inputMode}
				darkmode={darkmode}
				classNameGlobal={classNameGlobal}
				classNameInput={classNameInput}
				CompleteAPI={CompleteAPI}
				data={data}
				visible={visible}
				handleAutoCompleteClick={handleAutoCompleteClick}
				AutoCompleteRef={AutoCompleteRef}
				onClick={onClick}
				maxLength={maxLength}
				autoFocus={autoFocus}
				onBlur={onBlur}
				type={!showPassword ? "password" : "text"}
			/>

			<div
				onClick={() => toggleShowPassword()}
				className=" absolute z-40 right-2 translate-y-1/2 text-sm  text-thirddark "
			>
				<FontAwesomeIcon
					className="w-5 text-kupablack dark:text-kupawhite cursor-pointer "
					icon={showPassword ? faEye : faEyeSlash}
				/>
			</div>
		</div>
	);
};

export default FloatingLabelPassowrd;
