import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import LoginView from "./LoginView.js";

function LoginForm({ handleLogin, handleChangeForgot }) {
	const { t } = useTranslation();

	const handleSubmit = (email, password) => {
		if (email === "" || password === "") {
			toast.error(t("fill.in.all.fields"));
		} else {
			handleLogin(email, password);
		}
	};

	return (
		<LoginView
			handleSubmit={handleSubmit}
			handleChangeForgot={handleChangeForgot}
		/>
	);
}

export default LoginForm;
