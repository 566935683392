import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ForgotPasswordForm from "../../common/layouts/ForgotPassword/ForgotPasswordForm";
import LoginForm from "../../common/layouts/Login/LoginForm";
import { useAuth } from "../context/AuthContext";
import WhiteBlockDiv from "../layouts/Global/WhiteBlockDiv";
import Logo from "../layouts/logos/Logo";
import LogoLogin from "../layouts/logos/LogoLogin";

function LoginContainer() {
	useEffect(() => {
		document.title = "KUPA Station | Login";
	}, []);
	const { login, sendEmailPasswordReset } = useAuth();
	const { t } = useTranslation();
	const [forgotPassword, setForgotPassword] = useState(false);
	const navigate = useNavigate();
	const [isMobile, setIsMobile] = useState(
		document.documentElement.clientWidth < 1000
	);
	const handleLogin = async (email, password) => {
		try {
			await login(email, password);

			navigate("/");
			toast.success(t("login.connectSuccess"));
		} catch (error) {
			toast.error(t("login.inputError"));
		}
	};
	const handleForgotPassowrd = async (email) => {
		try {
			await sendEmailPasswordReset(email);
			toast.success(t("reset.emailSent"));
			setForgotPassword(false);
		} catch (error) {
			toast.error(t("reset.inputError"));
		}
	};

	return (
		<div className="w-screen h-screen mix-blend-normal">
			<div className="w-screen h-full relative  z-10">
				<div className="lg:grid lg:grid-cols-2 h-screen">
					<div className="flex items-center h-1/6 lg:h-2/3 p-2  justify-center">
						<LogoLogin
							customWidthLogin={isMobile ? "250px" : "250px"}
							textsize="md:text-5xl"
							login={true}
						/>
					</div>
					<div className="flex justify-center lg:items-center h-fit lg:h-full m-3 lg:m-0 ">
						<WhiteBlockDiv className="h-fit">
							<div className="flex flex-col justify-center items-center p-5 md:p-10 lg:px-20">
								{forgotPassword ? (
									<ForgotPasswordForm
										handleForgotPassowrd={
											handleForgotPassowrd
										}
										handleChangeForgot={() =>
											setForgotPassword((prev) => !prev)
										}
									/>
								) : (
									<LoginForm
										handleLogin={handleLogin}
										handleChangeForgot={() =>
											setForgotPassword((prev) => !prev)
										}
									/>
								)}
							</div>
						</WhiteBlockDiv>
					</div>
				</div>
			</div>
		</div>
	);
}

export default LoginContainer;
