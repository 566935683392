import { createRef, useState, useEffect } from "react";
import Arrow from "./Arrow/Arrow";
function ScrollBarArrow({ children, checkArrow, border }) {
	const [scrollPosition, setScrollPosition] = useState(0);
	const scrollContainerRef = createRef();
	const [hasOverflowY, setHasOverflowY] = useState(false);
	const handleScrollLeft = () => {
		setScrollPosition((prevScrollPosition) => prevScrollPosition - 300);
		scrollContainerRef.current.scrollBy({
			left: -300,
			behavior: "smooth",
		});
	};

	const handleScrollRight = () => {
		setScrollPosition((prevScrollPosition) => prevScrollPosition + 300);
		scrollContainerRef.current.scrollBy({ left: 300, behavior: "smooth" });
	};

	useEffect(() => {
		const divElement = scrollContainerRef.current;

		if (!divElement) return;

		const hasOverflow = divElement.scrollWidth > divElement.clientWidth;

		setHasOverflowY(hasOverflow);
	}, [checkArrow, scrollContainerRef]);

	const styleScrollBar = {
		flexDirection: "row",
		display: "flex",
		alignItems: "center",
		margin: "5px",
		marginTop: 0,
		width: "100%",
		padding: "20px",
		paddingTop: "5px",
		overflow: "auto",
	};

	return (
		<div
			style={styleScrollBar}
			className={`${
				border
					? "drop-shadow-[0_5px_5px_rgba(0,0,0,0.1)] bg-white "
					: ""
			} rounded-3xl `}
		>
			{hasOverflowY && (
				<Arrow
					color={"#00acd3"}
					size={"3x"}
					orientation={"left"}
					action={handleScrollLeft}
				/>
			)}

			<div
				className="w-full overflow-x-auto whitespace-nowrap flex flex-row"
				ref={scrollContainerRef}
			>
				{children}
			</div>

			{hasOverflowY && (
				<Arrow
					color={"#00acd3"}
					size={"3x"}
					orientation={"right"}
					action={handleScrollRight}
				/>
			)}
		</div>
	);
}

export default ScrollBarArrow;
