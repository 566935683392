import FloatingLabelPassowrd from "./PasswordFloatingLabel";

export default function InputPassword({
	name,
	placeholder,
	value,
	onChange,
	autoComplete,
	darkmode,
	classNameGlobal,
	classNameInput,
}) {
	return (
		<FloatingLabelPassowrd
			className={classNameGlobal}
			classNameInput={classNameInput}
			name={name}
			placeholder={placeholder}
			value={value}
			onChange={onChange}
			autoComplete={autoComplete}
			darkmode={darkmode}
		/>
	);
}
