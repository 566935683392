import moment from "moment";
import { useTranslation } from "react-i18next";

const MOMENT_FORMAT = "YYYY-MM-DD";

function FilterState({ filter, currentFilterData }) {
	const { t } = useTranslation();

	const getValue = () => {
		const { type } = filter;

		switch (type) {
			case "date":
				if (!currentFilterData.firstValue) return;

				switch (currentFilterData.operator) {
					case "between":
						if (!currentFilterData.secondValue) return;

						return t("filter.date.to", {
							1: moment(currentFilterData.firstValue).format(
								MOMENT_FORMAT
							),
							2: moment(currentFilterData.secondValue).format(
								MOMENT_FORMAT
							),
						});

					case "=":
						return moment(currentFilterData.firstValue).format(
							MOMENT_FORMAT
						);

					case ">":
						return t("filter.after", {
							1: moment(currentFilterData.firstValue).format(
								MOMENT_FORMAT
							),
						});

					case "<":
						return t("filter.before", {
							1: moment(currentFilterData.firstValue).format(
								MOMENT_FORMAT
							),
						});

					case "in_the_last":
						return t("filter.date.last", {
							1: currentFilterData.firstValue,
							2: t(currentFilterData.secondValue),
						});

					default:
						break;
				}
				break;

			case "number":
				if (!currentFilterData.firstValue) return;

				switch (currentFilterData.operator) {
					case "between":
						if (!currentFilterData.secondValue) return;

						return t("filter.number.to", {
							1: currentFilterData.firstValue,
							2: currentFilterData.secondValue,
						});

					case "=":
						return t("filter.equal", {
							1: currentFilterData.firstValue,
						});
					case ">":
						return t("filter.greater", {
							1: currentFilterData.firstValue,
						});
					case "<":
						return t("filter.less", {
							1: currentFilterData.firstValue,
						});
					default:
						break;
				}
				break;

			case "string":
				if (!currentFilterData.value) return;

				switch (currentFilterData.operator) {
					case "contains":
						return t("filter.contains", {
							1: currentFilterData.value,
						});
					case "equals":
						return currentFilterData.value;
					default:
						break;
				}
				break;

			case "dropdown":
				return t(currentFilterData.value);

			case "checkbox":
				const customFilter = currentFilterData?.custom?.value;

				const values = customFilter
					? [t(customFilter)]
					: currentFilterData?.value?.map((value) =>
							t(filter.values[value])
					  ) || [];

				return (
					values.slice(0, 2).join(", ") +
					(values.length > 2
						? t("filter.xmore", {
								1: values.length - 2,
						  })
						: "")
				);
			default:
				break;
		}
	};

	const value = getValue();

	if (!value) return null;

	return (
		<span>
			{" "}
			| <span className="text-kupablue font-semibold">{value}</span>
		</span>
	);
}

export default FilterState;
