import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import Permissions from "../../../common/config/permissions";
import { useAuth } from "../../../common/context/AuthContext";
import { FetchGroupDashboard } from "../../../portail/requests/Group";
import { FetchMachine } from "../../../portail/requests/machines/Machine";
const parsePathArgs = (path) => {
	const allArgs = path.split("/");
	const portalPart = allArgs[1];
	const potentialGroupId = parseInt(allArgs[2]);
	const pathName = allArgs[3];
	const potentialMachineId = parseInt(allArgs[4]);
	return { portalPart, potentialGroupId, pathName, potentialMachineId };
};
function PageName({ navheight, isMobile, left }) {
	const location = useLocation();
	const [topTitle, setTopTitle] = useState(" ");
	const [name, setName] = useState(null);

	const [machineId, setMachineId] = useState(null);
	const [groupId, setGroupId] = useState(null);
	const [machineTitle, setMachineTitle] = useState(null);

	const machineQuery = useQuery([`Machine-${machineId}`], {
		queryFn: () => FetchMachine(machineId),
		enabled: !!machineId,
	});

	const groupQuery = useQuery([`group-${groupId}`], {
		queryFn: () => FetchGroupDashboard(groupId),
		enabled: !!groupId,
	});

	useEffect(() => {
		const { portalPart, potentialGroupId, pathName, potentialMachineId } =
			parsePathArgs(location.pathname);
		let machineId = null;
		let machineTitle = null;

		if (pathName === "machine") {
			machineId = potentialMachineId;
		}

		let name = "dashboard";
		const pathMappings = {
			store: {
				catalog: "catalog",
				orders: "orders",
			},
			portal: {
				"": "dashboard",
				management: "management",
				machines: "stations",
				machine: location.pathname.includes("setting")
					? "settings"
					: "dashboard",
			},

			profil: "profil",
		};

		if (portalPart === "store") {
			if (pathName in pathMappings.store) {
				name = pathMappings.store[pathName];
			}
		} else if (portalPart === "portal") {
			if (pathName in pathMappings.portal) {
				name = pathMappings.portal[pathName];
			}
		} else if (portalPart === "profil") {
			name = pathMappings.profil;
		}
		setMachineId(machineId);
		setMachineTitle(machineTitle);
		setName(name);
		setGroupId(potentialGroupId);
	}, [location]);

	useEffect(() => {
		if (groupQuery.data && !groupQuery.isLoading) {
			setTopTitle(groupQuery.data?.data?.name);
		}
	}, [groupQuery]);
	const { hasPermission, hasGlobalPermission } = useAuth();

	useEffect(() => {
		const hasAnyGlobalPermission = (...permissions) => {
			return permissions.some((permission) =>
				hasGlobalPermission(permission)
			);
		};

		const hasAnyLocalPermission = (groupId, ...permissions) => {
			return permissions.some((permission) =>
				hasPermission(permission, groupId)
			);
		};
		if (!machineId) {
			setMachineTitle(null);
			return;
		}

		if (machineQuery.data && !machineQuery.isLoading) {
			if (
				hasAnyGlobalPermission(
					Permissions.MACHINE_STATS_PRODUCTLEVEL_VIEW,
					Permissions.MACHINE_STATS_TOPOPTION_VIEW,
					Permissions.MACHINE_STATS_TOPRECIPE_VIEW
				)
			) {
				setMachineTitle(machineQuery?.data?.factory_name);
			} else if (
				hasAnyLocalPermission(
					groupId,
					Permissions.MACHINE_STATS_PRODUCTLEVEL_VIEW,
					Permissions.MACHINE_STATS_TOPRECIPE_VIEW,
					Permissions.MACHINE_STATS_TOPOPTION_VIEW
				)
			) {
				setMachineTitle(machineQuery?.data?.private_name);
			} else {
				setMachineTitle("Station");
			}
		}
	}, [machineQuery, machineId, groupId]);
	const { t } = useTranslation();
	return (
		<div
			style={{
				position: "fixed",
				padding: isMobile ? "10px" : "10px",
				paddingLeft: isMobile ? "15px" : "10px",
				height: isMobile ? "180px" : "80px",
				width: !isMobile ? "20%" : "100%",
				display: "flex",
				left: !isMobile && left,
				zIndex: "2",
				borderRadius: "5px",
				justifyContent: "flex-start",
				//backgroundColor: "#00aed3",
			}}
			className="transition-all duration-300"
		>
			<div
				style={{
					flex: 1,
					color: "white",
					marginTop: isMobile ? navheight : "0px",
					display: "flex",
					flexDirection: "row",
					position: "fixed",
					alignItems: "center",
				}}
			>
				<div
					style={{
						display: "flex",
						flexDirection: "column",
					}}
				>
					<span style={{ fontSize: "16px" }}>
						{topTitle}
						{machineTitle && ` > ${machineTitle} `}
					</span>
					<span className="font-bold text-2xl">{t(name)}</span>
				</div>
			</div>
		</div>
	);
}
export default PageName;
