import { requestAPI } from "../../../common/requests/RequestAPI";
import URL from "../../../common/requests/UrlsEnum";

export async function FetchMachineStats(machineId, dates) {
	try {
		const machine = await requestAPI(
			URL.MACHINE_STATS.replace("{id}", machineId),
			"POST",
			{
				startDate: dates.startDateAPI,
				endDate: dates.endDateAPI,
			}
		);

		if (!machine) {
			throw new Error("No machine data found.");
		}

		return machine;
	} catch (error) {
		return null;
	}
}
