import classNames from "classnames";
import FloatingLabelInput from "./InputFloatingLabel";

function CustomDateInput({
	classNameInput,
	classNameGlobal,
	value,
	onChange,
	max,
}) {
	const today = new Date();
	const maxDate = `${today.getFullYear()}-${String(
		today.getMonth() + 1
	).padStart(2, "0")}-${String(today.getDate()).padStart(2, "0")}`;

	return (
		<div className={classNames(classNameGlobal)}>
			<FloatingLabelInput
				type="date"
				value={value || ""}
				max={maxDate}
				onChange={onChange}
				className={classNames("px-2 py-1 w-auto", classNameInput)}
			/>
		</div>
	);
}

export default CustomDateInput;
