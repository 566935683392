import { useState } from "react";
import { useTranslation } from "react-i18next";

import { useNavigate } from "react-router-dom";
import FloatingLabelInput from "../Global/InputFloatingLabel";
import InputPassword from "../Global/InputPassword";
import ProvidersLogin from "./ProvidersLogin";

function LoginView({ handleSubmit, handleChangeForgot }) {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");

	const { t } = useTranslation();
	const navigate = useNavigate();
	const handleEmail = (e) => {
		setEmail(e.target.value);
	};

	const handlePassword = (e) => {
		setPassword(e.target.value);
	};

	const handleFormSubmit = (e) => {
		e.preventDefault();
		handleSubmit(email, password);
	};

	return (
		<form className="flex flex-col align-middle justify-center w-full">
			<h1 className="text-kupablack text-lg uppercase flex p-1 mb-8 justify-center align-middle text-center">
				{t("welcome")}
			</h1>

			<FloatingLabelInput
				darkmode={false}
				className={"mb-2  w-full"}
				classNameInput=" p-2"
				name={t("email")}
				onChange={handleEmail}
				value={email}
				type="email"
				autoComplete="email"
			/>

			<InputPassword
				darkmode={false}
				classNameAll="w-60"
				classNameGlobal={
					"mb-1 flex w-full align-middle text-center justify-center"
				}
				name={t("auth.password")}
				onChange={handlePassword}
				value={password}
				autoComplete="current-password"
			/>
			<div
				onClick={handleChangeForgot}
				className="text-right text-sm whitespace-nowrap  rounded-full  font-medium 
				text-md  ease-in duration-200 transition-colors cursor-pointer"
			>
				{t("auth.password.forgot")}
			</div>
			<button
				onClick={handleFormSubmit}
				type="submit"
				id="submit"
				className="whitespace-nowrap mt-3 bg-kupablue rounded-full p-2 text-kupawhite font-medium 
						text-md hover:bg-kupabluedark ease-in duration-200 transition-colors"
			>
				{t("auth.login")}
			</button>
			<div
				className="text-center text-sm whitespace-nowrap mt-2 rounded-full  font-medium 
				text-md  ease-in duration-200 transition-colors "
			>
				{t("auth.register_instead")}
				<p
					className="cursor-pointer text-kupablue"
					onClick={() => {
						navigate("/signup");
					}}
				>
					{t("signup")}!
				</p>
			</div>

			<div className="border-t-2 border-slate-300 mt-5 mb-2" />
			<ProvidersLogin />
		</form>
	);
}

export default LoginView;
