import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Table from "../../../common/layouts/tables/Table";

function RolesTable({
	permissions,
	handleOnClick,
	isPermissionChecked,
	canEdit,
	opacity = false,
}) {
	const { t } = useTranslation();
	const inputArray = permissions.map((ele) => ele?.key?.split("_"));
	const transformListToDict = (inputList) => {
		const resultDict = {};
		inputList.forEach((entry) => {
			const key = entry[0];
			if (!resultDict[key]) {
				resultDict[key] = [];
			}
			resultDict[key].push(entry.slice(1));
		});
		return resultDict;
	};

	const consolidateData = (data) => {
		const result = {};
		for (const mainKey in data) {
			result[mainKey] = {};
			data[mainKey].forEach((subList) => {
				const targetKey =
					subList.length === 1
						? mainKey
						: subList.slice(0, -1).join("_");
				if (!result[mainKey][targetKey]) {
					result[mainKey][targetKey] = [];
				}
				result[mainKey][targetKey].push(subList[subList.length - 1]);
			});
		}
		return result;
	};

	const inputData = transformListToDict(inputArray);
	const consolidatedResult = useMemo(
		() => consolidateData(inputData),
		[inputData]
	);
	const consolidatedKeys = useMemo(
		() => Object.keys(consolidatedResult),
		[consolidatedResult]
	);
	const [displayed, setDisplayed] = useState(consolidatedKeys);
	const ToggleArrowFunction = (permissionGroup) => {
		if (displayed.includes(permissionGroup)) {
			setDisplayed(
				displayed.filter((display) => display !== permissionGroup)
			);
			return;
		}
		setDisplayed([...displayed, permissionGroup]);
	};
	return (
		<div
			className={classNames({
				"opacity-10": opacity,
			})}
		>
			{consolidatedKeys.map((permissionGroup) => {
				const actions = [
					...new Set(
						Object.values(
							consolidatedResult[permissionGroup]
						).flat()
					),
				].sort();
				const columns = [
					t("permission"),
					...actions.map((a) => (
						<div className="mx-auto text-center">
							{t(a?.toLowerCase())}
						</div>
					)),
					<div>{t("description")}</div>,
				];
				const rows = Object.keys(
					consolidatedResult[permissionGroup]
				).map((permission) => {
					return [
						<div className="text-left uppercase">
							{t(
								[
									...new Set([permissionGroup, permission]),
								].join("_") + "_NAME"
							)}
						</div>,
						...actions.map((action) => {
							const Key = [
								...new Set([
									permissionGroup,
									permission,
									action,
								]),
							].join("_");

							const permissionObj = permissions.find(
								(p) => p.key === Key
							);
							const permissionId = permissionObj?.id;
							let inputProps = {
								type: "checkbox",
								value: permissionId,
								onChange: canEdit ? handleOnClick : undefined,
							};
							if (isPermissionChecked) {
								inputProps.checked = isPermissionChecked({
									id: permissionId,
									key: Key,
								});
							}
							return consolidatedResult[permissionGroup][
								permission
							].includes(action) ? (
								<input {...inputProps} />
							) : (
								"-"
							);
						}),
						<div className="text-left lowercase first-letter:uppercase">
							{consolidatedResult[permissionGroup][permission]
								.map((el) => t(el?.toLowerCase()))
								.join(" / ")}{" "}
							{t(
								[
									...new Set([permissionGroup, permission]),
								].join("_")
							)}
						</div>,
					];
				});

				return (
					<div key={permissionGroup} className="block w-full">
						<div
							className="w-full my-1 text-left font-bold text-xs sm:text-xs uppercase "
							onClick={() => ToggleArrowFunction(permissionGroup)}
						>
							<FontAwesomeIcon
								className={`duration-300 text-kupablue ${
									displayed.includes(permissionGroup)
										? "rotate-0"
										: "-rotate-90"
								}`}
								icon={faAngleDown}
							/>{" "}
							{t(permissionGroup)}{" "}
						</div>
						{displayed.includes(permissionGroup) && (
							<div className="w-full">
								<Table columns={columns} rows={rows} />
							</div>
						)}
					</div>
				);
			})}
		</div>
	);
}

export default RolesTable;
