import LoadingSpinner from "../../../common/layouts/Global/LoadingSpinner";

import { useQuery } from "@tanstack/react-query";
import { FetchTree } from "../../../common/requests/tree/Tree";
import Dashboard from "../../views/dashboard/Dashboard";

function DashboardContainer() {
	const { data, isLoading } = useQuery([`tree`], {
		queryFn: () => FetchTree(),
		refetchInterval: 1000 * 30,
	});

	return <>{isLoading ? <LoadingSpinner /> : <Dashboard data={data} />}</>;
}

export default DashboardContainer;
