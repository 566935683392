import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Lost from "../assets/Lost.png";
import { useTranslation } from "react-i18next";

function NotFound() {
	let navigate = useNavigate();
	const { t } = useTranslation();

	useEffect(() => {
		document.title = "KUPA Station | 404";
	}, []);

	return (
		<div className="flex h-full">
			<div className="m-auto text-center justify-center">
				<img src={Lost} alt="lost" />
				<h1 className="text-2xl lg:text-3xl font-semibold mb-5">
					{t("404.message")}
				</h1>
				<button
					className="bg-kupablue hover:bg-kupabluedark text-kupawhite font-semibold py-2 px-4 rounded-full"
					onClick={() => navigate("/")}
				>
					{t("404.button")}
				</button>
			</div>
		</div>
	);
}
export default NotFound;
