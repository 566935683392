import {
	faArrowRightFromBracket,
	faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useAuth } from "../../../common/context/AuthContext";
import Darkmode from "./Darkmode";
import LanguageSwitcherNew from "./LanguageSwitcherNew";
import WhiteBlock from "./WhiteBlock";

export default function PageHeader({ isMobile }) {
	const navigate = useNavigate();
	const { logout, firebaseUser } = useAuth();

	const { t } = useTranslation();

	const menuItems = [
		{
			name: t("profil"),
			icon: faUser,
			link: "/profil",
			onClick: () => navigate("/profil"),
		},
		{
			language: true,
		},
		{
			name: t("auth.logout"),
			icon: faArrowRightFromBracket,
			onClick: () => {
				toast(t("auth.logout.progress"), {
					type: "info",
					autoClose: 400,
					hideProgressBar: false,
				});

				setTimeout(() => {
					logout();
					navigate("/login");
				}, 750);
			},
		},
	];
	const [open, setOpen] = useState(false);
	const togglePopup = (e) => {
		e.preventDefault();
		setOpen((prev) => !prev);
	};
	const dropdownRef = useRef(null);

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (
				open &&
				dropdownRef.current &&
				!dropdownRef.current.contains(event.target)
			) {
				setOpen(false);
			}
		};
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [open]);
	const [CheckPhoto, setCheckPhoto] = useState(null);
	useEffect(() => {
		const testFunction = async () => {
			const data = fetch(firebaseUser?.photoURL)
				.then(setCheckPhoto(firebaseUser?.photoURL))
				.catch(setCheckPhoto(null));
		};
		testFunction();
	}, []);
	return (
		<div ref={dropdownRef} className="mobile:p-[10px]">
			<div
				onClick={(e) => togglePopup(e)}
				style={{ userSelect: "none" }}
				className="flex w-12 h-12 justify-center items-center text-white bg-kupablue hover:opacity-50 cursor-pointer rounded-full border-2 border-white"
			>
				{CheckPhoto ? (
					<img
						className="rounded-full"
						src={firebaseUser?.photoURL}
						alt="profil"
					/>
				) : (
					<FontAwesomeIcon icon={faUser} className="text-2xl" />
				)}
			</div>
			{open && (
				<WhiteBlock
					className={
						" absolute px-2  z-20 h-28 mt-1 w-32 -translate-x-2/3"
					}
					classNameChild={"pb-2 h-full"}
				>
					<div className=" w-full py-2 justify-center  text-black ">
						<ul className=" ">
							{menuItems.map((item, index) => {
								if (!item.hideOnMobile) {
									if (item.language)
										return (
											<div
												key={index}
												className="border-b-2 border-secondary "
											>
												<LanguageSwitcherNew></LanguageSwitcherNew>
											</div>
										);
									else {
										return (
											<li key={index}>
												<button
													onClick={item.onClick}
													className={`flex border-white items-left mr-auto p-2  w-full font-semibold text-black ${" hover:bg-kupabluee hover:text-kupawhite"}`}
												>
													<FontAwesomeIcon
														icon={item.icon}
														className=" text-xs"
													/>
													<span className="ml-3 text-xs xl:block ">
														{item.name}
													</span>
												</button>
											</li>
										);
									}
								} else {
									return null;
								}
							})}

							{
								<>
									<li className="flex justify-center">
										<Darkmode />
									</li>

									<li className="flex justify-center"></li>
								</>
							}
						</ul>
					</div>
				</WhiteBlock>
			)}
		</div>
	);
}
