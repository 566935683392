import moment from "moment";
import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../context/AuthContext";

function MachineTooltip({ ref, machine, handleClickOutside }) {
	const { t } = useTranslation();
	const { staff } = useAuth();
	const tooltipRef = useRef(null);

	useEffect(() => {
		function handleClick(event) {
			if (
				tooltipRef.current &&
				!tooltipRef.current.contains(event.target)
			) {
				handleClickOutside(event);
			}
		}

		document.addEventListener("mousedown", handleClick);

		return () => {
			document.removeEventListener("mousedown", handleClick);
		};
	}, [handleClickOutside]);

	return (
		<div
			ref={tooltipRef}
			className={`absolute z-10 text-sm min-w-fit w-full  marker:overflow-y-auto   px-2 py-1    bg-glass  dark:border-primarydark  rounded-lg drop-shadow-kupashadow`}
		>
			<div className="text-center font-bold">{machine.address}</div>
			{staff() && (
				<>
					<div className="text-center font-bold">
						{machine.serial_number}
					</div>
					<div>
						<div className="flex text-sm  border-secondarydark border-opacity-25">
							<div
								className={` first-letter:uppercase ${
									machine.isOnline
										? "text-green-500  font-semibold"
										: "text-red-500 font-bold"
								}`}
							>
								{t("machine.alive")}:&nbsp;
							</div>
							{moment(machine.machine_keepalive).format(
								"YYYY-MM-DD- HH:mm:ss"
							)}
						</div>
						<div>
							<div className="flex">
								<div
									className={` first-letter:uppercase text-sm ${
										machine.isVideoOnline
											? "text-green-500 font-bold"
											: "text-red-500 font-bold"
									}`}
								>
									{t("machine.video.alive")}:&nbsp;
								</div>
								{moment(machine.video_keepalive).format(
									"YYYY-MM-DD HH:mm:ss"
								)}
							</div>
						</div>
					</div>
				</>
			)}
		</div>
	);
}

export default MachineTooltip;
