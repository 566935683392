import classNames from "classnames";
import React, { useRef, useState } from "react";

function TooltipNew({ text, children, className }) {
	const [showTooltip, setShowTooltip] = useState(false);
	const timeoutRef = useRef(null);

	const handleMouseEnter = () => {
		timeoutRef.current = setTimeout(() => {
			setShowTooltip(true);
		}, 500);
	};

	const handleMouseLeave = () => {
		clearTimeout(timeoutRef.current);
		setShowTooltip(false);
	};

	return (
		<div className={classNames("relative", className)}>
			<div
				className={classNames("w-full", className)}
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave}
			>
				{children}
			</div>
			{showTooltip && (
				<div className="absolute bottom-full left-1/2 transform -translate-x-1/2 first-letter:uppercase z-[100] px-2 py-2 text-sm text-black bg-white text-left w-fit p-3 shadow-md whitespace-nowrap">
					{text}
				</div>
			)}
		</div>
	);
}

export default TooltipNew;
