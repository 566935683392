import { useTranslation } from "react-i18next";

import FloatingLabelInput from "./InputFloatingLabel";

function SearchBar({ onChange, className }) {
	const { t } = useTranslation();

	return (
		<div className={className}>
			<FloatingLabelInput
				name={t("searchbar")}
				onChange={onChange}
				classNameInput={"ring-kupablue/30"}
			/>
		</div>
	);
}

export default SearchBar;
