import { useTranslation } from "react-i18next";
import WhiteBlockDiv from "../../../common/layouts/Global/WhiteBlockDiv";
import Table from "../../../common/layouts/tables/Table";

function TopMachines({ stats }) {
	const { t } = useTranslation();

	const columns = [
		"Station",
		<div className="text-right">Nombre de breuvages</div>,
	];

	const rows = stats?.map((machine) => [
		<p className="py-1 flex">{machine.private_name}</p>,
		<p className=" text-right">{machine.order_count.toLocaleString()} </p>,
	]);

	return (
		<WhiteBlockDiv text={t("station.usage")} className={"mb-2 lg:mb-0"}>
			<div className="uppercase max-h-[260px] min-h-[220px] w-full">
				<Table columns={columns} rows={rows} />
			</div>
		</WhiteBlockDiv>
	);
}

export default TopMachines;
