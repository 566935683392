import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { forwardRef, useState } from "react";

import classNames from "classnames";
import Tooltip from "./Tooltip";

const CustomButton = forwardRef(
	(
		{
			type,
			color,
			hoverColor,
			text,
			icon,
			onClick,
			rounded,
			tooltipText,
			className,
			disabled = false,
			excel = false,
			numberOfNotification = false,
			rightIcon,
		},
		ref
	) => {
		const [visible, setVisible] = useState(false);
		return (
			<>
				<button
					ref={ref}
					className={classNames(
						"text-xs px-3 py-[6px] flex justify-center items-center ease-in duration-200 transition-colors",
						`${color} hover:${hoverColor}`,
						{
							"rounded-full ": rounded,
							"rounded-none": !rounded,
							" bg-secondary hover:bg-secondary/50 hover:shadow-md":
								type === "cancel" && disabled === false,
							"bg-kupared hover:bg-kuparedhover text-white":
								type === "delete" && disabled === false,
							"bg-kupablue/80 hover:bg-kupablue/50 text-white hover:shadow-md":
								type !== "cancel" &&
								type !== "delete" &&
								disabled === false,
							" bg-secondary  text-white": disabled === true,
						},
						className
					)}
					disabled={disabled}
					onClick={onClick}
					onMouseEnter={() => setVisible(true)}
					onMouseLeave={() => setVisible(false)}
				>
					{icon && (
						<FontAwesomeIcon
							className={classNames({
								"mr-3": text,
							})}
							icon={icon}
						/>
					)}
					{numberOfNotification && (
						<span className="absolute text-xs animate-test bg-red-600 top-0 right-0 rounded-full px-1 py-1  leading-none">
							1
						</span>
					)}

					{text}
					{rightIcon && (
						<FontAwesomeIcon
							className={classNames({
								"ml-3": text,
							})}
							icon={rightIcon}
						/>
					)}
				</button>

				{tooltipText && (
					<Tooltip
						text={tooltipText}
						visible={visible}
						excel={excel}
					/>
				)}
			</>
		);
	}
);

CustomButton.defaultProps = {
	rounded: true,
};

export default CustomButton;
