import CustomSelectOption from "../../Global/CustomSelectOption";

function FilterTypeDropdown({
	values,
	currentEditingFilterData,
	handleChange,
}) {
	return (
		<div>
			<CustomSelectOption
				value={currentEditingFilterData.value || ""}
				onChange={(e) =>
					handleChange({
						...currentEditingFilterData,
						value: e.target.value,
					})
				}
				values={["", ...values]}
			/>
		</div>
	);
}

export default FilterTypeDropdown;
