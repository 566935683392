import {
	ArcElement,
	BarElement,
	CategoryScale,
	Chart as ChartJS,
	Legend,
	LineElement,
	LinearScale,
	PointElement,
	Title,
	Tooltip,
} from "chart.js";
import { useTranslation } from "react-i18next";
import Permissions from "../../../common/config/permissions";
import Restricted from "../../../common/context/hooks/Restricted";
import WhiteBlockDiv from "../../../common/layouts/Global/WhiteBlockDiv";
import ProductsLevel from "../../layouts/Machine/ProductsLevel";
import StatsByDate from "../../layouts/Machine/Stats/StatsByDate";
import Boissons from "../../layouts/Stats/Boissons";
import OptionsGraph from "../../layouts/Stats/OptionsGraph";
import OrderTable from "../../layouts/tables/OrderTable";

function MachineStats({ groupId, machineId, data, reload }) {
	const { t } = useTranslation();
	ChartJS.register(
		CategoryScale,
		LinearScale,
		BarElement,
		ArcElement,
		PointElement,
		LineElement,
		Title,
		Tooltip,
		Legend
	);
	if (!data) return null;
	const listStats = [
		{
			id: 1,
			title: t("day", { count: 1 }),
			data: data.oneDay,
			duration: 1,
		},
		{
			id: 2,
			title: t("week", { count: 1 }),
			data: data.oneWeek,
			duration: 1,
		},
		{
			id: 3,
			title: t("month", { count: 1 }),
			data: data.oneMonth,
			duration: 1,
		},
		{
			id: 4,
			title: t("month", { count: 3 }),
			data: data.threeMonths,
			duration: 1,
		},
	];

	return (
		<div>
			<div
				className={`m-2 mobile:m-0 space-y-2 lg:space-y-0 gap-2 lg:grid lg:grid-cols-2 ${
					reload ? "blur-[3px]" : null
				}`}
			>
				<Restricted
					to={Permissions.MACHINE_STATS_PRODUCTLEVEL_VIEW}
					groupId={groupId}
				>
					<ProductsLevel product_level={data.productLevel} />
				</Restricted>

				<WhiteBlockDiv
					text={t("stats")}
					className={"flex flex-col flex-none "}
					classNameChild={" m-2 my-auto lg:my-auto xl:my-auto"}
				>
					<div className="my-auto  flex flex-wrap justify-center  gap-2">
						{listStats.map((element, index) => (
							<div key={index} className="flex-1 w-1/3">
								<StatsByDate
									key={element.id}
									title={element.title}
									data={element?.data?.toLocaleString()}
									duration={element.duration}
								/>
							</div>
						))}

						<div className="flex-1 w-1/3  ">
							<StatsByDate
								title={t("all.orders")}
								data={data?.allOrders?.toLocaleString()}
								duration={"1"}
							/>
						</div>
					</div>
				</WhiteBlockDiv>

				<Restricted
					to={Permissions.MACHINE_STATS_TOPRECIPE_VIEW}
					groupId={groupId}
				>
					<Boissons
						stats={data.soldFlavors}
						text={t("drinks.served")}
					/>
				</Restricted>

				<Restricted
					to={Permissions.MACHINE_STATS_TOPOPTION_VIEW}
					groupId={groupId}
				>
					<OptionsGraph
						options={data.soldOptions}
						text={t("options.served")}
					/>
				</Restricted>
			</div>
			<Restricted
				to={Permissions.GROUP_STATS_ORDER_VIEW}
				groupId={groupId}
			>
				<div className="mt-[8px]  gap-2 lg:grid lg:grid-cols-1">
					<OrderTable
						groupId={groupId}
						machineId={parseInt(machineId)}
					/>
				</div>
			</Restricted>
		</div>
	);
}

export default MachineStats;
