import { useAuth } from "../AuthContext";

export const usePermission = (permission, groupId) => {
	const { hasPermission, hasGlobalPermission } = useAuth();

	if (Array.isArray(permission)) {
		return permission.every((permission) => {
			return groupId
				? hasPermission(permission, groupId)
				: hasGlobalPermission(permission);
		});
	}

	return groupId
		? hasPermission(permission, groupId)
		: hasGlobalPermission(permission);
};

export const useSomePermission = (permission, groupId) => {
	const { hasPermission, hasGlobalPermission } = useAuth();

	if (Array.isArray(permission)) {
		return permission.some((permission) => {
			return groupId
				? hasPermission(permission, groupId)
				: hasGlobalPermission(permission);
		});
	}

	return groupId
		? hasPermission(permission, groupId)
		: hasGlobalPermission(permission);
};
