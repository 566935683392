import { useEffect } from "react";
import CustomSelectOption from "../../Global/CustomSelectOption";
import FloatingLabelInput from "../../Global/InputFloatingLabel";

function FilterTypeString({ currentEditingFilterData, handleChange }) {
	const operators = [
		{
			name: "filter.contains",
			operator: "contains",
		},
		{
			name: "filter.equal",
			operator: "equals",
		},
	];

	useEffect(() => {
		if (!currentEditingFilterData.operator)
			handleChange({
				...currentEditingFilterData,
				operator: operators[0].operator,
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div>
			<CustomSelectOption
				value={
					operators.find(
						(o) => o.operator === currentEditingFilterData.operator
					)?.name
				}
				onChange={(e) =>
					handleChange({
						operator: operators.find(
							(o) => o.name === e.target.value
						).operator,
					})
				}
				values={operators.map((o) => o.name)}
			/>

			<FloatingLabelInput
				type="text"
				classNameInput="px-2 py-1"
				className="mt-2 w-auto"
				value={currentEditingFilterData.value || ""}
				onChange={(e) =>
					handleChange({
						...currentEditingFilterData,
						value: e.target.value,
					})
				}
			/>
		</div>
	);
}

export default FilterTypeString;
