import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { updateProfile } from "firebase/auth";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import auth from "../config/firebase";
import { useAuth } from "../context/AuthContext";
import CustomButton from "../layouts/Global/CustomButton";
import FloatingLabelInput from "../layouts/Global/InputFloatingLabel";
import FloatingLabelPassowrd from "../layouts/Global/PasswordFloatingLabel";
import WhiteBlockDiv from "../layouts/Global/WhiteBlockDiv";
import { GeneratePin, UpdateUsername } from "../requests/user/Me";
import { validateName } from "../util/Form";
import { ToastAfterLoading, ToastLoading } from "../util/ToastUtil";

function Profile() {
	const { t } = useTranslation();
	document.title = `KUPA Station | ${t("profil")}`;

	const { firebaseUser, updatePasswordFunction, kupaUser, refreshSelfInfos } =
		useAuth();

	const currentUsername = firebaseUser.displayName || firebaseUser.email;
	const [userName, setUserName] = useState(currentUsername);

	const [oldPassword, setOldPassword] = useState("");
	const [newPassword, setNewPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");

	const isPasswordProvider = firebaseUser.providerData.find(
		(provider) => provider.providerId === "password"
	);
	const generatePin = async () => {
		ToastLoading("generate.pin");
		const data = await GeneratePin();
		refreshSelfInfos();
		ToastAfterLoading("generate.pin", data?.success, data?.data?.message);
	};

	return (
		<WhiteBlockDiv
			classNameChild={
				"flex flex-col mobile:grid mobile:grid-cols-2 align-middle  justify-evenly"
			}
		>
			<div className="flex align-middle justify-center mobile:justify-end">
				<div className="p-4 mobile:p-8 m-4 text-center text-kupablack ">
					<FontAwesomeIcon className="text-5xl" icon={faUser} />
					<div className="text-lg">
						<span className="text-kupablack dark:text-kupawhite text-sm font-normal">
							{firebaseUser.email}
						</span>
					</div>
					<div className="text-left ">
						<span className="m-1 text-four text-sm font-bold">
							{t("name")}
						</span>
						<FloatingLabelInput
							defaultValue={userName}
							onChange={(e) => setUserName(e.target.value)}
							onBlur={async () => {
								if (userName === currentUsername) return;

								if (!validateName(userName)) {
									setUserName(currentUsername);
									return toast.error(
										t("profil.name.invalid")
									);
								}

								await UpdateUsername(userName);
								await updateProfile(auth.currentUser, {
									displayName: userName,
								});

								toast.success(t("profil.name.success"));
							}}
						/>
					</div>
					{isPasswordProvider && (
						<>
							<div className="text-left mt-2">
								<span className="m-1 text-four text-sm font-bold">
									{t("login.info")}
								</span>
								<FloatingLabelPassowrd
									type={"auth.password"}
									name={t("auth.password")}
									value={oldPassword}
									onChange={(e) =>
										setOldPassword(e.target.value)
									}
								/>
								<FloatingLabelPassowrd
									type={"auth.password"}
									name={t("auth.password.new")}
									value={newPassword}
									onChange={(e) =>
										setNewPassword(e.target.value)
									}
								/>
								<FloatingLabelPassowrd
									name={t("auth.password.confirm_new")}
									value={confirmPassword}
									onChange={(e) =>
										setConfirmPassword(e.target.value)
									}
								/>
							</div>
							<div className="flex justify-end bottom-2 right-2">
								<CustomButton
									className="mt-4 px-5 bg-kupablue hover:bg-kupabluedark"
									rounded={true}
									text={t("confirm")}
									onClick={async () => {
										if (
											newPassword === "" ||
											confirmPassword === "" ||
											oldPassword === ""
										) {
											toast.error(
												t("fill.in.all.fields")
											);
											return;
										}

										if (newPassword !== confirmPassword) {
											toast.error(
												t("auth.password.mismatch")
											);
											return;
										}
										ToastLoading("updatePassword");
										const data =
											await updatePasswordFunction(
												oldPassword,
												newPassword
											);
										ToastAfterLoading(
											"updatePassword",
											data
										);

										setOldPassword("");
										setNewPassword("");
										setConfirmPassword("");
									}}
								/>
							</div>
						</>
					)}
				</div>
			</div>
			<div className="flex align-middle justify-center mobile:justify-start">
				<div className="mobile:p-8 mobile:m-4 mobile:mt-12 text-center text-kupablack ">
					{kupaUser.groupMember.length > 0 && (
						<div className="mobile:p-8 mobile:m-4 text-center text-kupablack ">
							<span className="m-1 text-four text-sm font-bold">
								{t("pin.info")}
							</span>

							<div className="mt-2">
								{kupaUser?.pin ? (
									<>
										<span className="pt-2 ">
											{kupaUser?.pin.slice(0, 3)}
											{kupaUser?.pin.slice(3)}
										</span>
									</>
								) : (
									<span className="pt-2">&nbsp;</span>
								)}
								<div className="flex justify-end bottom-2 right-2">
									<CustomButton
										className="mt-2 px-5 bg-kupablue hover:bg-kupabluedark"
										rounded={true}
										text={
											kupaUser?.pin
												? t("pin.generate")
												: t("pin.create")
										}
										onClick={generatePin}
									/>
								</div>
							</div>
						</div>
					)}
				</div>
			</div>
		</WhiteBlockDiv>
	);
}

export default Profile;
