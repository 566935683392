import React, { useState } from "react";
import Count from "./Counter";

//styling

const StatsByDate = ({ title, data, duration }) => {
	const [isShown, setIsShown] = useState(false);
	if (!data) return null;
	return (
		<div
			className="  group dark:border-primarydark p-2 m-2  "
			onMouseEnter={() => setIsShown(true)}
			onMouseLeave={() => setIsShown(false)}
		>
			<Count
				data={{
					number: data,
					duration: duration,
					shown: isShown,
				}}
			/>
			<h1 className="mb-3 text-xl text-kupablack font-base text-center">
				{title}
			</h1>
		</div>
	);
};

export default StatsByDate;
