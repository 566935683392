import Table from "../../../common/layouts/tables/Table";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { FetchLogs } from "../../requests/log/Log";
import LogFilters from "../filters/LogFilters";
import WhiteBlockDiv from "../../../common/layouts/Global/WhiteBlockDiv";

function LogTable() {
	const [filters, setFilters] = useState({});
	const [currentPage, setCurrentPage] = useState(1);
	const { t } = useTranslation();

	const { data, isFetching, refetch } = useQuery([`logs`], {
		queryFn: () => FetchLogs(filters, currentPage, 30),
	});

	const { data: logs, pagination } = data || {};

	const handleNewFilters = async (newFilters) => {
		setCurrentPage(1);
		setFilters(newFilters);
	};

	useEffect(() => {
		refetch();
	}, [filters, currentPage, refetch]);

	const columns = [
		t("type"),
		t("station"),
		t("user"),
		t("group"),
		t("before"),
		t("after"),
		t("date"),
	];

	const rows = logs?.map((item) => {
		return [
			item.log_type.key,
			item.machine?.machine_name?.private_name || "N/A",
			item.user?.email || "N/A",
			item.group?.name || "N/A",
			item.before !== null && item.before !== undefined
				? JSON.stringify(item.before)
				: "N/A",
			item.after !== null && item.after !== undefined
				? JSON.stringify(item.after)
				: "N/A",
			moment(item.created_at).format("YYYY-MM-DD HH:mm:ss"),
		];
	});

	return (
		<WhiteBlockDiv classNameChild={"overflow-auto"}>
			<div>
				<LogFilters handleNewFilters={handleNewFilters} />
				<Table
					columns={columns}
					rows={rows}
					isLoading={isFetching}
					pagination={pagination}
					currentPage={currentPage}
					handlePageChange={setCurrentPage}
				/>
			</div>
		</WhiteBlockDiv>
	);
}

export default LogTable;
