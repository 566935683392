import React, { useEffect, useState } from "react";
import SignUpContainer from "../containers/SignUpContainer";
import WhiteBlockDiv from "../layouts/Global/WhiteBlockDiv";
import LogoLogin from "../layouts/logos/LogoLogin";
function SignUp() {
	useEffect(() => {
		document.title = "KUPA Station | Sign up";
	}, []);
	const [isMobile, setIsMobile] = useState(
		document.documentElement.clientWidth < 1000
	);
	return (
		<div className="w-screen h-screen mix-blend-normal">
			<div className="w-screen h-full relative  z-10">
				<div className="lg:grid lg:grid-cols-2  h-screen">
					<div className="flex items-center h-1/6 lg:h-2/3 p-2 lg:order-last  justify-center">
						<LogoLogin
							customWidthLogin={isMobile ? "250px" : "250px"}
							textsize="md:text-5xl"
							login={true}
						/>
					</div>
					<div className="flex justify-center lg:items-center h-fit lg:h-full m-3 lg:m-0 ">
						<WhiteBlockDiv className="h-fit">
							<div className="flex flex-col justify-center items-center p-5 md:p-10 lg:px-20">
								<SignUpContainer />
							</div>
						</WhiteBlockDiv>
					</div>
				</div>
			</div>
		</div>
	);
}

export default SignUp;
