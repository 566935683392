import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ToastNotSavedCustom } from "../../util/ToastUtil";
import FloatingLabelInput from "./InputFloatingLabel";

export default function Dropdown({
	list,
	className,
	disabled = false,
	classNameInput,
	customTitle,
	alwaysCustomTitle = false,
	adding,
	name,
}) {
	const { t } = useTranslation();
	const [open, setOpen] = useState(false);
	const [title, setTitle] = useState(list.title);
	const newElement = useRef(null);
	const [addingInputVisible, setAddingInputVisible] = useState(false);
	const ref = useRef(null);

	const handleClickOutside = (e) => {
		if (ref.current && !ref.current.contains(e.target)) setOpen(false);
	};

	useEffect(() => {
		document.addEventListener("click", handleClickOutside, true);
		return () =>
			document.removeEventListener("click", handleClickOutside, true);
	}, []);

	const handleAddElement = async () => {
		if (newElement.current.value !== "") {
			const data = await adding.function(newElement.current.value);
			if (!data.success) {
				ToastNotSavedCustom(t(data.data.message));
				return;
			}
			setAddingInputVisible(!addingInputVisible);
		}
	};
	const hasWidthClass = /w-/.test(className);

	return (
		<div
			className={classNames(
				"p-1",
				!hasWidthClass && "w-[256px]",
				className
			)}
			ref={ref}
		>
			<div className="relative w-full inline-block">
				<button
					type="button"
					className={classNames(
						`flex px-1.5 py-2.5  w-full h-fit  text-sm text-[#3c4858] bg-transparent rounded-[5px] border-1  appearance-none ring-1 focus:outline-none focus:ring-1  ${
							disabled
								? "bg-gray ring-0 focus:ring-black/60"
								: "bg-white ring-kupablue focus:ring-kupabluedark/60"
						} peer`,
						classNameInput
					)}
					placeholder=" "
					aria-haspopup="true"
					onClick={() => setOpen(!open)}
				>
					<label
						className={`${
							name ? "" : "hidden"
						} absolute w-fit p-[2px] text-xs sm:text-sm rounded-lg text-[#3c4858] whitespace-nowrap overflow-hidden duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-xs  sm:peer-focus:text-sm peer-focus:text-kupabluedark peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 pointer-events-none`}
						style={{
							textOverflow: "ellipsis",
							maxWidth: "calc(100% - 4px)",
						}}
					>
						{name}
					</label>
					<div className=" flex-grow flex items-center whitespace-nowrap w-fit text-xs sm:text-sm ">
						{title !== undefined && !alwaysCustomTitle
							? title
							: customTitle}
					</div>
					<div className="mt-[3px] ml-2 w-5 h-full flex ">
						<FontAwesomeIcon
							icon={faAngleDown}
							className={` duration-300 ${
								open ? "rotate-0" : "-rotate-90"
							}`}
						/>
					</div>
				</button>
				{open && (
					<>
						<div
							className="origin-top-left absolute left-0 mt-0 z-20 w-56 rounded-md shadow-lg bg-white dark:bg-kupabluee"
							role="menu"
							aria-orientation="vertical"
							aria-labelledby="menu-button"
							tabIndex="-1"
						>
							<div className="py-1" role="none">
								{list.map((elem, index) => (
									<button
										className={`${
											elem.disabled
												? "gray cursor-not-allowed"
												: "dark:text-kupawhite font-bold text-darkgray"
										} block w-full text-left pl-4 ${
											elem.disabled
												? ""
												: "hover:bg-dadaGrey dark:hover:bg-kupabluedark"
										}`}
										role="menuitem"
										tabIndex="-1"
										key={elem.title}
										onClick={() => {
											if (!elem.disabled) {
												elem.onClick(elem);
												setTitle(t(elem.title));
												setOpen(false);
											}
										}}
									>
										{t(elem.title)}
									</button>
								))}
								{adding && (
									<button
										className="dark:text-red-500 font-bold text-kupablue block w-full text-left pl-4 hover:bg-dadaGrey dark:hover:bg-kupabluedark"
										role="menuitem"
										tabIndex="-1"
										key={adding.title}
										onClick={() => {
											if (!adding.disabled) {
												setAddingInputVisible(
													!addingInputVisible
												); // Toggle the visibility of the input
											}
										}}
									>
										{t(adding.title)}
									</button>
								)}
								{addingInputVisible && (
									<div className="px-3">
										<FloatingLabelInput
											className={"w-auto"}
											type="text"
											name={t("enter.new.element")}
											reference={newElement}
											defaultValue={""}
										/>

										<button
											className="dark:text-red-500 font-bold text-kupablue block w-full text-right pl-1  dark:hover:bg-kupabluedark"
											onClick={handleAddElement}
										>
											{t("confirm")}
										</button>
									</div>
								)}
							</div>
						</div>
					</>
				)}
			</div>
		</div>
	);
}
