import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import AppleLogo from "../../assets/logo/apple.png";
import FacebookLogo from "../../assets/logo/facebook.png";
import GoogleLogo from "../../assets/logo/google.png";
import MicrosoftLogo from "../../assets/logo/microsoft.png";
import { loginWithApple } from "../../util/auth/Apple";
import { loginWithFacebook } from "../../util/auth/Facebook";
import { loginWithGoogle } from "../../util/auth/Google";
import { loginWithMicrosoft } from "../../util/auth/Microsoft";
import { useNavigate } from "react-router-dom";
function ProvidersLogin() {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const loginMethods = [
		{
			name: "Google",
			icon: GoogleLogo,
			onPress: loginWithGoogle,
		},
		{
			name: "Apple",
			icon: AppleLogo,
			onPress: loginWithApple,
		},
		{
			name: "Microsoft",
			icon: MicrosoftLogo,
			onPress: loginWithMicrosoft,
		},
		{
			name: "Facebook",
			icon: FacebookLogo,
			onPress: loginWithFacebook,
		},
	];
	return (
		<div className="flex flex-row justify-center gap-[5px] my-2">
			{loginMethods.map((method) => (
				<div
					key={method.name}
					onClick={async () => {
						try {
							await method.onPress();
							navigate("/");
						} catch (error) {
							toast.error(t(error.code));
						}
					}}
					className="rounded-full mx-2  hover:bg-slate-300 ease-in duration-200 transition-colors cursor-pointer"
				>
					<img
						src={method.icon}
						alt={method.name}
						className="w-12 h-12"
					/>
				</div>
			))}
		</div>
	);
}

export default ProvidersLogin;
