import { requestAPI } from "../../common/requests/RequestAPI";
import URL from "../../common/requests/UrlsEnum";

export async function FetchGroupDashboard(groupId) {
	const data = await requestAPI(`${URL.GROUP}/${groupId}`, "GET");
	return data;
}
export async function FetchGroupDashboardStatsHeader(groupId, dates) {
	const data = await requestAPI(
		URL.GROUP_STATS_HEADER.replace("{id}", groupId),
		"POST",
		{
			startDate: dates.startDateAPI,
			endDate: dates.endDateAPI,
		}
	);

	return data.data;
}
export async function FetchGroupDashboardStats(groupId, dates) {
	const data = await requestAPI(
		URL.GROUP_STATS.replace("{id}", groupId),
		"POST",
		{
			startDate: dates.startDateAPI,
			endDate: dates.endDateAPI,
			timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
		}
	);

	return data.data;
}
export async function FetchGroupDiscount(groupId) {
	const data = await requestAPI(`${URL.GROUP}/${groupId}/discount`, "GET");
	return data;
}

export async function FetchGroupMembers(groupId) {
	const data = await requestAPI(`${URL.GROUP}/${groupId}/user`, "GET");
	return data;
}
