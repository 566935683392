import URL from "../requests/UrlsEnum";

const recipesNew = [
	{
		code: "cerise",
		name: "Cerise Noire",
		color: "rgb(41,13,9,0.8)",
		img: "cerise.png",
	},
	{
		code: "fraise",
		name: "Fraise",
		color: "rgb(254,59,13,0.8)",
		img: "fraise.png",
	},
	{
		code: "fracit",
		name: "Framboise Citron",
		color: "rgb(255, 150, 102,0.7)",
		img: "fracit.png",
	},
	{
		code: "citron",
		name: "Citron",
		color: "rgb(247, 255, 60,0.85)",
		img: "citron.png",
	},
	{
		code: "frambo",
		name: "Framboise",
		color: "rgb(227, 11, 93,0.85)",
		img: "frambo.png",
	},
	{
		code: "framel",
		name: "Melon Framboise",
		color: "rgb(255, 71, 111,0.85)",
		img: "framel.png",
	},
	{
		code: "pample",
		name: "Pamplemousse",
		color: "rgb(218, 97, 103,0.85)",
		img: "pample.png",
	},
	{
		code: "noixana",
		name: "Ananas Coco",
		color: "rgb(255, 203, 0,0.85)",
		img: "noixana.png",
	},
	{
		code: "mencit",
		name: "Menthe Citron",
		color: "rgb(22, 184, 78,0.85)",
		img: "mencit.png",
	},
	{
		code: "hibiscus",
		name: "Hibiscus",
		color: "rgb(176, 48, 96,0.85)",
		img: "hibiscus.png",
	},
	{
		code: "melon",
		name: "Melon",
		color: "rgb(255, 71, 111,0.85)",
		img: "melon.png",
	},
	{
		code: "menthe",
		name: "Menthe",
		color: "rgb(22, 184, 78,0.85)",
		img: "menthe.png",
	},
	{
		code: "vita",
		color: "rgb(185, 250, 187,0.85)",
		img: "vitamines.png",
	},
	{
		code: "elect",
		color: "rgb(250, 184, 250,0.85)",
		img: "electro.png",
	},
	{
		code: "sport",
		color: "rgb(255, 20, 24,0.85)",
		img: "electro.png",
	},
	{
		code: "sparkling",
		color: "rgb(28, 163, 236,0.85)",
		img: "petillant.png",
	},
	{
		code: "v_co2",
		color: "rgb(28, 163, 236,0.85)",
		img: "petillant.png",
	},
	{
		code: "petilant",
		color: "rgb(28, 163, 236,0.85)",
		img: "petillant.png",
	},
	{
		code: "touche",
		color: "rgb(246, 250, 185,0.85)",
		img: "edulcorant.png",
	},
	{
		code: "v_eau",
		color: "rgb(28, 163, 236,0.85)",
		img: "aucune.png",
	},
	{
		code: "Aucune",
		color: "rgb(28, 163, 236,0.85)",
		img: "aucune.png",
	},
];
function GetRecipeImg(recipe_code) {
	const image = URL.GET_IMAGE.replace("{filename}", recipe_code + ".png");
	return image;
}

function GetRecipeColor(recipe_code) {
	const recipe = recipesNew.find(
		(r) =>
			r.code?.toLowerCase() === recipe_code?.toLowerCase() ||
			r.name?.toLowerCase() === recipe_code?.toLowerCase()
	);
	return recipe ? recipe.color : "white";
}

export { GetRecipeImg, GetRecipeColor };
