import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Popup from "reactjs-popup";
import CustomButton from "../../../../common/layouts/Global/CustomButton";
import Dropdown from "../../../../common/layouts/Global/Dropdown";
import PopupHeadTitle from "../../../../common/layouts/Global/PopupHeadTitle";
import { RoundButtonXMarkAbsolute } from "../../../../common/layouts/Global/RoundButton";
import { FetchRoles } from "../../../requests/dashboard/Roles";

function ModifyMemberPopup({
	open,
	closeMethod,
	modifyMethod,
	groupId,
	currentRole,
}) {
	const { t } = useTranslation();
	const [roles, setRoles] = useState([]);
	const [selectedRole, setSelectedRole] = useState(null);

	const { data } = useQuery([`group-${groupId}-roles`], {
		queryFn: () => FetchRoles(groupId),
	});

	useEffect(() => {
		if (data && data.success) {
			const options = data?.data?.map((role) => ({
				value: role.id,
				label: role.name,
			}));
			setRoles(options);
			setSelectedRole(
				currentRole !== ""
					? options.find((role) => role.value === currentRole)?.value
					: null
			);
		}
	}, [currentRole, data]);
	if (!data || !data.success) return null;

	return (
		<Popup
			open={open}
			onClose={closeMethod}
			contentStyle={{
				borderRadius: "0.75rem",
				width: "auto",
				padding: "10px",
			}}
			className="relative"
		>
			<RoundButtonXMarkAbsolute functionCall={closeMethod} />

			<PopupHeadTitle text={t("modify.member.title")} />

			<div className="text-center p-1 mt-2">
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
					}}
				>
					<Dropdown
						className=""
						customTitle={
							currentRole !== ""
								? roles.find(
										(role) => role.value === currentRole
								  )?.label
								: ""
						}
						list={roles.map((role) => ({
							title: role.label,
							onClick: () => setSelectedRole(role.value),
						}))}
					/>
				</div>
			</div>
			<div className="flex justify-end">
				<CustomButton
					className="mt-1 w-40"
					text={t("modify")}
					onClick={() => {
						const memberRole = selectedRole;

						if (memberRole !== null) {
							modifyMethod(memberRole);
							setSelectedRole(
								currentRole !== ""
									? roles.find(
											(role) => role.value === currentRole
									  )?.value
									: null
							);
						}
					}}
				/>
			</div>
		</Popup>
	);
}
export default ModifyMemberPopup;
