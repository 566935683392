import { useTranslation } from "react-i18next";
import CustomCheckbox from "../../Global/CustomCheckbox";

function FilterTypeCheckbox({
	values,
	currentEditingFilterData,
	handleChange,
	custom,
}) {
	const { t } = useTranslation();

	return (
		<div className="overflow-auto max-h-72">
			{Object.entries(values).map(([key, value], index) => (
				<CustomCheckbox
					key={index}
					checked={
						(currentEditingFilterData.value?.includes(key) ||
							currentEditingFilterData.value?.includes(
								Number(key)
							)) ??
						false
					}
					onChange={(e) => {
						if (e.target.checked) {
							handleChange({
								...currentEditingFilterData,
								value: [
									...(currentEditingFilterData.value ?? []),
									isNaN(key) ? key : Number(key),
								],
								custom: undefined,
							});
						} else {
							handleChange({
								...currentEditingFilterData,
								value: currentEditingFilterData.value?.filter(
									(v) => v !== key && v !== Number(key)
								),
								custom: undefined,
							});
						}
					}}
					value={t(value)}
				/>
			))}

			{custom && (
				<>
					<div className="w-full border-b-[1px] my-1.5 border-gray-300" />

					<div>
						{custom.map((item, index) => (
							<CustomCheckbox
								key={index}
								checked={
									currentEditingFilterData.custom?.value ===
										item.value ?? false
								}
								onChange={(e) => {
									if (e.target.checked) {
										handleChange({
											...currentEditingFilterData,
											value: [],
											custom: item,
										});
									} else {
										handleChange({
											...currentEditingFilterData,
											value: [],
											custom: undefined,
										});
									}
								}}
								value={t(item.value)}
							/>
						))}
					</div>
				</>
			)}
		</div>
	);
}

export default FilterTypeCheckbox;
