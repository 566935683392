import {
	faCheck,
	faFilm,
	faGear,
	faInfoCircle,
	faPenToSquare,
	faSignal,
	faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Permissions from "../../../common/config/permissions";
import { useAuth } from "../../../common/context/AuthContext";
import { TreeContext } from "../../../common/context/TreeContext";
import Restricted from "../../../common/context/hooks/Restricted";
import { usePermission } from "../../../common/context/hooks/usePermission";
import FloatingLabelInput from "../../../common/layouts/Global/InputFloatingLabel";
import MachineTooltip from "../../../common/layouts/Global/MachineTooltip";
import TooltipNew from "../../../common/layouts/Global/TooltipNew";
import { RenameMachine } from "../../requests/machines/Machine";

function Machine({ machine, groupId, refetch }) {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { hasAccessGroup } = useAuth();

	const { setUpdateTree, setLastSelected } = useContext(TreeContext);
	const { hasPermission, hasGlobalPermission } = useAuth();
	const MachinePrivateName = useRef(null);
	const canEditMachineName = usePermission(
		Permissions.MACHINE_NAME_EDIT,
		groupId
	);

	const [isEditing, setIsEditing] = useState(false);
	const [machineName, setMachineName] = useState(
		hasGlobalPermission(Permissions.GROUP_MACHINES_VIEW)
			? machine.factory_name
			: machine.private_name
	);

	const editMachineName = (e) => {
		e.stopPropagation();
		setIsEditing(true);
	};

	const submitNewName = async (e) => {
		e.stopPropagation();

		setIsEditing(false);
		await RenameMachine(machine, MachinePrivateName.current.value);
		refetch();
	};

	const cancelEditMachineName = (e) => {
		e.stopPropagation();
		setIsEditing(false);
	};
	const getMachineDisplayName = () => {
		if (hasGlobalPermission(Permissions.GROUP_MACHINES_VIEW)) {
			return machine.private_name;
		}

		if (hasPermission(Permissions.GROUP_MACHINES_VIEW, groupId)) {
			return machine.public_name;
		}

		return null;
	};
	useEffect(() => {
		setMachineName(
			hasGlobalPermission(Permissions.GROUP_MACHINES_VIEW)
				? machine.factory_name
				: machine.private_name
		);
	}, [machine, hasGlobalPermission]);
	const [machineTooltipVisible, setMachineTooltipVisible] = useState(false);
	const tooltipRef = useRef(null);
	const tooltipActionRef = useRef(null);
	function handleClickOutside(event) {
		if (
			tooltipActionRef.current &&
			!tooltipActionRef.current.contains(event.target)
		) {
			setMachineTooltipVisible(false);
		}
	}

	return (
		<div className="relative flex-auto border-t-none h-fit rounded-xl w-auto">
			<div
				className={classNames(
					"flex flex-col p-3 pb-0 mb-0 h-full rounded-xl w-auto shadow-md hover:ring-kupablue hover:ring-2 hover:ring-opacity-30",
					machine.status.className
				)}
				onClick={() => {
					navigate(
						`/portal/${machine.group_id}/machine/${machine.id}`
					);
					setUpdateTree((prev) => prev + 1);
				}}
			>
				<div className="flex justify-between items-start">
					<div className="flex flex-col items-center w-full">
						<div className="flex w-full">
							<div className="">
								{isEditing ? (
									<>
										<FloatingLabelInput
											type="text"
											defaultValue={machine.private_name}
											autoFocus
											maxLength={30}
											reference={MachinePrivateName}
											name={t("machine.name")}
											onClick={(e) => e.stopPropagation()}
										/>
									</>
								) : (
									<>
										<div
											className="font-bold text-kupablack text-ellipsis"
											style={{
												fontSize: "15px",
												wordBreak: "break-word",
											}}
										>
											{machineName}
										</div>
									</>
								)}
							</div>
						</div>
						<div className="justify-between items-start text-center w-full">
							{isEditing && (
								<div className="  ">
									<FontAwesomeIcon
										icon={faXmark}
										onClick={(e) =>
											cancelEditMachineName(e)
										}
										className=" text-black w-6 h-6 mr-2 -mb-[2.2px]"
									/>
									<FontAwesomeIcon
										icon={faCheck}
										onClick={async (e) =>
											await submitNewName(e)
										}
										className="text-kupablue w-6 h-6 mr-2 -mb-[2.2px]"
									/>
								</div>
							)}
						</div>
					</div>
				</div>
				<div className="">
					<span className="text-sm">{getMachineDisplayName()}</span>
				</div>
				<div className="flex justify-between items-center min-w-fit ">
					<div className="text left">
						<FontAwesomeIcon
							icon={faFilm}
							className={classNames("w-5 h-5 mr-2 -mb-[2.2px]", {
								"text-green-400": machine.isVideoOnline,
								"text-kupared": !machine.isVideoOnline,
							})}
						/>
						<FontAwesomeIcon
							icon={faSignal}
							className={classNames("w-5 h-5 text-xl", {
								"text-green-400": machine.isOnline,
								"text-kupared": !machine.isOnline,
							})}
						/>
					</div>
					<div className="flex gap-1 text-right">
						{hasPermission(
							Permissions.GROUP_MACHINES_VIEW,
							groupId
						) && (
							<div
								className=" flex  text-kupablue hover:text-kupabluedark "
								onClick={(e) => {
									e.stopPropagation();
									setMachineTooltipVisible(
										!machineTooltipVisible
									);
								}}
								ref={tooltipActionRef}
							>
								<FontAwesomeIcon
									icon={faInfoCircle}
									className="w-5 h-5 "
									size={"xs"}
								/>
							</div>
						)}
						{hasAccessGroup(groupId) && (
							<div className=" flex text-kupablue hover:text-kupabluedark">
								{canEditMachineName && !isEditing && (
									<FontAwesomeIcon
										icon={faPenToSquare}
										onClick={(e) => editMachineName(e)}
										className="w-5 h-5  "
									/>
								)}
							</div>
						)}

						{hasAccessGroup(groupId) && (
							<Restricted
								to={[
									Permissions.MACHINE_SETTING_VALVE_EDIT,
									Permissions.MACHINE_SETTING_CONTAINER_EDIT,
									Permissions.MACHINE_SETTING_PAYMENT_TOGGLE,
									Permissions.MACHINE_SETTING_OPTION_EDIT,
									Permissions.MACHINE_SETTING_OPTION_TOGGLE,
									Permissions.MACHINE_SETTING_RECIPE_EDIT,
									Permissions.MACHINE_SETTING_RECIPE_TOGGLE,
								]}
								groupId={groupId}
							>
								<button
									onClick={(e) => {
										e.stopPropagation();
										navigate(
											`/portal/${groupId}/machine/${machine.id}/setting`,
											{
												state: {
													isOnline: machine.isOnline,
												},
											}
										);
										setLastSelected({
											type: "Setting",
											id: machine.id,
										});
									}}
									className=" flex text-kupablue hover:text-kupabluedark"
								>
									<TooltipNew text={t("settings")}>
										{" "}
										<FontAwesomeIcon
											className="w-5 h-5"
											icon={faGear}
										/>
									</TooltipNew>
								</button>
							</Restricted>
						)}
					</div>
				</div>
			</div>
			{machineTooltipVisible && (
				<MachineTooltip
					ref={tooltipRef}
					machine={machine}
					handleClickOutside={handleClickOutside}
				/>
			)}
		</div>
	);
}
export default Machine;
