import ToggleButton from "../../../../common/layouts/Global/ToggleButton";
import { useTranslation } from "react-i18next";
import Containers from "./prices/Containers";
import WhiteBlockDiv from "../../../../common/layouts/Global/WhiteBlockDiv";

function PriceSettings({
	containers,
	payments,
	handlePaymentChange,
	addContainer,
	handleDeleteContainer,
	handleChangeContainer,
	containerMove,
}) {
	const { t } = useTranslation();

	return (
		<div className="m-2 gap-2 lg:grid lg:grid-cols-1">
			<WhiteBlockDiv
				className={"mb-2 sm:mb-0"}
				classNameChild={"m-auto mt-4"}
			>
				<ToggleButton
					name={t("price.enabled")}
					checked={payments}
					handleChange={handlePaymentChange}
					id="payments"
				/>
			</WhiteBlockDiv>

			<WhiteBlockDiv
				className={"mb-2 sm:mb-0"}
				text={t("settings.beverage.formats")}
			>
				<Containers
					containers={containers}
					enabledPayment={payments}
					addContainer={addContainer}
					handleDeleteContainer={handleDeleteContainer}
					handleChangeContainer={handleChangeContainer}
					containerMove={containerMove}
				/>
			</WhiteBlockDiv>
		</div>
	);
}

export default PriceSettings;
