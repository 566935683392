import {
	ArcElement,
	BarElement,
	CategoryScale,
	Chart as ChartJS,
	Legend,
	LineElement,
	LinearScale,
	PointElement,
	Title,
	Tooltip,
} from "chart.js";

import { faGlassWater, faSackDollar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useQuery } from "@tanstack/react-query";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ReactComponent as IconMachines } from "../../common/assets/icons/machines.svg";
import Permissions from "../../common/config/permissions";
import { DimensionsContext } from "../../common/context/DimensionsContext";
import Restricted from "../../common/context/hooks/Restricted";
import AppVsMobile from "../layouts/Stats/AppVsMobile";
import Boissons from "../layouts/Stats/Boissons";
import BoissonsParMois from "../layouts/Stats/BoissonsParMois";
import DataWithIconAndNumber from "../layouts/Stats/DataWithIconAndNumber";
import TopMachines from "../layouts/Stats/TopMachines";
import OrderTable from "../layouts/tables/OrderTable";
import { FetchGroupDashboardStatsHeader } from "../requests/Group";

function Stats({
	boissonsParMois,
	appVsMobile,
	boissons,
	topMachines,
	groupId,
	isLoading,
}) {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { endDateAPI, startDateAPI } = useContext(DimensionsContext);
	ChartJS.register(
		CategoryScale,
		LinearScale,
		BarElement,
		ArcElement,
		PointElement,
		LineElement,
		Title,
		Tooltip,
		Legend
	);
	const { data, refetch } = useQuery([`stats-${groupId}-header`], {
		queryFn: () =>
			FetchGroupDashboardStatsHeader(groupId, {
				startDateAPI,
				endDateAPI,
			}),
	});
	const [reload, setReload] = useState(false);
	useEffect(() => {
		const refetchFunction = async () => {
			setReload(true);
			await refetch();
			setReload(false);
		};
		refetchFunction();
	}, [startDateAPI, endDateAPI]);
	return (
		<div>
			<div
				className={`my-2 space-y-2 md:space-y-0 gap-2 md:grid md:grid-cols-3 ${
					reload ? "blur-[3px]" : null
				}`}
			>
				<Restricted
					to={Permissions.GROUP_STATS_ORDER_VIEW}
					groupId={groupId}
				>
					<DataWithIconAndNumber
						color={"rgb(0 172 211 / 0.1)"}
						icon={
							<FontAwesomeIcon
								className="w-full h-full"
								icon={faGlassWater}
								color="#CBCBCB"
							/>
						}
						text={t("drinks")}
						reload={reload}
						data={data ? data?.TotalOrder ?? 0 : 27868}
					/>
				</Restricted>

				<Restricted
					to={Permissions.GROUP_STATS_ORDER_REVENUE_VIEW}
					groupId={groupId}
				>
					<DataWithIconAndNumber
						color={"rgb(249 115 22 / 0.1)"}
						icon={
							<FontAwesomeIcon
								className="w-full h-full"
								icon={faSackDollar}
								color="#CBCBCB"
							/>
						}
						text={t("income")}
						reload={reload}
						data={
							<div className=" ">
								{data ? data?.TotalRevenus ?? 0 : 27868}

								<span className="text-xs md:text-sm">$CAD</span>
							</div>
						}
					/>
				</Restricted>

				<Restricted
					to={Permissions.GROUP_STATS_TOPMACHINE_VIEW}
					groupId={groupId}
				>
					<DataWithIconAndNumber
						color={"hsl(0 0% 80% / 0.5)"}
						iconClick={() => {
							navigate("machines");
						}}
						icon={<IconMachines fill="#CBCBCB" />}
						text={t("stations")}
						reload={reload}
						data={data ? data?.TotalMachines ?? 0 : 27868}
					/>
				</Restricted>
			</div>

			<div
				className={` gap-2 lg:grid lg:grid-cols-2 ${
					isLoading ? "blur-[2px]" : null
				}`}
			>
				<Restricted
					to={Permissions.GROUP_STATS_ORDER_VIEW}
					groupId={groupId}
				>
					<BoissonsParMois
						key={data?.TotalOrder}
						stats={boissonsParMois}
					/>
				</Restricted>

				<Restricted
					to={Permissions.GROUP_STATS_TOPMACHINE_VIEW}
					groupId={groupId}
				>
					<TopMachines stats={topMachines} />
				</Restricted>

				<Restricted
					to={Permissions.GROUP_STATS_ORDER_VIEW}
					groupId={groupId}
				>
					<Boissons stats={boissons} text={t("drinks.served")} />
					<AppVsMobile stats={appVsMobile} />
				</Restricted>
			</div>
			<Restricted
				to={Permissions.GROUP_STATS_ORDER_VIEW}
				groupId={groupId}
			>
				<div className="mt-[8px]  gap-2 lg:grid lg:grid-cols-1">
					<OrderTable groupId={groupId} />
				</div>
			</Restricted>
		</div>
	);
}
export default Stats;
