import { faAngleDown, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import CustomCheckbox from "../../../common/layouts/Global/CustomCheckbox";

function NotificationTypes({
	notificationTypes,
	currentNotificationTypes,
	handleChange,
}) {
	const { t } = useTranslation();
	const [isOpen, setIsOpen] = useState(false);
	const ref = useRef(null);
	const handleDocumentClick = (event) => {
		if (ref.current && ref.current.contains(event.target)) {
			return;
		}
		setIsOpen(false);
	};
	useEffect(() => {
		document.addEventListener("click", handleDocumentClick);
		return () => document.removeEventListener("click", handleDocumentClick);
	}, []);

	/* useEffect(() => {
		 if (!isOpen) handleSave(); 
		console.log(isOpen);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isOpen]); */

	const isActive = currentNotificationTypes?.length > 0;
	const CheckBoxName = (checkedValues) => {
		const values =
			checkedValues?.map((value) => t(notificationTypes[value])) || [];
		return (
			values.slice(0, 2).join(", ") +
			(values.length > 2
				? t("filter.xmore", {
						1: values.length - 2,
				  })
				: "")
		);
	};
	return (
		<div className="m-1 relative" ref={ref}>
			<div
				className={classNames(
					"text-sm bg-backgroundgray ring-1 ring-kupablue rounded-[5px] p-2 ",
					{
						"border-hidden": !isActive,
					}
				)}
				onClick={() => setIsOpen((prev) => !prev)}
			>
				<FontAwesomeIcon
					icon={isOpen > 0 ? faAngleDown : faAngleRight}
					className="text-kupablue px-1 inline cursor-pointer"
				/>
				<p className="inline cursor-pointer p-2">
					{t("notification.types")} :{" "}
					{isActive && CheckBoxName(currentNotificationTypes)}
				</p>
			</div>

			{isOpen && (
				<NotificationChecked
					values={notificationTypes}
					currentEditingFilterData={currentNotificationTypes}
					handleChange={handleChange}
					handleClose={() => setIsOpen(false)}
				/>
			)}
		</div>
	);
}

export default NotificationTypes;

function NotificationChecked({
	values,
	currentEditingFilterData,
	handleChange,
	custom,
}) {
	const { t } = useTranslation();

	return (
		<div className="items-center absolute z-20 min-w-[160px]">
			<div className="bg-white rounded-md shadow-lg mt-2 overflow-y-auto p-2">
				<div className="overflow-auto max-h-72">
					{Object.entries(values).map(([key, value], index) => (
						<CustomCheckbox
							key={index}
							checked={
								(currentEditingFilterData?.includes(key) ||
									currentEditingFilterData?.includes(
										Number(key)
									)) ??
								false
							}
							onChange={(e) => {
								handleChange(key);
							}}
							value={t(value)}
						/>
					))}
				</div>
			</div>
		</div>
	);
}
