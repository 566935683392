import { faLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import DeleteIcon from "../../../common/layouts/Global/Icons/DeleteIcon";
import TooltipNew from "../../../common/layouts/Global/TooltipNew";
import { ToastNotSavedCustom } from "../../../common/util/ToastUtil";
import Warning from "../../../common/assets/warning.png";
const DragAndDropExample = ({
	sequence,
	draggedItem,
	setoriginDiv,
	originDiv,
	setCancel,
	setCheckArrow,
	droppedItems,
	setDroppedItems,
}) => {
	const [lastUsedId, setLastUsedId] = useState(0);

	const divRef = useRef(null);
	const { t } = useTranslation();

	useEffect(() => {
		const divElement = divRef.current;

		if (!divElement) return;

		const hasOverflow = divElement.scrollWidth > divElement.clientWidth;
		setCheckArrow(hasOverflow);
		const list = [];
		let newId = lastUsedId;

		if (sequence) {
			sequence?.forEach((item) => {
				const newitem = draggedItem.find(
					(gallery) => gallery.id === item
				);
				newId += 1;
				list.push({
					...newitem,
					id: String(newId),
					realId: newitem.id,
				});
			});
			setLastUsedId(newId);
			setDroppedItems(list);
		}
	}, [sequence]);

	const handleDragOver = (event) => {
		event.preventDefault();
	};

	const handleDrop = (event) => {
		event.preventDefault();
		if (!sequence) {
			return;
		}
		setCancel(true);
		const itemId = event.dataTransfer.getData("text/plain");
		const newitem = draggedItem.find(
			(item) => item.id.toString() === itemId
		);

		if (newitem && originDiv) {
			if (newitem.approved === false) {
				ToastNotSavedCustom("La vidéo n'est pas encore approuvée");
				return;
			}
			const updatedItems = [...droppedItems];
			const newId = lastUsedId;
			setLastUsedId((prevId) => prevId + 1);

			updatedItems.push({
				...newitem,
				id: String(newId),
				realId: newitem.id,
			});
			setDroppedItems(updatedItems);
		}
		setoriginDiv(false);
	};
	const handleItemDragStart = (event, itemId) => {
		event.dataTransfer.setData("text/plain", itemId);
	};
	const handleItemDragOver = (event) => {
		event.preventDefault();
	};

	const handleItemDrop = (event, index) => {
		event.preventDefault();
		setCancel(true);
		const itemId = event.dataTransfer.getData("text/plain");
		const itemIndex = droppedItems.findIndex((item) => item.id === itemId);

		if (itemIndex > -1) {
			const updatedItems = [...droppedItems];
			const removedItem = updatedItems.splice(itemIndex, 1)[0];
			updatedItems.splice(index, 0, removedItem);
			setDroppedItems(updatedItems);
		}
	};
	const handleDeleteItem = (index) => {
		const updatedItems = [...droppedItems];
		updatedItems.splice(index, 1);
		setDroppedItems(updatedItems);
		setCancel(true);
	};
	const handleItemDragEnd = (event) => {
		event.preventDefault();
		const itemId = event.dataTransfer.getData("text/plain");
		const itemIndex = droppedItems.findIndex((item) => item.id === itemId);
	};
	const stylesDivDelete = {
		zIndex: 1,
		position: "absolute",
		top: "0",
		right: "0",
		height: "15px",
		width: "15px",
		display: "flex",
		alignItems: "center",
	};
	return (
		<div
			ref={divRef}
			className="flex flex-row"
			style={{
				minHeight: "100px",
				width: "100%",
				height: "fit-content",
			}}
			onDragOver={handleDragOver}
			onDrop={handleDrop}
		>
			{droppedItems ? (
				droppedItems?.map((item, index) => {
					return (
						<div key={index}>
							<div
								style={{
									height: "100px",
									width: "150px",
									display: "flex",
									flexDirection: "row-reverse",
									margin: "10px",
									marginLeft: "20px",
									position: "relative",
								}}
								key={index}
								draggable
								onDragStart={(event) =>
									handleItemDragStart(event, item.id)
								}
								onDragOver={handleItemDragOver}
								onDrop={(event) => handleItemDrop(event, index)}
								onDragEnd={handleItemDragEnd}
							>
								<div style={stylesDivDelete}>
									<DeleteIcon
										color={"#00acd3"}
										action={() => handleDeleteItem(index)}
									/>
								</div>
								<div
									style={{
										boxShadow:
											"rgba(0, 0, 0, 0.24) 0px 3px 8px",
										margin: "2px",
										width: "250px",
										border: "8px solid transparent",
										borderRadius: "10px",
										backgroundImage: `url(${
											(item &&
												item.thumbnail &&
												item.thumbnail[0]?.large
													?.url) ||
											Warning
										})`,
										backgroundSize: "cover",
										backgroundPosition: "center",
									}}
								></div>
							</div>
							<div
								className="text-black text-center mx-auto w-full text-sm"
								style={{ display: "inline-block" }}
							>
								{item.private && (
									<span
										style={{
											display: "inline-block",
											marginRight: "5px",
										}}
									>
										<TooltipNew text={"privée"}>
											<FontAwesomeIcon
												size="sm"
												icon={faLock}
												color={"#00acd3"}
											/>
										</TooltipNew>
									</span>
								)}
								<TooltipNew text={item.name}>
									<span
										className="w-[150px] overflow-hidden text-ellipsis"
										style={{ display: "inline-block" }}
									>
										{item.name}
									</span>
								</TooltipNew>
							</div>
						</div>
					);
				})
			) : (
				<div className="flex m-auto font-bold">
					{t("select.sequence.first")}
				</div>
			)}
		</div>
	);
};

export default DragAndDropExample;
