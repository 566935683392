import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import CustomButton from "../../Global/CustomButton";
import FilterTypeCheckbox from "../type/FilterTypeCheckbox";
import FilterTypeDate from "../type/FilterTypeDate";
import FilterTypeDropdown from "../type/FilterTypeDropdown";
import FilterTypeNumber from "../type/FilterTypeNumber";
import FilterTypeString from "../type/FilterTypeString";

function FilterPopup({
	filter,
	currentEditingFilterData,
	handleChange,
	handleClose,
}) {
	const { t } = useTranslation();
	const filterType = filter.type;

	const renderFilter = () => {
		switch (filterType) {
			case "checkbox":
				return (
					<FilterTypeCheckbox
						values={filter.values}
						currentEditingFilterData={currentEditingFilterData}
						handleChange={handleChange}
						custom={filter.custom}
					/>
				);

			case "dropdown":
				return (
					<FilterTypeDropdown
						values={filter.values}
						currentEditingFilterData={currentEditingFilterData}
						handleChange={handleChange}
					/>
				);

			case "string":
				return (
					<FilterTypeString
						currentEditingFilterData={currentEditingFilterData}
						handleChange={handleChange}
					/>
				);

			case "number":
				return (
					<FilterTypeNumber
						currentEditingFilterData={currentEditingFilterData}
						handleChange={handleChange}
					/>
				);

			case "date":
				return (
					<FilterTypeDate
						currentEditingFilterData={currentEditingFilterData}
						handleChange={handleChange}
					/>
				);

			default:
				return null;
		}
	};

	return (
		<div className="items-center absolute z-20 min-w-[160px]">
			<FontAwesomeIcon
				icon={faCaretRight}
				className="text-white text-2xl absolute -top-1 left-4 -rotate-90"
			/>
			<div className="bg-white rounded-md shadow-lg mt-2 overflow-y-auto p-2">
				<h1 className="text-sm font-semibold lowercase first-letter:uppercase mb-2">
					{t("filter.by", {
						1: t(filter.name),
					})}
				</h1>
				{renderFilter()}

				<CustomButton
					text={t("apply")}
					className="text-sm mt-3 w-full py-1"
					onClick={handleClose}
				/>
			</div>
		</div>
	);
}

export default FilterPopup;
