import { requestAPI } from "../../../common/requests/RequestAPI";
import URL from "../../../common/requests/UrlsEnum";

export async function GetMachineVideoSchedule(groupId) {
	let data = await requestAPI(
		URL.MACHINE_VIDEO_SCHEDULE.replace("{id}", groupId)
	);
	return data;
}

export async function CreateMachineVideoSchedule(
	groupId,
	machineId,
	videoModelId,
	start,
	end
) {
	let data = await requestAPI(
		URL.MACHINE_VIDEO_SCHEDULE.replace("{id}", groupId),
		"POST",
		{
			machineId,
			videoModelId,
			start: new Date(start).toUTCString(),
			end: end ? new Date(end).toUTCString() : null,
		}
	);

	return data;
}
export async function ModifyMachineVideoSchedule(
	groupId,
	machineId,
	machineVideoScheduleId,
	videoModelId,
	start,
	end
) {
	let data = await requestAPI(
		URL.MACHINE_VIDEO_SCHEDULE_ID.replace("{id}", groupId).replace(
			"{videoSchedule}",
			machineVideoScheduleId
		),
		"PUT",
		{
			machineId,
			videoModelId,
			start: new Date(start).toUTCString(),
			end: end ? new Date(end).toUTCString() : null,
		}
	);
	return data;
}

export async function DeleteMachineVideoSchedule(
	groupId,
	machineVideoScheduleId
) {
	let data = await requestAPI(
		URL.MACHINE_VIDEO_SCHEDULE_ID.replace("{id}", groupId).replace(
			"{videoSchedule}",
			machineVideoScheduleId
		),
		"DELETE",
		{}
	);
	return data;
}
