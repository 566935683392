import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Popup from "reactjs-popup";
import CustomButton from "../../../common/layouts/Global/CustomButton";
import Table from "../../../common/layouts/tables/Table";
import { GetRecipeImg } from "../../../common/util/RecipeUtil";
import {
	ToastAfterLoading,
	ToastLoading,
} from "../../../common/util/ToastUtil";
import { AddOrder } from "../../requests/Orders";
import Restricted from "../../../common/context/hooks/Restricted";
import Permissions from "../../../common/config/permissions";
const ItemsPurchasePopup = ({ open, closeMethod, data, groupId }) => {
	const { t } = useTranslation();
	const textAreaRef = useRef();
	const [counts, setCounts] = useState({});
	const total =
		Object.keys(counts).length === 0
			? 0
			: Object.values(counts).reduce((acc, value) => acc + value, 0);
	const ResetStates = () => {
		setCounts({});
	};
	useEffect(() => {
		ResetStates();
	}, []);
	const handleSubmit = async () => {
		ToastLoading("store.order");
		const data = await AddOrder(counts, textAreaRef.current.value, groupId);
		ToastAfterLoading("store.order", data.success, data?.data?.message);
		handleClose();
	};
	const handleClose = () => {
		ResetStates();
		closeMethod();
	};
	const columns = [t("products"), t("quantity")];
	const handleIncrement = (id) => {
		setCounts((prevCounts) => {
			const newCount = (prevCounts[id] || 0) + 1;
			return {
				...prevCounts,
				[id]: Math.min(newCount, 20),
			};
		});
	};

	const handleDecrement = (id) => {
		setCounts((prevCounts) => {
			const newCount = (prevCounts[id] || 0) - 1;
			return {
				...prevCounts,
				[id]: Math.max(newCount, 0), // Limit count to minimum of 0
			};
		});
	};

	const rows = data.map((item) => [
		<li key={item.id} className="flex align-middle">
			<img
				alt="imagefd"
				src={GetRecipeImg(item.key)}
				className="h-8 w-8 object-contain mix-blend-multiply"
			/>
			<span className="h-8 flex align-middle p-1 text-sm 2xl:text-base">
				{t(item.key)}{" "}
			</span>
		</li>,
		<div className="text-sm 2xl:text-base font-bold flex gap-2 w-20">
			<button
				className={`w-8 ${
					counts[item.id] <= 0 ? "text-secondary " : ""
				}`}
				onClick={() => handleDecrement(item.id)}
				disabled={counts[item.id] <= 0}
			>
				-
			</button>
			<span className="w-8">{counts[item.id] || 0}</span>
			<button
				className={`w-8 ${
					counts[item.id] >= 20 ? "text-secondary " : ""
				}`}
				onClick={() => handleIncrement(item.id)}
				disabled={counts[item.id] >= 20}
			>
				+
			</button>
		</div>,
	]);
	return (
		<Popup
			open={open}
			onClose={handleClose}
			contentStyle={{
				borderRadius: "0.75rem",
				padding: "20px",
				maxHeight: "95%",
				overflow: "auto",
				width: "90%",
				maxWidth: "500px",
			}}
			className="relative w-2/3"
		>
			<Table className={"mb-2"} rows={rows} columns={columns} />
			<div className="w-full text-right">
				Total : <span className="inline-block w-12">{total}</span>
			</div>
			<div className="text-kupared flex justify-center">
				{t("multiple.dix")}
			</div>
			<div>{t("command.comment")}</div>
			<textarea
				ref={textAreaRef}
				className="ring-1 ring-black w-full p-1 outline-none "
			></textarea>
			<Restricted to={Permissions.STORE_ORDER_CREATE} groupId={groupId}>
				<div className="flex justify-end mt-5">
					<CustomButton
						className=" w-40 text-sm 2xl:text-base"
						text={t("commander")}
						disabled={total === 0}
						onClick={handleSubmit}
					/>
				</div>
			</Restricted>
		</Popup>
	);
};
export default ItemsPurchasePopup;
