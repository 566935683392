import React from "react";

const ProgressBar = ({ pourcent }) => {
	let percentage;
	if (pourcent === null || pourcent === undefined || pourcent < 0) {
		percentage = 0;
	} else if (pourcent > 100) {
		percentage = 100;
	} else {
		percentage = pourcent;
	}

	return (
		<div className="bg-slate-100 relative  rounded-lg w-28 md:w-36   min-w-full xl:w-36 2xl:w-52 xl:min-w-full overflow-hidden drop-shadow-[0_1px_1px_rgba(0,0,0,0.0)]">
			<div
				className={`h-3 md:h-5 saturate-20 ${
					percentage < 20 ? "bg-yellow-500" : "bg-kupablue"
				}	drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)]`}
				style={{
					width: `${percentage}%`,
					opacity: 0.8,
				}}
			></div>

			{/* <div className="bg-white text-sm flex justify-center items-center w-12  absolute top-1/2 right-2 transform  -translate-y-1/2 align-middle ">
				{percentage.toFixed(1)}%
			</div> */}
		</div>
	);
};

export default ProgressBar;
