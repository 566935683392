import classNames from "classnames";
import React from "react";

const FloatingLabelInput = ({
	className,
	reference,
	type = "text",
	name,
	placeholder,
	nameInput,
	value,
	defaultValue,
	min,
	max,
	step,
	autoComplete,
	onChange,
	idInput,
	disabled = false,
	inputMode,
	darkmode,
	classNameGlobal,
	classNameInput,
	CompleteAPI = false,
	data,
	visible,
	handleAutoCompleteClick,
	AutoCompleteRef,
	onClick,
	maxLength,
	autoFocus = false,
	onBlur,
}) => {
	const acceptedTypes = [
		"text",
		"email",
		"number",
		"date",
		"datetime-local",
		"password",
	];

	if (!acceptedTypes.includes(type)) {
		return null;
	}
	const hasWidthClass = /w-/.test(className);
	return (
		<div
			className={classNames(
				"relative  p-1",
				!hasWidthClass && "w-[256px]",
				className
			)}
		>
			<input
				type={type}
				name={nameInput}
				min={min}
				max={max}
				step={step}
				value={value}
				ref={reference}
				defaultValue={defaultValue}
				onChange={onChange}
				autoComplete={autoComplete}
				id={idInput}
				disabled={disabled}
				inputMode={inputMode}
				className={classNames(
					`block bg-white ${
						name ? "px-1.5 pb-1 pt-4" : "px-1.5 py-2.5"
					} w-full  text-xs sm:text-sm text-[#3c4858] rounded-[5px] border-1  appearance-none ring-1 focus:outline-none focus:ring-1  ${
						disabled
							? " ring-1 ring-third  ring-opacity-25 focus:ring-black/60"
							: " ring-kupablue focus:ring-kupabluedark/60"
					} peer`,
					classNameInput
				)}
				placeholder=" "
				autoFocus={autoFocus}
				maxLength={maxLength}
				onClick={onClick}
				onBlur={onBlur}
			/>
			<label
				htmlFor={idInput}
				className={`${
					name ? "" : "hidden"
				}  absolute w-fit  text-xs sm:text-sm rounded-lg text-[#3c4858] whitespace-nowrap  duration-300 transform -translate-y-4 scale-75 top-5 z-10 origin-[0] px-2 peer-focus:px-2 peer-focus:text-xs  sm:peer-focus:text-sm peer-focus:text-kupabluedark peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-5 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 pointer-events-none overflow-hidden`}
				style={{
					textOverflow: "ellipsis",
					maxWidth: "calc(100% - 14px)",
				}}
			>
				{name}
			</label>
		</div>
	);
};

export default FloatingLabelInput;
