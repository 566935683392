import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { GetRecipeImg } from "../../../../common/util/RecipeUtil";
import FloatingLabelInput from "../../../../common/layouts/Global/InputFloatingLabel";
import WhiteBlockDiv from "../../../../common/layouts/Global/WhiteBlockDiv";
import ToggleButton from "../../../../common/layouts/Global/ToggleButton";

function SettingPanel({
	values,
	mapping,
	handleChange,
	handleFlavorChange,
	handleOptionChange,
	step,
	disabled,
}) {
	const { t } = useTranslation();
	return (
		<WhiteBlockDiv>
			<div
				className={classNames("w-full pt-2", {
					"pointer-events-none": disabled,
				})}
			>
				<div className="relative ring-opacity-20 mb-2">
					<div className="h-20">
						<img
							src={GetRecipeImg(values.key)}
							alt={values.key}
							className={`h-full object-contain mix-blend-multiply ${
								values.enabled || values.enabled === undefined
									? ""
									: "opacity-20"
							}`}
						/>
					</div>

					<div className="absolute top-0 right-0 flex items-center m-auto mt-1 mr-1">
						{values.enabled !== undefined && handleChange && (
							<ToggleButton
								name={
									values.enabled
										? t("enabled")
										: t("disabled")
								}
								checked={values.enabled}
								handleChange={() => {
									handleChange({
										target: {
											name: "enabled",
											id: values.id,
											value: values.enabled ? 0 : 1,
										},
									});
								}}
							/>
						)}
					</div>

					<div className="font-bold text-darkgray text-base p-1 ml-1 ">
						{t(values.key)}
					</div>
				</div>

				<div className="grid grid-cols-2 gap-2 w-full">
					{values.flavor &&
						Object.entries(values.flavor)?.map(
							([key, time], index) =>
								time !== null &&
								handleFlavorChange && (
									<div key={key}>
										<FloatingLabelInput
											key={index}
											className={"w-auto"}
											name={t(mapping?.flavors[key])}
											value={time}
											id={values.id}
											onChange={handleFlavorChange}
											enabled={values.enabled}
											step={step}
											idInput={values.id}
											nameInput={key}
										/>
									</div>
								)
						)}

					{values.option &&
						Object.entries(values.option).map(
							([key, time], index) =>
								time !== null &&
								handleOptionChange && (
									<div key={key}>
										<FloatingLabelInput
											key={index}
											className={"w-auto"}
											name={t(mapping.options[key])}
											value={time}
											id={values.id}
											onChange={handleOptionChange}
											enabled={values.enabled}
											step={step}
											idInput={values.id}
											nameInput={key}
										/>
									</div>
								)
						)}

					{values.value !== undefined && values.value !== null && (
						<FloatingLabelInput
							name={t("value")}
							className={"w-auto"}
							value={values.value}
							id={values.key}
							onChange={handleChange}
							enabled={true}
							step={step}
							idInput={values.key}
							nameInput="value"
						/>
					)}

					{values.price_boost !== undefined &&
						values.price_boost !== null &&
						handleFlavorChange && (
							<FloatingLabelInput
								displayName={t("mapping.options.price_boost")}
								name="price_boost"
								value={values.price_boost}
								id={values.id}
								onChange={handleFlavorChange}
								enabled={values.enabled}
								step={step}
							/>
						)}
					{values.time !== undefined && values.time !== null && (
						<FloatingLabelInput
							name={t("value")}
							value={values.time}
							id={values.id}
							className={"w-auto"}
							onChange={handleChange}
							enabled={values.enabled}
							step={step}
							idInput={values.id}
							nameInput="time"
						/>
					)}
				</div>
			</div>
		</WhiteBlockDiv>
	);
}

SettingPanel.defaultProps = {
	disabled: false,
};

export default SettingPanel;
