import { useQuery } from "@tanstack/react-query";
import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import CustomButton from "../../../../../common/layouts/Global/CustomButton";
import LoadingSpinner from "../../../../../common/layouts/Global/LoadingSpinner";
import {
	RoundButtonPen,
	RoundButtonPlus,
	RoundButtonXMark,
} from "../../../../../common/layouts/Global/RoundButton";
import WhiteBlockDiv from "../../../../../common/layouts/Global/WhiteBlockDiv";
import Table from "../../../../../common/layouts/tables/Table";
import {
	ToastAfterLoading,
	ToastLoading,
} from "../../../../../common/util/ToastUtil";
import {
	CreateDiscount,
	DeleteDiscount,
	FetchDiscounts,
	ModifyDiscount,
} from "../../../../requests/dashboard/Discounts";
import CreateDiscountPopup from "../../Popup/CreateDiscountPopup";
import DeletePopup from "../../Popup/DeletePopup";
import DetailsDiscountPopup from "../../Popup/DetailsDiscountPopup";
import ModifyDiscountPopup from "../../Popup/ModifyDiscountPopup";

function DiscountsSection({ groupId }) {
	const { t } = useTranslation();
	const { data, refetch } = useQuery([`group-${groupId}-discounts`], {
		queryFn: () => FetchDiscounts(groupId),
	});

	const [createDiscountPopupOpen, setCreateDiscountPopupOpen] =
		useState(false);
	const [deleteDiscountPopupOpen, setDeleteDiscountPopupOpen] =
		useState(false);
	const [modifyDiscountPopupOpen, setModifyDiscountPopupOpen] =
		useState(false);
	const [detailsDiscountPopupOpen, setDetailsDiscountPopupOpen] =
		useState(false);
	const [discountModify, setDiscountModify] = useState(null);

	const closeCreatePopup = () => {
		setCreateDiscountPopupOpen(false);
	};

	const openCreatePopup = (discount) => {
		setDiscountModify(discount);
		setCreateDiscountPopupOpen(true);
	};
	const closeDetailsPopup = () => {
		setDetailsDiscountPopupOpen(false);
	};

	const openDetailsPopup = (discount) => {
		setDiscountModify(discount);
		setDetailsDiscountPopupOpen(true);
	};
	const closeDeletePopup = () => {
		setDeleteDiscountPopupOpen(false);
	};

	const openDeletePopup = (discount) => {
		setDiscountModify(discount);
		setDeleteDiscountPopupOpen(true);
	};

	const closeModifyPopup = () => {
		setModifyDiscountPopupOpen(false);
	};

	const openModifyPopup = (discount) => {
		setDiscountModify(discount);
		setModifyDiscountPopupOpen(true);
	};

	const createDiscount = async (
		discountName,
		discountCode,
		discountValue,
		discountStartDate,
		discountEndDate,
		isMachineChecked,
		isApplicationChecked,
		selectedIds,
		users
	) => {
		closeCreatePopup();
		ToastLoading("createDiscount");
		const data = await CreateDiscount(
			Number(groupId),
			discountName,
			discountStartDate,
			discountEndDate,
			discountValue,
			discountCode,
			isMachineChecked,
			isApplicationChecked,
			selectedIds,
			users
		);
		ToastAfterLoading("createDiscount", data?.success, data?.data?.message);

		refetch();
	};

	const deleteDiscount = async () => {
		closeDeletePopup();
		await DeleteDiscount(discountModify.id);
		refetch();
	};

	const modifyDiscount = async (
		discountName,
		discountCode,
		discountStartDate,
		discountEndDate,
		isMachineChecked,
		isApplicationChecked,
		selectedIds,
		users
	) => {
		closeModifyPopup();
		ToastLoading("modifyDiscount");

		const data = await ModifyDiscount(
			discountModify.id,
			discountName,
			discountStartDate,
			discountEndDate,
			discountCode,
			isMachineChecked,
			isApplicationChecked,
			selectedIds,
			users
		);
		ToastAfterLoading("modifyDiscount", data?.success, data?.data?.message);

		refetch();
	};
	const columns = [
		t("discounts.description"),
		t("discounts.code"),
		t("discounts.value"),
		t("date.start"),
		t("date.end"),
		t("more.details"),
		t("actions"),
	];
	const rows = data?.data?.map((discount) => {
		return [
			discount.description,
			discount.discount_code,
			discount.discount_value * 100 + "%",

			discount.start_date
				? moment(discount.start_date).format("YYYY-MM-DD HH:mm")
				: t("discounts.no.date"),
			discount.end_date
				? moment(discount.end_date).format("YYYY-MM-DD HH:mm")
				: t("discounts.no.date"),
			<CustomButton
				className={"min-w-fit whitespace-nowrap"}
				text={t("more.details")}
				onClick={() => openDetailsPopup(discount)}
			/>,
			<div className="flex">
				<RoundButtonPen
					functionCall={() => openModifyPopup(discount)}
				/>
				<RoundButtonXMark
					functionCall={() => openDeletePopup(discount)}
				/>
			</div>,
		];
	});
	if (!data) return <LoadingSpinner />;

	return (
		<WhiteBlockDiv
			className="mb-2 sm:mb-0"
			text={
				<>
					{t("discounts.title")}{" "}
					<RoundButtonPlus functionCall={openCreatePopup} />
				</>
			}
		>
			<Table columns={columns} rows={rows} />
			<CreateDiscountPopup
				open={createDiscountPopupOpen}
				closeMethod={closeCreatePopup}
				createMethod={createDiscount}
				groupId={groupId}
			/>
			<DetailsDiscountPopup
				open={detailsDiscountPopupOpen}
				closeMethod={closeDetailsPopup}
				discount={discountModify}
			/>
			<DeletePopup
				open={deleteDiscountPopupOpen}
				closeMethod={closeDeletePopup}
				deleteMethod={deleteDiscount}
				typeOfDelete={"discount"}
			></DeletePopup>
			<ModifyDiscountPopup
				id={discountModify?.id ?? 0}
				open={modifyDiscountPopupOpen}
				closeMethod={closeModifyPopup}
				modifyMethod={modifyDiscount}
				currentDiscount={discountModify ? discountModify : ""}
				groupId={groupId}
			/>
		</WhiteBlockDiv>
	);
}

export default DiscountsSection;
