import { requestAPI } from "../../../common/requests/RequestAPI";
import URL from "../../../common/requests/UrlsEnum";

let abortController;

export async function FetchLogs(filters = undefined, page = 1, pageCount = 40) {
	try {
		if (abortController) abortController.abort();

		filters = filters ?? {};

		abortController = new AbortController();

		const data = await requestAPI(
			`${URL.LOG}?page=${page}&countPerPage=${pageCount}`,
			"POST",
			filters,
			abortController.signal
		);

		return data.success ? data.data : [];
	} catch (error) {
		return [];
	}
}
